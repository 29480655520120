import React from 'react';
import {Form} from 'react-bootstrap'
import { toast } from "react-toastify";

import { serverService } from '../../_services/server.service';
import {Trans} from "react-i18next";
import i18n from "i18next";

interface MyProps {
    onSkillSelected(IdSkill:number):any,
    selectedIdSkill?:number,
}

interface MyState {
    skills:any
}

class SkillSelect extends React.Component<MyProps,MyState> {

    constructor(props) {
        super(props);

        this.state = {
            skills:[],
        };
    }

    componentDidMount(){
        this.fetchSkills();
    }


    render () {
        let skills = this.state.skills;

        let optionItems = "";
        let defaultValue = 0;
        if(skills && skills.length > 0){
             optionItems = skills.map((skill) => {
                 let selected = false;
                 if(this.props.selectedIdSkill > 0){
                     if(this.props.selectedIdSkill == skill.IdSkill){
                         selected = true;
                         defaultValue = skill.IdSkill;
                     }
                 }

                return <option key={skill.IdSkill} value={skill.IdSkill}>{skill.Name}</option>
             });
        }

        return (
                <Form.Control onChange={this.onSkillSelected} size="sm" as="select" defaultValue={defaultValue.toString()}>
                    <option key='0' value='0'>{i18n.t("trans_33_skills_select_default_option")}</option>
                    {optionItems}
                </Form.Control>
        )
    }


    fetchSkills() {

        serverService.getSkillsByType(0)
            .then(
                result => {
                    if(result.status === "1"){
                        this.setState({
                            skills:result.data,
                        });
                    } else {
                        toast.warn("Error while fetching skills");
                    }
                },
                error => {
                    toast.warn("Did not get skills");
                }
            );
    }

    onSkillSelected = (event) => {
        this.props.onSkillSelected(event.target.value);
    };
}

export default SkillSelect;
