import React from 'react';


import {Typeahead} from 'react-bootstrap-typeahead';

import {serverService} from "../../_services/server.service";
import {toast} from "react-toastify";  
import i18n from "i18next";

interface MyProps {
    selectedCompany:string,
    handleCompanyChange(companyText:string, isANewCompany:boolean):any,
}

interface MyState {
    companies:any,
    selectedCompany:string,
}

class CompaniesAutocomplete extends React.Component<MyProps,MyState> {

    constructor(props) {
        super(props);

        this.state = {
            companies: [],
            selectedCompany:'',
        };

        this.handleOnInputChange = this.handleOnInputChange.bind(this);
        this.handleOnChange = this.handleOnChange.bind(this);
        this.doesCompanyAlreadyExistsGetCompany = this.doesCompanyAlreadyExistsGetCompany.bind(this);
        this.fetchCompanies = this.fetchCompanies.bind(this);

    }

    componentDidMount(){
        this.fetchCompanies();
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.selectedCompany !== prevState.selectedCompany) {
            this.setState({selectedCompany: this.props.selectedCompany});
        }
    }

    render() {
        let selectedCompany = this.state.selectedCompany;
        if(selectedCompany == null){
            selectedCompany = "";
        }
        return (
            <Typeahead
                allowNew
                bsSize="small"
                selected={[{'Name':selectedCompany}]}
                id="companiesAct"
                options={this.state.companies}
                labelKey="Name"
                onInputChange={this.handleOnInputChange}
                onChange={this.handleOnChange}
                placeholder={i18n.t("trans_33_companies_acp_search_for_company")}
                newSelectionPrefix={i18n.t("trans_33_companies_acp_add_company")}
            />
        );
    }

    handleOnInputChange(companyString:string) {
        this.props.handleCompanyChange(companyString,this.doesCompanyAlreadyExistsGetCompany(companyString));
    }

    handleOnChange(companiesObject:any) {
        if(companiesObject != undefined && companiesObject[0] != undefined && companiesObject[0].Name != undefined ){
            this.props.handleCompanyChange(companiesObject[0].Name,this.doesCompanyAlreadyExistsGetCompany(companiesObject[0].Name));
        }
    }

    fetchCompanies() {
        let userStr = localStorage.getItem('user');
        let IdCompany = 0;
        if(userStr) {
            let userObj = JSON.parse(userStr);
            IdCompany = userObj.IdCompany;
        }

        serverService.getCompanies()
            .then(
                result => {
                    if(result.status == 1){
                        this.setState({
                            companies:result.data,
                        });
                    }
                },
                error => {
                    toast.warn("Did not get companies");
                }
            );
    }

    doesCompanyAlreadyExistsGetCompany(companyText:string):any{
        const companies = this.state.companies;
        if(companies){
            for(let i=0;i<companies.length;i++){
                if(companies[i].Name.toLowerCase() == companyText.toLowerCase()){
                    return companies[i];
                }
            }
        }
        return null;
    }
}


export default CompaniesAutocomplete;