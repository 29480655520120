import React from "react";

import moment from "moment";
import { Button } from "react-bootstrap";

import ResultsTable from "./ResultsTable";
import ResultsStandardTableTable from "./ResultsStandardTableTable";

import "react-day-picker/lib/style.css";

import "./ResultsSelfStarter.css";

import { toast } from "react-toastify";
import { serverService } from "../../_services/server.service";
import {Trans} from "react-i18next";
import ModalAnswersChart from "./ModalAnswersChart";

interface MyProps {
  selectedTabName: string;
}

interface MyState {
  modalAnswersChart: boolean,
  userObj: any
}

class ResultsSelfStarter extends React.Component<MyProps, MyState> {

    private userObj;

  constructor(props) {
    super(props);

    this.state = {
      modalAnswersChart: false,
        userObj:{}
    };

    this.doOpenModalAnswersChart = this.doOpenModalAnswersChart.bind(this);
    this.doCloseModalAnswersChart = this.doCloseModalAnswersChart.bind(this);
  }

    componentDidMount() {
        let userStr = localStorage.getItem('user');
        if(userStr) {
            this.setState({userObj: JSON.parse(userStr)});
        }

    }


  render() {
    return (
      <span>
        <br />

        <Button onClick={this.doOpenModalAnswersChart}><Trans>trans_33_show_answers_chart</Trans></Button>

        <br />

          {this.state.userObj && this.state.userObj['LicenseType'] == 1 &&
            <ResultsTable />
          }

          {this.state.userObj && this.state.userObj['LicenseType'] != 1 &&
            <ResultsStandardTableTable />
          }

         <ModalAnswersChart
             show={this.state.modalAnswersChart}
             onHide={this.doCloseModalAnswersChart}
         />

      </span>
    );
  }

  doOpenModalAnswersChart() {
    this.setState({ modalAnswersChart: true });
  }

  doCloseModalAnswersChart() {
    this.setState({ modalAnswersChart: false });
  }

}

export default ResultsSelfStarter;
