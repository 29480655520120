import React from "react";

import { serverService } from "../../../_services/server.service";

import ResourcesTable from "./AdminResourcesTable";

import "./AdminResourcesPage.css";
import { toast } from "react-toastify";

interface MyProps {}

interface MyState {
  resources: any;
}

class AdminResourcesPage extends React.Component<MyProps, MyState> {
  constructor(props) {
    super(props);

    this.state = {
      resources: [],
    };

    this.onResourceAdded = this.onResourceAdded.bind(this);
    this.onResourceEdited = this.onResourceEdited.bind(this);
    this.onResourceDeleted = this.onResourceDeleted.bind(this);
  }

  componentDidMount() {
    this.setState({
      resources: [],
    });

    this.doFetchAllResources();
  }

  render() {
    return (
      <div className="container mt-5 p-5">
        <ResourcesTable
          resources={this.state.resources}
          onResourceAdded={this.onResourceAdded}
          onResourceEdited={this.onResourceEdited}
          onResourceDeleted={this.onResourceDeleted}
        />
      </div>
    );
  }

  doFetchAllResources() {
    serverService.getResources(0, 0, 0, 0).then(
      (result) => {
        this.setState({ resources: result.data });
      },
      (error) => {
        toast.warn("Did not get resources4");
      }
    );
  }

  onResourceAdded() {
    this.doFetchAllResources();
  }

  onResourceEdited() {
    this.doFetchAllResources();
  }

  onResourceDeleted() {
    this.doFetchAllResources();
  }
}

export { AdminResourcesPage };
