import React from 'react';
import {Button,Modal,Form,Row,Col} from 'react-bootstrap'
import { toast } from "react-toastify";


import { serverService } from '../../_services/server.service';
import VoxSelect from "../_other/VoxSelect";
import ItemsSelect from "../_other/ItemsSelect";

import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
import  {
    formatDate,
    parseDate,
} from 'react-day-picker/build/addons/MomentLocaleUtils';


import i18n from 'i18next';
import {Trans} from "react-i18next";

import sha1 from 'sha1' 



interface MyProps {
    selectedPulse:any,
    onPulseAdded(extra:string):any,
    onPulseEdited(extra:string):any,
    show:boolean,
    onHide():any,
    channels:any,
}

interface MyState {
    submitted: boolean,
    loading: boolean,
    error: string,
    formFields:any,
    selectedPulse:any,
    selectedCompanyObject:any,

    selectedIdChannel: number,
}

class ModalAddNewPulseStep1 extends React.Component<MyProps,MyState> {

    private userObj;

    constructor(props) {
        super(props);

        this.state = {
            submitted: false,
            loading: false,
            error: '',

            formFields:{},

            selectedPulse: {},
            selectedCompanyObject:{},

            selectedIdChannel: 0,
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleDateChange = this.handleDateChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);

        this.onChannelSelected = this.onChannelSelected.bind(this);
        this.onEntered = this.onEntered.bind(this);


    }


    onEntered() {
        let formFields = this.state.formFields;
        this.setState({ formFields: formFields });

        // serverService.getAllLinkedMsTeamsBotOrSlackVoxes().then(
        //     result => {
        //         if (result.status === '1') {
        //             this.setState({
        //                 voxes: result.data,
        //             });
        //         } else {
        //             toast.warn('Error while fetching voxes');
        //         }
        //     },
        //     error => {
        //         toast.warn('Did not get voxes');
        //     }
        // );
    }


    componentDidMount() {
        const userStr = localStorage.getItem('user');
        if (userStr) {
            this.userObj = JSON.parse(userStr);
        }
    }

    handleChange(e) {
        const { name, value } = e.target;
        let formFields = this.state.formFields;
        formFields[name] = value;
        this.setState({ formFields: formFields });
    }

    handleDateChange(from) {
        let formFields = this.state.formFields;
        formFields["DateToEnd"] = from;
        this.setState({ formFields: formFields });
    }

 
    handleSubmit(e) {
        e.preventDefault();

        this.setState({submitted: true});

        const { formFields } = this.state;
        const Name = formFields.Name;
        const DateToEnd = formFields.DateToEnd;


        let channels = [];
        if (this.state.selectedIdChannel == 0) {
            for (let i = 0; i < this.props.channels.length; i++) {
                channels.push(this.props.channels[i]);
            }
        } else {
            for (let i = 0; i < this.props.channels.length; i++) {
                if(this.props.channels[i].IdItem == this.state.selectedIdChannel){
                    channels.push(this.props.channels[i]);
                    break;
                }
            }
        }


        // stop here if form is invalid
        if (!(Name) || !DateToEnd) {
            toast.error("Missing fields");
            return;
        }


        let IdCompany = 0;
        let Timezone = '';

        if (this.userObj) {
            IdCompany = this.userObj.IdCompany;
            Timezone = this.userObj.Timezone;
        }


        let pulse = {'Name':Name, 'DateToEnd': DateToEnd};

        if(this.state.selectedPulse && typeof(this.state.selectedPulse.IdPulse) !== "undefined"){
            pulse['IdPulse'] = this.state.selectedPulse.IdPulse.toString();

            serverService.insertOrUpdatePulse(pulse,JSON.stringify(channels),false)
                .then(result => {
                    if(result.status === "1"){
                        let messageStr = i18n.t("trans_33_msg_pulse_edited");
                        toast.success(messageStr);
                        this.props.onPulseEdited(result.extra);
                    } else {
                        toast.error("Edit pulse failed");
                    }
                });
        } else {
            serverService.insertOrUpdatePulse(pulse,JSON.stringify(channels),false)
                .then(
                    result => {
                        if(result.status === "1"){
                            let messageStr = i18n.t("trans_33_msg_pulse_added");
                            toast.success(messageStr);
                            this.props.onPulseAdded(JSON.parse(result.extra));
                        } else {
                            toast.error("Add pulse failed");
                        }
                    },
                    error => {
                        toast.warn("Did not insert pulse");
                    }
                );
        }

    }

    componentDidUpdate(prevProps, prevState){
        if(this.props.selectedPulse !== prevState.selectedPulse){
            this.setState({selectedPulse:this.props.selectedPulse});

            let formFields = this.state.formFields;
            formFields.Name = "";

            if(this.state.selectedPulse && typeof(this.state.selectedPulse.IdPulse) !== "undefined"){
                formFields.Name = this.props.selectedPulse.Name;
            }

            this.setState({formFields:formFields});
        }
    }


    render() {

        let isEditMode = this.state.selectedPulse && typeof(this.state.selectedPulse.IdPulse) !== "undefined";

        let titleStr = i18n.t("trans_33_add_pulse_step1_title");
        let confirmButtonStr = i18n.t("trans_33_next");
        if(isEditMode){
            titleStr = i18n.t("trans_33_edit_pulse");
            confirmButtonStr = i18n.t("trans_33_save");
        }


        return (
            <Modal
                show={this.props.show}
                onHide={this.props.onHide}
                onEntered={this.onEntered}
                size='lg'
                dialogClassName="modal-90w"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header className='modal-header-update p-4' closeButton translate="yes">
                    <Modal.Title as="h4" id="example-custom-modal-styling-title">
                        {titleStr}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className='px-4 pb-0'>
                    <Form onSubmit={this.handleSubmit}>

                        <Form.Group controlId="formBasicName">
                            <Form.Label>*Name of pulse</Form.Label>
                            <Form.Control size="lg" type="text" placeholder="Enter name of pulse"
                                          name="Name"
                                          value={this.state.formFields.Name}
                                          onChange={this.handleChange}
                            />
                        </Form.Group>

                        {!isEditMode &&
                          <>
                            <Form.Label><Trans>trans_33_where_to_send_pulse</Trans></Form.Label>

                            <ItemsSelect className="add-new-pulse-select" size="lg" selectedIdItem={this.state.selectedIdChannel} items={this.props.channels}
                              onItemSelected={this.onChannelSelected} defaultText={i18n.t("trans_33_select_channel")} />
                          </>
                        }

                        <br/>

                        <Form.Group>
                            <Form.Label>Set the deadline for answering the Pulse</Form.Label>
                            <div className="InputResourceDate">
                                <DayPickerInput
                                    placeholder={i18n.t('trans_33_choose_date')}
                                    formatDate={formatDate}
                                    parseDate={parseDate}
                                    format="DD/MM/YYYY"
                                    onDayChange={this.handleDateChange}
                                    dayPickerProps={{
                                        disabledDays: {before: new Date(),},
                                        firstDayOfWeek:1,
                                    }}
                                />
                            </div>
                        </Form.Group>
                        


                        <br/>

                        <div style={{ textAlign: 'right',padding: "0 10px 0 10px" }}>
                            <Button className='px-4 py-2' size='lg' variant="primary"  type="submit">
                                {confirmButtonStr}
                            </Button>
                        </div>

                    </Form>
                </Modal.Body>
                <br/>
            </Modal>
        );
    }

    onChannelSelected(IdChannel) {
        this.setState({ selectedIdChannel: IdChannel });
    }

}

export default ModalAddNewPulseStep1;