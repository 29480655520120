import React from "react";

import { serverService } from "../../../_services/server.service";

import LogsTable from "./AdminLogsTable";

interface MyProps {}

interface MyState {
  logs: any;
}

class AdminLogsPage extends React.Component<MyProps, MyState> {
  constructor(props) {
    super(props);

    this.state = {
      logs: [],
    };
  }

  componentDidMount() {
    this.setState({
      logs: [],
    });

    this.doFetchAllLogs();
  }

  render() {
    return (
      <div className="container mt-5 p-5">
        <LogsTable logs={this.state.logs} />
      </div>
    );
  }

  doFetchAllLogs() {
    serverService.getLogs().then((result) => {
      this.setState({ logs: result.data });
    });
  }
}

export { AdminLogsPage };
