/**
 * Created by alexd on 3/12/2020.
 */
class PubSub {
    constructor() {
        this.handlers = [];
    }

    subscribe(event, handler) {
        this.handlers.push({ event: event, handler: handler.bind(handler) });
    }

    unsubscribe(event) {
        for(let i=0;i<this.handlers.length;i++){
            if(this.handlers[i].event === event){
                this.handlers.pop(this.handlers[i]);
            }
        }
    }

    publish(event, args) {
        this.handlers.forEach(topic => {
            if (topic.event === event) {
                topic.handler(args)
            }
        })
    }
}

export default new PubSub();