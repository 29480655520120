/**
 * Created by Alex on 22-Mar-19.
 */
import React from 'react';
import {Button,Modal,Form,Row,Col} from 'react-bootstrap'
import { toast } from "react-toastify";
import moment from "moment"

import {serverService} from "../../_services/server.service";


import './ModalOnboardingBeforeAddVox.css'
import { Trans } from 'react-i18next';
import i18n from 'i18next';
import ItemsSelect from "../_other/ItemsSelect";


let momentTz = require('moment-timezone');


interface MyProps {
    show:boolean,
    onHide(): any,
    onTimezoneAndLanguageUpdated(Timezone:string, IdLanguage:number):any,
}

interface MyState {
    submitted: boolean;
    loading: boolean;
    formFields: any;
}

class ModalOnboardingSelectTimezoneAndLanguage extends React.Component<MyProps,MyState> {

    private timezonesObj;
    private languagesObj;

    private browserTimezone;

    constructor(props) {
        super(props);

        this.browserTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

        this.timezonesObj = i18n.t('trans_33_timezones', {
            returnObjects: true,
        });

        this.languagesObj = i18n.t('trans_33_languages', {
            returnObjects: true,
        });



        this.state = {
            submitted: false,
            loading: false,
            formFields: {'Timezone': this.browserTimezone, 'IdLanguage': '1'},
        };


        this.onTimezoneSelected = this.onTimezoneSelected.bind(this);
        this.onLanguageSelected = this.onLanguageSelected.bind(this);
        this.doUpdateTimezoneAndLanguage = this.doUpdateTimezoneAndLanguage.bind(this);

    }

    render() {

        let currentTime = momentTz
                            .tz(moment(), this.state.formFields.Timezone)
                            .format('HH:mm');
        return (
            <Modal
                show={this.props.show}
                onHide={this.props.onHide}
                size="lg"
                dialogClassName="modal-90w"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                backdrop="static"
            >
                <Modal.Body style={{textAlign: 'center'}}>
                    <br/>
                    <Modal.Title id="example-custom-modal-styling-title">
                        <span id="welcome_text">
                        <Trans>What time is it?</Trans>
                        </span>
                    </Modal.Title>

                    <span style={{"color": "red", "fontSize": "20px"}} >
                        {currentTime}
                    </span>

                    <br/><br/>

                    Confirm your timezone:

                    <br/>

                    <div style={{"width": "200px", "marginLeft": "auto", "marginRight": "auto"}}>
                        <ItemsSelect
                            items={this.timezonesObj}
                            selectedIdItem={this.state.formFields.Timezone}
                            onItemSelected={this.onTimezoneSelected}
                            defaultText={"Select your timezone"}
                        />
                    </div>

                    <br/>

                    <Modal.Title id="example-custom-modal-styling-title">
                        <span id="welcome_text">
                        <Trans>Which language do you want to ask the questions in?</Trans>
                        </span>

                        <br/>

                        <div style={{"width": "200px", "marginLeft": "auto", "marginRight": "auto"}}>
                            <ItemsSelect
                                items={this.languagesObj}
                                selectedIdItem={this.state.formFields.IdLanguage}
                                onItemSelected={this.onLanguageSelected}
                                defaultText={"Select your language"}
                            />
                        </div>
                    </Modal.Title>
                </Modal.Body>

                <br/><br/>


                <Modal.Footer>
                    <Button variant="success" onClick={this.doUpdateTimezoneAndLanguage}>
                        Set timezone and language
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }


    onTimezoneSelected(Timezone) {
        let formFields = this.state.formFields;
        formFields['Timezone'] = Timezone;
        this.setState({ formFields: formFields });
    }

    onLanguageSelected(IdLanguage) {
        let formFields = this.state.formFields;
        formFields['IdLanguage'] = IdLanguage;
        this.setState({ formFields: formFields });
    }


    doUpdateTimezoneAndLanguage(){
        let formFields = this.state.formFields;
        let Timezone = formFields.Timezone;
        let IdLanguage = formFields.IdLanguage;

        if(Timezone.length == 0 || IdLanguage.length == 0){
            toast.warn("You have to choose a timezone and a language");
            return;
        }
        serverService.updateTimezoneAndLanguage(Timezone,IdLanguage)
            .then(
                (response) => {
                    if(response.status == 1){
                        this.props.onTimezoneAndLanguageUpdated(Timezone,IdLanguage);
                    }
                },
                (error) => {
                    console.log(error);
                    toast.warn(i18n.t("trans_33_msg_something_went_wrong"));
                }
            )
    }


}

export default ModalOnboardingSelectTimezoneAndLanguage;