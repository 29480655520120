import React from "react";

import { serverService } from "../../../_services/server.service";

import QuestionsTable from "./AdminQuestionsTable";

import "./AdminQuestionsPage.css";
import { toast } from "react-toastify";

interface MyProps {}

interface MyState {
  questions: any;
  question_skills: any;
  allSkills: any;
}

class AdminQuestionsPage extends React.Component<MyProps, MyState> {
  constructor(props) {
    super(props);

    this.state = {
      questions: [],
      question_skills: [],
      allSkills: [],
    };

    this.onQuestionAdded = this.onQuestionAdded.bind(this);
    this.onQuestionDeleted = this.onQuestionDeleted.bind(this);
    this.onQuestionsImported = this.onQuestionsImported.bind(this);
  }

  componentDidMount() {
    this.setState({
      questions: [],
      question_skills: [],
      allSkills: [],
    });

    this.doFetchAllQuestions();
  }

  render() {
    return (
      <div className="container mt-5 p-5">
        <QuestionsTable
          questions={this.state.questions}
          question_skills={this.state.question_skills}
          allSkills={this.state.allSkills}
          onQuestionsImported={this.onQuestionsImported}
          onQuestionAdded={this.onQuestionAdded}
          onQuestionDeleted={this.onQuestionDeleted}
        />
      </div>
    );
  }

  doFetchAllQuestions() {
    const userStr = localStorage.getItem("user");
    let IdCompany = 0;
    if (userStr) {
      const userObj = JSON.parse(userStr);
      IdCompany = userObj.IdCompany;
    }

    serverService.getAllAdminQuestionsForTableDisplay().then(
      (questionsRes) => {
        let questions = questionsRes.data.questions;
        let skills = questionsRes.data.skills;
        let allSkills = questionsRes.data.allSkills;
        let question_skills = {};
        for (let i = 0; i < skills.length; i++) {
          if (question_skills[skills[i].IdQuestion] === undefined) {
            question_skills[skills[i].IdQuestion] = [];
          }
          question_skills[skills[i].IdQuestion].push(skills[i]);
        }

        for (let i = 0; i < questions.length; i++) {
          let oneQuestionSkills = question_skills[questions[i].IdQuestion];
          let SkillsString = "";
          if (oneQuestionSkills !== undefined) {
            for (let j = 0; j < oneQuestionSkills.length; j++) {
              if (j > 0) {
                SkillsString += ", ";
              }
              SkillsString +=
                oneQuestionSkills[j].Name + ": " + oneQuestionSkills[j].Weight;
            }
          }
          questions[i]["SkillsString"] = SkillsString;
          questions[i]["Skills"] = oneQuestionSkills;
        }

        this.setState({
          questions: questions,
          question_skills: question_skills,
          allSkills: allSkills,
        });
      },
      (error) => {
        toast.warn("Did not get questions");
      }
    );
  }

  onQuestionAdded() {
    this.doFetchAllQuestions();
  }

  onQuestionDeleted() {
    this.doFetchAllQuestions();
  }

  onQuestionsImported() {
    this.doFetchAllQuestions();
  }
}

export { AdminQuestionsPage };
