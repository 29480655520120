/**
 * Created by Alex on 22-Mar-19.
 */
import React from 'react';
import {Button,Modal,Form,Row,Col} from 'react-bootstrap'
import { toast } from "react-toastify";
import {Link} from "react-router-dom";

import {Trans} from "react-i18next";
import i18n from "i18next";
import { serverService } from '../../_services/server.service';

interface MyProps {
    show:boolean,
    onHide(): any,
    selectedLink:string,
    selectedEmail:string,
    scanToken:string,
    title: string,
}


class ModalScanReady extends React.Component<MyProps> {
    private textArea:any;

    constructor(props) {
        super(props);
        this.state = {
        };
    }

    render() {
        return (
            <Modal
                show={this.props.show}
                onHide={this.props.onHide}
                dialogClassName="modal-90w"
                aria-labelledby="contained-modal-title-vcenter"
                size="lg"
                centered
            >
                <Modal.Header className="add-vox-modal-header" translate="yes">
                    <Modal.Title id="example-custom-modal-styling-title">
                        {this.props.title}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="p-5 add-vox-modal-content">

                <div className="d-flex flex-column align-items-center">

                    <img src="/assets/images/survey.svg" alt="Scan ready" className="img-fluid" />

                    <p>Survey is ready! We've sent your survey link and password to {this.props.selectedEmail}</p>

                    <Button className='btn btn-outline-primary my-2' variant="secondary"
                        onClick={() => {
                            serverService.resendScanEmail(this.props.scanToken)
                            .then(res => {
                                if(res.status === 200 && res.data.status == "0"){
                                    toast.success(i18n.t("trans_33_modal_resend_email_msg_failed"));
                                } else {
                                    toast.success(i18n.t("trans_33_modal_resend_email_msg_success"));
                                }
                              
                            })
                            .catch(err => {
                                console.log(err)
                            });
                        }}> 
                        <Trans>trans_33_resend_email</Trans>
                    </Button>
                </div>


                    <br/>

                    Survey link
                    <div className='copy-link-body-wrapper'>
                      <i className="fas fa-link mx-2"></i>
                      <textarea
                        rows={1}
                        ref={(textarea) => this.textArea = textarea}
                        id="copyLinkTextarea"
                        value={this.props.selectedLink}
                        onChange={this.onChange}
                      />
                      <Button className='copy-link-btn' variant="primary" onClick={this.copyToClipboard}>
                          <Trans>trans_33_modal_copy_link_copy_btn</Trans>
                      </Button>
                    </div>
                </Modal.Body>
            </Modal>
        );
    }

    onChange(){}


    copyToClipboard = (e) => {
        this.textArea.select();
        document.execCommand('copy');
        e.target.focus();

        toast.success(i18n.t("trans_33_modal_copy_link_msg_success"))
    };

}

export default ModalScanReady;