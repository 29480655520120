/**
 * Created by Alex on 22-Mar-19.
 */
import React from 'react';
import {Form,Button,Modal} from 'react-bootstrap'
import { toast } from "react-toastify";

import './ModalLoadingQuestionPack.css'
import {Trans} from "react-i18next";

interface MyProps {
    show:boolean,
    onHide():any,
}

interface MyState {
    loading: boolean,
    error: string,
}


class ModalLoadingQuestionPack extends React.Component<MyProps,MyState> {

    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            error: '',
        };
    }

    componentDidMount() {
    }

    render() {
        return (
            <Modal
                show={this.props.show}
                onHide={this.props.onHide}
                size="lg"
                dialogClassName="modal-90w modalLoadingQuestionPack"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                backdrop="static"
            >
                <Modal.Body className="modalLoadingQuestionPackBody">
                        <br/>

                        <p><Trans>trans_33_modal_loading_question_pack_creating_your_questions</Trans></p>

                        <p><Trans>trans_33_modal_loading_question_pack_may_take_5_to_10_seconds</Trans></p>

                        <div className="container" role="img" aria-label="animated digital illustration of an audio recorder made with CSS">
                            <div className="main">
                                <div className="circle c1">
                                    <div className="inner-C">
                                        <div className="inner-inner"></div>

                                        <div className="hole hole1">
                                            <div className="line h-Top"></div>
                                            <div className="line h-Right"></div>
                                            <div className="line h-Bottom"></div>
                                            <div className="line h-Left"></div>
                                        </div>

                                        <div className="hole hole2">
                                            <div className="line h-Top"></div>
                                            <div className="line h-Right"></div>
                                            <div className="line h-Bottom"></div>
                                            <div className="line h-Left"></div>
                                        </div>

                                        <div className="hole hole3">
                                            <div className="line h-Top"></div>
                                            <div className="line h-Right"></div>
                                            <div className="line h-Bottom"></div>
                                            <div className="line h-Left"></div>
                                        </div>

                                    </div>
                                </div>

                                <div className="circle c2">
                                    <div className="inner-C">
                                        <div className="inner-inner"></div>

                                        <div className="hole hole1">
                                            <div className="line h-Top"></div>
                                            <div className="line h-Right"></div>
                                            <div className="line h-Bottom"></div>
                                            <div className="line h-Left"></div>
                                        </div>

                                        <div className="hole hole2">
                                            <div className="line h-Top"></div>
                                            <div className="line h-Right"></div>
                                            <div className="line h-Bottom"></div>
                                            <div className="line h-Left"></div>
                                        </div>

                                        <div className="hole hole3">
                                            <div className="line h-Top"></div>
                                            <div className="line h-Right"></div>
                                            <div className="line h-Bottom"></div>
                                            <div className="line h-Left"></div>
                                        </div>
                                    </div>
                                </div>


                                <div className="m2">

                                    <div className="sRec"></div>

                                    <div className="rec">
                                        <div className="mini-Circle mc1"></div>
                                        <div className="mini-Circle mc2"></div>
                                        <div className="mini-Circle mcBig"></div>

                                        <div className="line line-Left"></div>
                                        <div className="line line-Left2"></div>

                                        <div className="line line-Right"></div>
                                        <div className="line line-Right2"></div>
                                    </div>

                                </div>

                                <div className="m3">

                                    <div className="nobs nobs1">
                                        <div className="mini-Circle mc3"></div>
                                        <div className="mini-Circle mc4"></div>
                                        <div className="mini-Circle mc5"></div>
                                    </div>

                                    <div className="boxes boxes1">
                                        <div className="box box1">
                                            <div className="meter meter1"></div>
                                        </div>
                                        <div className="box box2">
                                            <div className="meter meter2"></div>
                                        </div>
                                    </div>

                                    <div className="boxes boxes2">
                                        <div className="box box3">
                                            <div className="meter meter3"></div>
                                        </div>
                                        <div className="box box4">
                                            <div className="meter meter4"></div>
                                        </div>
                                    </div>

                                    <div className="nobs nobs2">
                                        <div className="mini-Circle mc6"></div>
                                        <div className="mini-Circle mc7"></div>
                                        <div className="mini-Circle mc8"></div>
                                    </div>

                                </div>
                            </div>
                        </div>

                        <br/><br/>

                        <p><Trans>trans_33_modal_loading_question_pack_preparing_the_dance_floor</Trans></p>
                </Modal.Body>
            </Modal>
        );
    }


}

export default ModalLoadingQuestionPack;