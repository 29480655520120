import React from 'react';

import {Button} from 'react-bootstrap'

import '../CampaignPage/Self-Starter/QuestionsTable.css'
import i18n from "i18next";
import {Trans} from "react-i18next";

import moment from 'moment'

const $ = require('jquery');
$.DataTable = require('datatables.net');

require( 'datatables.net-bs4' );
require( 'datatables.net-select-bs4' );
require('../datatables.selected.css');


interface MyProps {
    inputQuestions:any,
    onShowCopyLink: any,
    onShowAnswers: any
}

interface MyState {
    submitted: boolean,
    loading:boolean,
}

class InputQuestionsTable extends React.Component<MyProps,MyState> {
    constructor(props) {
        super(props);

        this.state = {
            submitted:false,
            loading:false,
        };
    }

    componentDidMount() {
        $(this.refs.main).DataTable({
            dom: "<'data-table-wrapper'" +
                    "<'row'<'col-sm-12'tr>>" +
                    "<'row'<'col-sm-12 col-md-5'><'col-sm-12 col-md-7'p>>" +
                    ">",
            data: this.props.inputQuestions,
            columns : [
                {
                    title: i18n.t("trans_33_Added"),
                    width: 135,
                    data: 'Added'
                },
                {
                    title: i18n.t("trans_33_Vox"),
                    width: 80,
                    data: 'VoxName'
                },
                {
                    title: i18n.t("trans_33_Question"),
                    width: 200,
                    data: 'QuestionText',
                    orderable: false
                },
                {
                    title: i18n.t("trans_33_Answers"),
                    width: 200,
                    data: 'answers'
                }
            ],
            ordering: true,
            paging:true,
            order: [[ 0, "desc" ]],
            language: {
                search: i18n.t("trans_33_search"),
                emptyTable:  i18n.t("trans_33_table_no_records"),
                paginate: {
                    first: i18n.t("trans_33_first"),
                    previous: i18n.t("trans_33_previous"),
                    next: i18n.t("trans_33_next"),
                    last: i18n.t("trans_33_last")
                }
            },
            buttons: [
                { extend: 'excel', text: i18n.t("trans_33_export_excel") }
            ],
            columnDefs: [
                { width: 40, targets: 0 },
                { width: 80, targets: 1 },
                { width: 200, targets: 2 },
                { width: 200, targets: 3 },
                // {
                //     render: function ( data, type, row ) {
                //         return `${data} <br /><a href='#' class='request-link' 
                //         data-type='copy'
                //         data-IdInputQuestion='${row.IdInputQuestion}'
                //         data-token='${row.Token}'
                //         data-IdVox='${row.IdVox}'
                //        >Copy Link</a>`
                //     },
                //     targets: 2,
                // },
                // {
                //     render: function ( data, type, row ) {
                //         return `${data} <br /><a href='#' class='request-link' 
                //         data-type='copy'
                //         data-IdInputQuestion='${row.IdInputQuestion}'
                //         data-token='${row.Token}'
                //         data-IdVox='${row.IdVox}'
                //        >Copy Link</a>`
                //     },
                //     targets: 2,
                // },
                {
                    render: function ( data, type, row ) {
                        if(data != null && data.length > 0){
                          const items = ("•" + data).split("<br/>")
                            if(items.length === 1) {
                              return items[0]
                            }

                            return `${items[0]}<br/><a href='#' class='request-link' 
                              data-type='answers'
                              data-IdInputQuestion='${row.IdInputQuestion}'
                              data-IdVox='${row.IdVox}'
                              data-token='${row.Token}'
                              data-NumOfAnswers='${items.length}'
                             >View All ${items.length} answers</a>`
                        } else {
                            return "";
                        }
                    },
                    targets: 3,
                },

                {
                    render: function ( data, type, row ) {
                        if(type === 'sort'){
                            return data;
                        }

                        if(data != null){
                            return moment(data).format('D MMM YYYY HH:mm:ss');
                        } else {
                            return "";
                        }
                    },
                    targets: 0,
                }
            ]
        });


    }

    componentWillUnmount(){
        $('.data-table-wrapper')
            .find('table')
            .DataTable()
            .destroy(true);
    }

    componentDidUpdate(oldProps) {
        if(this.props.inputQuestions !== oldProps.inputQuestions){
            this.reloadTableData(this.props.inputQuestions);
        }
    }
    
    reloadTableData(items) {
        const table = $('.data-table-wrapper')
            .find('table')
            .DataTable();
        table.clear();
        table.rows.add(items);
        table.draw();
    
        table.on('click', 'a', e => {
          e.stopPropagation();
    
          const data = e.target.dataset;
    
          const { type, idinputquestion, idvox, token, numofanswers } = data
          
          if(data.type === 'copy') {
            this.props.onShowCopyLink(type, idinputquestion, idvox, token)
          }
    
          if(data.type === 'answers') {
            this.props.onShowAnswers(type, idinputquestion, idvox, token, numofanswers)
          }
      });
    }

    render() {
        return (
          <div>

            <div className="d-flex align-items-center">
                <div className='d-flex flex-nowrap align-items-center mr-4 w-100 input-group relative border border-secondary rounded'>
                  <i className="pl-2 fas fa-search"></i>
                  <input
                    className='w-100 p-2 shadow-none border-0'
                    style={{ outline: 0 }}
                    type="text"
                    placeholder={i18n.t("trans_33_search")}
                    onChange={e => {
                      const table = $(this.refs.main)
                        .dataTable();

                      table.fnFilter( e.target.value );
                    }}
                  />
                </div>
                <Button
                  className='p-2 btn-outline-primary-updated no-shrink'
                  variant="outline-primary"
                  size="lg"
                  onClick={() => {
                    $(this.refs.main)
                      .DataTable().button().trigger();
                  }}
                >
                  <Trans>trans_33_export_excel</Trans>
                </Button>
            </div> 
            <div className="row py-3">
                <div  className="col-md-12" id="questionTableContainer">
                    <table style={{ width: '100%' }} ref="main" className="table table-bordered table-data-sort questionsTable" />
                </div>
            </div>
          </div>);
    }

}

export default InputQuestionsTable;
