/**
 * Created by Alex on 22-Mar-19.
 */
import React from 'react';
import {Button,Modal,Form,Row,Col} from 'react-bootstrap'
import { toast } from "react-toastify";
import {Link} from "react-router-dom";

import './ModalOnboardingAfterAddVox.css'
import SelectDaysAutocomplete from "../MyVoxPage/SelectDaysAutocomplete";
import {Trans} from "react-i18next";

interface MyProps {
    onConfirmAddNewVox(): void,
    show:boolean,
    onHide(): any,
}


class ModalOnboardingAfterAddVox extends React.Component<MyProps> {

    constructor(props) {
        super(props);
        this.state = {

        };
    }

    render() {
        return (
            <Modal
                show={this.props.show}
                onHide={this.props.onHide}
                size="lg"
                dialogClassName="modal-90w"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                backdrop="static"
            >
                <Modal.Header translate="yes">
                    <Modal.Title id="example-custom-modal-styling-title">
                        <img src="/assets/images/onboarding/onboarding_calendar.png" id="calendar_img"/>
                        <Trans>trans_33_modal_onboarding_after_adding_vox_title</Trans>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p><Trans>trans_33_modal_onboarding_after_adding_vox_description1</Trans></p>
                    <p>
                        <Trans i18nKey="trans_33_modal_onboarding_after_adding_vox_description2">
                            text<strong>text</strong>text
                        </Trans>
                    </p>´
                    <SelectDaysAutocomplete />
                </Modal.Body>

                <Modal.Footer>
                    <Link to="/campaign">
                        <Button variant="success" onClick={this.props.onConfirmAddNewVox}>
                            <Trans>trans_33_modal_onboarding_after_adding_vox_btn</Trans>
                        </Button>
                    </Link>
                </Modal.Footer>
            </Modal>
        );
    }

}

export default ModalOnboardingAfterAddVox;