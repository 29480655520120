import React from "react";

import { serverService } from "../../_services/server.service";
import { toast } from "react-toastify";
import { Row, Col } from "react-bootstrap";

import "./HomePage.css";
import ResultsSelfStarterChart from "../_other/ResultsSelfStarterChart";

import ModalTos from "../_modals/ModalTos";
import {Trans} from "react-i18next";

const moment = require("moment");

interface MyProps {}

interface MyState {
  loading: boolean;
  strikingFact: any;
  company: any;
  modalTos:boolean,
  chart1Json:any,
}

class HomePage extends React.Component<MyProps, MyState> {
  private userObj;

  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      strikingFact: {},
      company: {},
      modalTos:false,
      chart1Json:{}
    };

    this.onTosAccepted = this.onTosAccepted.bind(this);

  }

  componentDidMount() {
    let userStr = localStorage.getItem("user");
    if (userStr) {
      this.userObj = JSON.parse(userStr);
    }

    this.doFetchAll();
    this.doFetchChartData();
  }



  doFetchChartData() {
    serverService.getResultsNumOfAnswersPerVoxChart().then(
        (result) => {
          if (result.status === "1") {
            let allResults = result.data.allResults;
            let allVoxes = result.data.allVoxes;

            let emptyVoxObject = { day: "" };
            for (let i = 0; i < allVoxes.length; i++) {
              let IdVox = allVoxes[i]["IdVox"];
              let Name = allVoxes[i]["Name"];

              emptyVoxObject[Name] = "";
            }

            let tempDate = "";
            let chart1Json = [];
            let chart2Json = [];
            let tempOneObject = { ...emptyVoxObject };
            for (let i = 0; i < allResults.length; i++) {
              let oneResult = allResults[i];

              if (tempDate !== oneResult.Date) {
                tempDate = oneResult.Date;

                if (i > 0) {
                  chart1Json.push(tempOneObject);
                }

                tempOneObject = { ...emptyVoxObject };
              } else {
              }

              tempOneObject["day"] = oneResult.Date;
              tempOneObject[oneResult.Name] = oneResult.NumberOfAnswers;
            }
            chart1Json.push(tempOneObject);

            this.setState({ chart1Json: chart1Json });
          }
        },
        (error) => {
          console.log(error);
        }
    );
  }



  render() {

    console.log(this.state.chart1Json);

    return (
      <div id="homeContainer">
        {this.state.loading && (
          <img
            alt="loading"
            src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA=="
          />
        )}

        <br />

        {this.state.strikingFact.YesPercentage !== undefined && (
          <div className="left-side">
            <p id="text-title-did-you-know"><Trans>trans_33_home_page_did_you_know</Trans></p>
            <br />
            <section className="flex-container__vox">
              <div id="yes-container">
                <div id="burst-12-shape" />
                <div id="yes-percentage">
                  {Math.round(this.state.strikingFact.YesPercentage)}%
                </div>
              </div>
              <div id="question-container">
                <Trans>trans_33_home_page_colleagus_agree_with</Trans> <br />{" "}
                <b>{this.state.strikingFact.QuestionText}</b>
              </div>
            </section>
          </div>
        )}

          {!this.state.loading && this.state.strikingFact.YesPercentage === undefined &&
          this.state.company.CampaignStartDate == "0000-00-00" && (
            <p id="text-title-did-you-know"><Trans>trans_33_vox_to_be_configured</Trans></p>
          )}

        {!this.state.loading && this.state.strikingFact.YesPercentage === undefined &&
          this.state.company.CampaignStartDate != "0000-00-00" && (
            <p id="text-title-did-you-know">
              <Trans>trans_33_campaign_starts_on</Trans>{" "}
              {moment(this.state.company.CampaignStartDate).format("DD/MM/YY")}
            </p>
          )}


        {this.state.chart1Json.length > 0 && this.state.chart1Json[0]['day'].length > 0 &&
            <>
             <br/><br/><br/>
            <div>
              <div style={{maxWidth: "800px",margin: "auto"}}><Trans>trans_33_home_page_voting_rate_title</Trans></div>
              <ResultsSelfStarterChart
                  chart1Data={this.state.chart1Json}
                  displayButtons={false}
              />
            </div>
            </>
        }


        <ModalTos
            show={this.state.modalTos}
            onHide={this.doFetchCurrentUser}
            onTosAccepted={this.onTosAccepted}
        />
      </div>

    );
  }

  doFetchAll() {
    this.setState({ loading: true });
    serverService.getStrikingFact(this.userObj.IdCompany).then(
      (answersRes) => {
        if (answersRes && answersRes.data != undefined) {
          this.setState({ strikingFact: answersRes.data, loading: false });
        } else {
          this.setState({ loading: false });
        }
      },
      (error) => {
        toast.warn("Did not load striking fact");
        this.setState({ loading: false });
      }
    );

    serverService.getCompany(this.userObj.IdCompany).then(
      (response) => {
        if (response && response.data != undefined) {

          this.userObj['IdLanguage'] = response.data[0]['IdLanguage'];

          localStorage.setItem('user',JSON.stringify(this.userObj));
          // console.log(this.userObj);
          this.setState({ company: response.data[0], loading: false });
        } else {
          this.setState({ loading: false });
        }
      },
      (error) => {
        toast.warn("Did not get company");
        this.setState({ loading: false });
      }
    );

    this.doFetchCurrentUser();

  }

  doFetchCurrentUser(){
    serverService.getCurrentUser().then(
        (response) => {
          if (response && response.data != undefined) {
            if(response.data[0]['AcceptedTos'] == 0){
              this.setState({ modalTos: true, loading: false });
            }
          } else {
            this.setState({ loading: false });
          }
        },
        (error) => {
          toast.warn("Did not get user");
          this.setState({ loading: false });
        }
    );
  }

  onTosAccepted(){
    this.setState({ modalTos: false });

    window.location.href = "/myvox";
  }
}

export { HomePage };
