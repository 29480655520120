/**
 * Created by Alex on 22-Mar-19.
 */
import React from 'react';
import { Button, Modal, Form, Row, Col } from 'react-bootstrap';
import { toast } from 'react-toastify';

import { serverService } from '../../../../_services/server.service';
import i18n from 'i18next';
import { Trans } from 'react-i18next';
import VoxSelect from '../../../_other/VoxSelect';
import ModalConfirmAction from "../../../_other/ModalConfirmAction";

interface MyProps {
  onHide(): any;
  onGenerateQuestions(TextMessage:string, selectedIdVox: number): any;
  show: boolean;
  size?: any;
} 

interface MyState {
  submitted: boolean;
  loading: boolean;
  error: string;
  success: string;
  formFields: any;
  selectedIdVox: number;
  voxes: any;

  acceptTosPolicy: boolean;

  modalConfirmShow:boolean;

  questionsFromGPT: string;
}

class ModalGenerateQuestions extends React.Component<MyProps, MyState> {
  constructor(props) {
    super(props);

    this.state = {
      submitted: false, 
      loading: false,
      error: '',
      success: '',

      acceptTosPolicy: false,

      formFields: {},
      selectedIdVox: 0,
      voxes: [],

      modalConfirmShow:false,

      questionsFromGPT:''
  };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onVoxSelected = this.onVoxSelected.bind(this);
    this.onEntered = this.onEntered.bind(this);

    this.handleTosCheckboxChange = this.handleTosCheckboxChange.bind(this);

  }

  onEntered() {
    let formFields = this.state.formFields;


    serverService.getAllActiveVoxes().then(
      result => {
        if (result.status === '1') {

          this.setState({ voxes: result.data, });

        } else {
          toast.warn('Error while fetching voxes');
        }
      },
      error => {
        toast.warn('Did not get voxes');
      }
    );
  }


  handleChange(e) {
    const { name, value } = e.target;
    let formFields = this.state.formFields;
    formFields[name] = value;
    this.setState({ formFields: formFields });
  }

  handleSubmit(e) {
    e.preventDefault();
  }


  render() {
    let { formFields, loading } = this.state;

    let modalConfirmOpen = (itemToEdit) => {
      if(this.state.acceptTosPolicy === false){
        toast.warn("Please accept the terms of service to move forward");
        return;
      }

      if(!this.state.formFields.TextMessage){
        toast.warn("Please enter a description");
        return;
      } 

      this.props.onGenerateQuestions(formFields.TextMessage, this.state.selectedIdVox)
    };
 
    return (
      <Modal
        show={this.props.show}
        onHide={this.props.onHide}
        size={this.props.size}
        onEntered={this.onEntered}
        dialogClassName='modal-90w'
        aria-labelledby='contained-modal-title-vcenter'
        centered
      >
        <Modal.Header
          className='add-vox-modal-header'
          closeButton
          translate='yes'
        >
          <Modal.Title id='example-custom-modal-styling-title'>
            <Trans>trans_33_generate_questions</Trans>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className='add-vox-modal-content'>
          <p style={{ color: 'red' }}>{this.state.error}</p>

          <p style={{ color: 'green' }}>{this.state.success}</p>

          <Form onSubmit={this.handleSubmit}>
            <Form.Group controlId='formCompanyName'>
              <Form.Label>
                *<Trans>trans_33_generate_questions_description</Trans>
              </Form.Label>
              <Form.Control
                size='sm'
                as='textarea'
                name='TextMessage'
                rows={2}
                value={formFields.TextMessage}
                onChange={this.handleChange}
              />
            </Form.Group>


            <span style={{ display: 'inline-block', padding: "0 10px 0 10px", }}>
              <VoxSelect
                  selectedIdVox={this.state.selectedIdVox}
                  onVoxSelected={this.onVoxSelected}
                  voxes={this.state.voxes}
              />
            </span>


            <div className='col-md-12 mt-2 terms-center'>
                <div className='custom-control custom-checkbox'>
                  <input
                      checked={this.state.acceptTosPolicy}
                      className='custom-control-input'
                      type='checkbox'
                      id='acceptTosPolicy'
                      name='acceptTosPolicy'
                      onChange={this.handleTosCheckboxChange}
                  />

                  <br />

                  <label
                      className='custom-control-label terms-right'
                      htmlFor='acceptTosPolicy'
                  >   
                        <span style={{ color: '#777' }} >
                          <Trans>trans_33_register_authorize</Trans>
                        </span>
                        &nbsp;
                        <a style={{ color: '#000' }} href='https://www.voxcollector.com/pdf/AI_TOS.pdf'>
                          <Trans>trans_33_register_authorize_tos</Trans>
                        </a>
                  </label>
                </div>
              </div>


            <div>
              
            </div>


            <div style={{ textAlign: 'right',padding: "0 10px 0 10px" }}>
              <Button variant='primary' disabled={loading} onClick={modalConfirmOpen}>
                <Trans>trans_33_generate</Trans>
              </Button>
              {loading && (
                <img
                  alt='load ing'
                  src='data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA=='
                />
              )}
            </div>


          </Form>


        </Modal.Body>
        <br />


      </Modal>
    );
  }

  onVoxSelected(IdVox) {
    this.setState({ selectedIdVox: IdVox });
  }

  handleTosCheckboxChange(e) {
    const { name, checked } = e.target;
    this.setState({ acceptTosPolicy: checked });
  }
}

export default ModalGenerateQuestions;
