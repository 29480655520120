import React from 'react';
import { toast } from "react-toastify";


import { serverService } from '../../_services/server.service';
import i18n from "i18next";
import ItemsSelect from "../_other/ItemsSelect";

import moment from 'moment'

const $ = require('jquery');
$.DataTable = require('datatables.net');
 

require( 'datatables.net-bs4' );
require( 'datatables.net-select-bs4' );
require( 'datatables.net-buttons-bs4' )();
require( 'datatables.net-buttons/js/buttons.html5.js' )();


interface MyWindow extends Window {
    JSZip: any;
}
declare let window: MyWindow;

window.JSZip = require('jszip');



interface MyProps {
}

interface MyState {
    loading: boolean,
    allResults:any,
    filtredResults: any,
    skills: any,
    selectedIdSkill: number,

    tags: any,
    selectedIdTag: number,

    voxes: any,
    selectedIdVox: number,
    groupVoxesByDate: boolean,
}

class ResultsStandardTableTable extends React.Component<MyProps,MyState> {
    private userObj;

    constructor(props) {
        super(props);

        this.state = {
            loading:false,
            allResults:[],
            filtredResults: [],
            skills:[],
            selectedIdSkill:0,
            tags: [],
            selectedIdTag: 0,
            voxes: [],
            selectedIdVox:  0,
            groupVoxesByDate: true,
        };


        this.onSkillSelected = this.onSkillSelected.bind(this);
        this.onTagSelected = this.onTagSelected.bind(this);
        this.onVoxSelected = this.onVoxSelected.bind(this);
        this.handleCheckboxChange = this.handleCheckboxChange.bind(this);


        let userStr = localStorage.getItem('user');
        let IdCompany = 0;
        if(userStr) {
            this.userObj = JSON.parse(userStr);
            IdCompany = this.userObj.IdCompany;
        }

    }

    componentDidMount() {
        let isTheDifferenceColumnVisible = false;
        if(this.userObj && (this.userObj.LicenseType == 2 || this.userObj.LicenseType == 3)){
            isTheDifferenceColumnVisible = true;
        }
        // display: flex; align-items: center;
        let table = $(this.refs.main).DataTable({
            dom: "<'data-table-wrapper'" +
                    "<'row'<'col-md-10 my-auto'<'toolbar'>><'col-md-2'f>>" +
                    // "<'row'<'col-md-6 my-auto'><'col-md-6'f>>" +
                    "<'row'<'col-sm-12'tr>>" +
                    "<'row'<'col-sm-12 col-md-5'B><'col-sm-12 col-md-7'p>>" +
                    ">",

            data: this.state.filtredResults,
            columns : [
                {
                    title: 'Date',
                    data: 'Date'
                },
                {
                    title: 'VoxName',
                    data: 'VoxName'
                },
                {
                    title: 'Question',
                    data: 'QuestionText'
                },
                {
                    title: 'Difference',
                    data: 'Difference'
                },
                {
                    title: 'Company Yes[%]',
                    data: 'CompanyYesPercentage'
                },
                {
                    title: 'Company Votes',
                    data: 'CompanyNumberOfAnswers'
                },
                {
                    title: 'Main variable',
                    data: 'MainVariable'
                },

            ],
            ordering: true,
            paging:true,
            buttons: [
                {
                     extend: 'excel'
                     , text: i18n.t("trans_33_export_excel") 
                     , exportOptions: {
                        columns: ':visible'
                      }
                }
            ],
            "order": [[ 0, "desc" ]],

           
            language: {
                search: i18n.t("trans_33_search"),
                emptyTable:  i18n.t("trans_33_table_no_records"),
                paginate: {
                    first: i18n.t("trans_33_first"),
                    previous: i18n.t("trans_33_previous"),
                    next: i18n.t("trans_33_next"),
                    last: i18n.t("trans_33_last")
                }
            },


            columnDefs: [
                {
                    render: function (data, type, row) {
                        if (type === "display" || type === "filter") {
                            if (data != null) {
                                return moment(data).format("DD/MM/YYYY");
                            } else {
                                return "";
                            }
                        }
                        return data;
                    },
                    targets: 0,
                },

                {
                    visible: isTheDifferenceColumnVisible,
                    render: function (data, type, row) {
                        if (data == null || data.length == 0) {
                            return "<div style=\"background:#52B2E8;width:50px; height:20px\">" +
                                "<span style=\"color:#52B2E8;font-size:1px;\">No benchmark</span>" +
                                "</div>";
                        } else {

                            if(data == 0){
                                return "<div style=\"background:#fabd30;width:50px; height:20px\">" +
                                    "<span style=\"color:#fabd30;font-size:1px;\">The same</span>" +
                                    "</div>";
                            } else if(data < 0){
                                return "<div style=\"background:#F1511B;width:50px; height:20px\">" +
                                    "<span style=\"color:#F1511B;font-size:1px;\">To improve</span>" +
                                    "</div>";
                            } else {
                                return "<div style=\"background:#50BE3B;width:50px; height:20px\">" +
                                    "<span style=\"color:#50BE3B;font-size:1px;\">Good</span>" +
                                    "</div>";
                            }
                        }
                    },
                    targets: 3,
                },

            ]
        });


        this.setState({
            loading:false,
            allResults: [],
            filtredResults: [],
        });

        this.doFetchAll();



        serverService.getAllActiveVoxes().then(result => {
            if(result.status > 0){
                let voxItems = [];
                for(let i=0;i<result.data.length;i++){
                    voxItems.push({'IdItem': result.data[i].IdVox, 'Name': result.data[i].Name})
                }
                this.setState({voxes: voxItems});
            }
        });



        serverService.getQPickerTags().then(result => {
            if(result.status > 0){
                let tagsItems = [];
                for(let i=0;i<result.data.length;i++){
                    tagsItems.push({'IdItem': result.data[i].IdTag, 'Name': result.data[i]['Name']});
                }

                this.setState({tags: tagsItems});
            }
        });

        serverService.getSkillsByType(0)
            .then(
                resultsRes => {
                    if (resultsRes && resultsRes.data != undefined) {
                        let skills = resultsRes.data;
                        let skillsFormatted = [];
                        for(let i=0;i<skills.length;i++){
                            skillsFormatted.push({"IdItem": skills[i].IdSkill,"Name": skills[i].Name })
                        }
                        this.setState({
                            skills: skillsFormatted,
                        });
                    }
                }
            );
    }

    componentWillUnmount(){
        $('.data-table-wrapper')
            .find('table')
            .DataTable()
            .destroy(true);
    }

    // componentDidUpdate(oldProps) {
    //     console.log("this.state.filtredResults");
    //     console.log(this.state.filtredResults);
    //
    //     console.log("oldProps.filtredResults");
    //     console.log(oldProps.filtredResults);
    //
    //     if(this.state.filtredResults !== oldProps.filtredResults){
    //         reloadTableData(this.state.filtredResults);
    //     }
    // }

    render() {

        return (
            <>
                <div id='toolbar'>

                  <span style={{width:'170px',float:'left'}}>
                        <ItemsSelect
                            selectedIdItem={this.state.selectedIdVox}
                            items={this.state.voxes}
                            onItemSelected={this.onVoxSelected}
                            defaultText={i18n.t('trans_33_select_vox')}
                        />
                    </span>
                    <span style={{width:'170px',float:'left',marginLeft:'5px'}}>
                         <ItemsSelect
                             selectedIdItem={this.state.selectedIdSkill}
                             items={this.state.skills}
                             onItemSelected={this.onSkillSelected}
                             defaultText={i18n.t('trans_33_select_category')}
                         />
                    </span>

                    {this.userObj && this.userObj['Type'] == 1 &&
                        <span style={{width:'170px',float:'left',marginLeft:'5px'}}>
                            <ItemsSelect
                                selectedIdItem={this.state.selectedIdTag}
                                items={this.state.tags}
                                onItemSelected={this.onTagSelected}
                                defaultText={i18n.t('trans_33_select_tag')}
                            />
                        </span>
                    }

                    <div className="custom-control custom-checkbox" style={{float:'left',marginLeft:'15px'}}>
                        <input
                            checked={this.state.groupVoxesByDate}
                            className="custom-control-input"
                            type="checkbox"
                            id="groupVoxesByDate"
                            name="groupVoxesByDate"
                            onChange={this.handleCheckboxChange}
                        />
                        <label
                            className="custom-control-label"
                            htmlFor="groupVoxesByDate"
                        >
                            Group voxes by date
                        </label>
                    </div>



                </div>


                <div className="row" style={{clear: 'both'}}>
                    <div  className="col-md-12" id="questionTableContainer">
                        <table ref="main" className="table table-bordered questionsTable" />
                    </div>
                </div>
            </>
        );

    }



    doFetchAll(){

     

        this.setState({loading: true});

       // console.log("Selected category: " + this.state.selectedIdSkill);

        console.log("this.state.groupVoxesByDate: " + this.state.groupVoxesByDate);

        serverService.getResultsStandardTable(this.state.selectedIdSkill
            , this.state.selectedIdVox, this.state.selectedIdTag, this.state.groupVoxesByDate?1:0)
            .then(
                resultsRes => {
                    if(resultsRes && resultsRes.data != undefined){

                        let results = resultsRes.data.results;
                        let skills = resultsRes.data.skills;
                        let allSkills = resultsRes.data.allSkills;
                        let question_skills = {};
                        for(let i=0;i<skills.length;i++){
                            if(question_skills[skills[i].IdQuestion] === undefined){
                                question_skills[skills[i].IdQuestion] = [];
                            }
                            question_skills[skills[i].IdQuestion].push(skills[i]);
                        }


                        let filtredResults = [];
                        let tempResultRow;
                        for(let i=0;i<results.length;i++){
                            tempResultRow = results[i];


                            let oneQuestionSkills = question_skills[results[i].IdQuestion];
                            let MainVariable = "";
                            let SecondVariable = "";

                            let mainVariableWeight = 0;
                            let absMainVariableWeight = 0;

                            let secondVariableWeight = 0;
                            let absSecondVariableWeight = 0;

                            let selectedVariable = {};


                            let foundIdSkill = false;

                            if(oneQuestionSkills !== undefined){
                                for(let j=0;j<oneQuestionSkills.length;j++){
                                    if(oneQuestionSkills[j]['IdSkill'] == this.state.selectedIdSkill){
                                        foundIdSkill = true;
                                        selectedVariable = oneQuestionSkills[j]
                                    }
                                    if(oneQuestionSkills[j].Type === 0){
                                        if(Math.abs(oneQuestionSkills[j].Weight) > absMainVariableWeight){
                                            absSecondVariableWeight = absMainVariableWeight;
                                            secondVariableWeight = mainVariableWeight;

                                            mainVariableWeight = oneQuestionSkills[j].Weight;
                                            absMainVariableWeight = Math.abs(oneQuestionSkills[j].Weight);


                                            SecondVariable = MainVariable;
                                            MainVariable = oneQuestionSkills[j].Name;
                                        } else {
                                            if(Math.abs(oneQuestionSkills[j].Weight) > absSecondVariableWeight){
                                                secondVariableWeight = oneQuestionSkills[j].Weight;
                                                absSecondVariableWeight = Math.abs(oneQuestionSkills[j].Weight);

                                                SecondVariable = oneQuestionSkills[j].Name;
                                            }
                                        }
                                    }
                                }
                            }


                            if(this.state.selectedIdSkill > 0 && !foundIdSkill){

                            } else {


                                if(this.state.selectedIdSkill > 0){
                                    MainVariable = selectedVariable['Name'];
                                    mainVariableWeight = selectedVariable['Weight'];
                                }

                                if(MainVariable.includes("Stress")){
                                    mainVariableWeight = mainVariableWeight * -1
                                }


                                tempResultRow['MainVariable'] = MainVariable;
                                tempResultRow['SecondVariable'] = SecondVariable;
                                tempResultRow['Skills'] = oneQuestionSkills;
                                tempResultRow['MainVariableWeight'] = mainVariableWeight;


                                // console.log(selectedVariable['Name'] + " weight: " + selectedVariable['Weight']);
                                // console.log("CompanyYesPercentage: " + tempResultRow['CompanyYesPercentage'] );
                                // console.log("BenchmarkYesPercentage: " + tempResultRow['BenchmarkYesPercentage'] );


                                if(MainVariable && MainVariable.length > 0){
                                    if(mainVariableWeight > 0){
                                        tempResultRow['Difference'] = tempResultRow['CompanyYesPercentage'] - tempResultRow['BenchmarkYesPercentage'];
                                    } else {
                                        tempResultRow['Difference'] = tempResultRow['BenchmarkYesPercentage'] - tempResultRow['CompanyYesPercentage'];
                                    }

                                } else {
                                    tempResultRow['Difference'] = "";
                                }


                                filtredResults.push(tempResultRow)
                            }
                        }

                        this.setState({allResults: results, filtredResults: filtredResults, loading: false },() => {
                            reloadTableData(this.state.filtredResults);
                        });
                    } else {
                        this.setState({loading:false})
                    }
                },
                error => {
                    toast.warn("Did not get results for company");
                    this.setState({ loading: false });
                }
            );
    }


    onVoxSelected(IdVox){
        // console.log("IdVox: " + IdVox);
        this.setState({ selectedIdVox: IdVox }, function(){
            this.doFetchAll()
        });

    }

    onSkillSelected(IdSkill){
        // console.log("IdSkill: " + IdSkill);

        changeMainVariableColumnName(IdSkill);

        this.setState({ selectedIdSkill: IdSkill}, function(){
            this.doFetchAll()
        });

    }

    onTagSelected(IdTag){
        console.log("IdTag: " + IdTag);
        this.setState({ selectedIdTag: IdTag }, function(){
            this.doFetchAll()
        });
    }


    handleCheckboxChange(e) {
        const { name, checked } = e.target;
        this.setState({ groupVoxesByDate: checked,}, () => {
            this.doFetchAll();
        });
    }


}


function changeMainVariableColumnName(IdSkill){
    let title = "Main Variable";
    if(IdSkill > 0){
        title = "Selected Variable";
    }
    const table = $('.data-table-wrapper')
        .find('table')
        .DataTable();
    $( table.column(6).header() ).text(title);
}


function reloadTableData(results) {
    const table = $('.data-table-wrapper')
        .find('table')
        .DataTable();
    table.clear();
    table.rows.add(results);
    table.draw();

    let toolbar =  $("#toolbar");

    $("div.toolbar").html(toolbar);
}


export default ResultsStandardTableTable;
