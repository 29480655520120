import React from 'react';
import {Button,Modal,Form,Row,Col} from 'react-bootstrap'
import { toast } from "react-toastify";
import {serverService} from "../../_services/server.service";
import {Trans} from "react-i18next";
import i18n from "i18next";

interface MyProps {
    show:boolean,
    onHide(): any;
    theProps: any;
}
class ModalCalendly extends React.Component<MyProps> {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    render() {
        const theProps = this.props.theProps;
        return (
            <Modal
                show={this.props.show}
                onHide={this.props.onHide}
                size="xl"
                dialogClassName="modal-product"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header style={{borderBottom: 0}} closeButton translate="yes">
                </Modal.Header>
                <Modal.Body style={{display: 'flex'}}>
                       <Col xs={12}>
                       <div>                 
                            <iframe className='enlargeMobile' src="https://calendly.com/matt-voxcollector" height="80vh" width="100%" frameBorder="0" title="Calendly"
                                style={{ display: 'block', background: 'transparent', border: 'none', height: '80vh' }}
                            ></iframe>
                      </div>
                       </Col>
                       <Col>
                       <div> 
               
                    
                       </div>
                       </Col>
                </Modal.Body>

            </Modal>
        );
    }




}

export default ModalCalendly;