/**
 * Created by Alex on 22-Mar-19.
 */
import React from 'react';
import {Form,Button,Modal} from 'react-bootstrap'
import { toast } from "react-toastify";
import ModalImage from "react-modal-image";
import {Trans} from "react-i18next";

interface MyProps {
    show:boolean,
    onHide():any,
}

interface MyState {
    loading: boolean,
    error: string,
}


class ModalLoadingQuestionPack extends React.Component<MyProps,MyState> {

    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            error: '',
        };
    }

    componentDidMount() {
    }

    render() {
        return (
            <Modal
                show={this.props.show}
                onHide={this.props.onHide}
                size="lg"
                dialogClassName="modal-90w modalLoadingQuestionPack"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                backdrop="static"
            >
                <Modal.Body className="modalLoadingQuestionPackBody">
                        <br/>

                        <p><Trans>trans_33_modal_loading_ai_pack_creating_your_questions</Trans></p>

                        <p><Trans>trans_33_modal_loading_ai_pack_may_take_5_to_10_seconds</Trans></p>

                        <p>
                        <ModalImage
                            small={"/assets/waiter_small.gif"}
                            large={"/assets/waiter_small.gif"}
                            alt="Loading robot"
                        /> 
                        </p>


                        <p><Trans>trans_33_modal_loading_ai_pack_preparing_the_dance_floor</Trans></p>
                </Modal.Body>
            </Modal>
        );
    }


}

export default ModalLoadingQuestionPack;