/**
 * Created by Alex on 22-Mar-19.
 */
import React from 'react';

import { Button, Modal, Form, Row, Col,OverlayTrigger, Popover } from 'react-bootstrap';
import { toast } from 'react-toastify';

import { serverService } from '../../_services/server.service';
import { Trans } from 'react-i18next';
import i18n from 'i18next';
import ItemsSelect from '../_other/ItemsSelect';

interface MyProps {
  show: boolean;
  company: any;
  onHide(): any;
  onVerifyInfo1NextClicked(formFields: any): any;
}

interface MyState {
  submitted: boolean;
  loading: boolean;
  error: string;
  formFields: any;
  industries: any;

  isVatValidationSubmitted: boolean;
  isVatValid: boolean;
  vatAddress: string;

  company: any;
  environments: any;
  numberOfUsers: any;
  countries: any;

}

class ModalNewScanVerifyInfo1 extends React.Component<MyProps, MyState> {
  private countriesObj;

  constructor(props) {
    super(props);

    let industriesObj = i18n.t('trans_33_industries', { returnObjects: true });

    this.countriesObj = i18n.t('trans_33_countries', {
      returnObjects: true,
    });

    let environmentsObj = i18n.t('trans_33_environments', {
      returnObjects: true,
    });
    let numberOfUsersObj = i18n.t('trans_33_number_of_users', {
      returnObjects: true,
    });


    this.state = {
      submitted: false,
      loading: false,
      error: '',

      formFields: {},
      industries: industriesObj,

      isVatValidationSubmitted: false,
      isVatValid: false,
      vatAddress: "",

      company: {},

      environments: environmentsObj,
      numberOfUsers: numberOfUsersObj,
      countries: this.countriesObj,
      
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);


    this.onModalEntered = this.onModalEntered.bind(this);
    this.onIndustrySelected = this.onIndustrySelected.bind(this);
    this.onEnvironmentSelected = this.onEnvironmentSelected.bind(this);
    this.onNumberOfUsersSelected = this.onNumberOfUsersSelected.bind(this);
    this.onCountrySelected = this.onCountrySelected.bind(this);


    this.getCompanyInfo = this.getCompanyInfo.bind(this);


  }

  handleChange(e) {
    const { name, value } = e.target;
    let formFields = this.state.formFields;
    formFields[name] = value;
    this.setState({ formFields: formFields });
  }


  handleSubmit(e) {
    e.preventDefault();

    this.setState({ submitted: true, loading: true });

    const { formFields } = this.state;
    const CompanyName = formFields.CompanyName;
    const IdIndustry = formFields.IdIndustry;
    const IdNumberOfUsers = formFields.IdNumberOfUsers;
    const IdEnvironment = formFields.IdEnvironment;
    const IdCountry = formFields.IdCountry;


    // stop here if form is invalid
    if (!CompanyName || !IdIndustry || !IdNumberOfUsers
          || !IdEnvironment || !IdCountry) {
      toast.error(i18n.t('trans_33_msg_missing_fields'));
      this.setState({ submitted: false, loading: false });
      return;
    }
  
   this.props.onVerifyInfo1NextClicked(formFields);


  }

  render() {
    let { formFields, industries, loading } = this.state;

    return (
      <Modal
        show={this.props.show}
        onHide={this.props.onHide}
        onEntered={this.onModalEntered}
        dialogClassName='modal-90w'
        aria-labelledby='contained-modal-title-vcenter'
        centered
        backdrop="static"
      >
        <Modal.Header className="add-vox-modal-header" translate="yes">
            <Modal.Title id="example-custom-modal-styling-title">
            Verify information
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={this.handleSubmit}>
            <Form.Group controlId='formCompanyName'>
              <Form.Label>
                Company short name
              </Form.Label>
              <Form.Control
                size='sm'
                type='text'
                placeholder={i18n.t('trans_33_type_in_the_company_name')}
                name='CompanyName'
                value={formFields.CompanyName}
                onChange={this.handleChange}
                // disabled={true}
              />
            </Form.Group>

            <Form.Group controlId='formIndustry'>
              <Form.Label>
                Company Industry
              </Form.Label>

              <ItemsSelect
                selectedIdItem={formFields.IdIndustry}
                items={industries}
                onItemSelected={this.onIndustrySelected}
                defaultText={i18n.t('trans_33_select_industry')}
              />
            </Form.Group>

           
            <Form.Group controlId='formEstimatedNumOfUsers'>
              <Form.Label>
                Number of people
              </Form.Label>

              <ItemsSelect
                selectedIdItem={formFields.IdNumberOfUsers}
                items={this.state.numberOfUsers}
                onItemSelected={this.onNumberOfUsersSelected}
                defaultText={i18n.t('trans_33_select_vox_number_of_users')}
              />
            </Form.Group>


            <Form.Group controlId='formEnvironment'>
              <Form.Label>
                Working environment
              </Form.Label>

              <ItemsSelect
                selectedIdItem={formFields.IdEnvironment}
                items={this.state.environments}
                onItemSelected={this.onEnvironmentSelected}
                defaultText={i18n.t('trans_33_select_vox_environment')}
              />
            </Form.Group>



            <Form.Group controlId='formCountry'>
              <Form.Label>
                Team country
              </Form.Label>

              <ItemsSelect
                selectedIdItem={formFields.IdCountry}
                items={this.state.countries}
                onItemSelected={this.onCountrySelected}
                defaultText={"Select country"}
              />
            </Form.Group>

            <div className='p-2' style={{textAlign:'right'}}>
                <Button variant='primary' className='px-4 py-2' size='lg'  type='submit' disabled={loading}>
                  <Trans>trans_33_next</Trans>
                </Button>
                {loading && (
                  <img
                    alt='loading'
                    src='data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA=='
                  />
                )}
            </div>
            </Form>

         
        </Modal.Body>
        <br />
      </Modal>
    );
  }



  onIndustrySelected(IdIndustry) {
    let formFields = this.state.formFields;
    formFields['IdIndustry'] = IdIndustry;
    this.setState({ formFields: formFields });
  }

  onEnvironmentSelected(IdEnvironment) {
    let formFields = this.state.formFields;
    formFields['IdEnvironment'] = IdEnvironment;
    this.setState({ formFields: formFields });
  }

  onNumberOfUsersSelected(IdNumberOfUsers) {
    let formFields = this.state.formFields;
    formFields['IdNumberOfUsers'] = IdNumberOfUsers;
    this.setState({ formFields: formFields });
  }


  onCountrySelected(IdCountry) {
    let formFields = this.state.formFields;
    formFields['IdCountry'] = IdCountry;
    this.setState({ formFields: formFields });
  }


  
  onModalEntered() {

    let formFields = this.state.formFields;
    formFields.CompanyName = this.props.company.Name;
    formFields.IdIndustry = this.props.company.IdIndustry;

    this.setState({ formFields: formFields });

    this.getCompanyInfo();
  }


  getCompanyInfo() {

    let userStr = localStorage.getItem('user');
    let userObj = null;
    if(userStr) {
      userObj = JSON.parse(userStr);
    }

    serverService.getCompany(userObj.IdCompany).then(
      (response) => {
        if (response && response.data != undefined && response.data.length > 0) {

          let company = response.data[0];
          console.log(company);

          let formFields = this.state.formFields;
          formFields.CompanyName = company.Name;
          formFields.IdIndustry = company.IdIndustry;

        
          this.setState({ formFields: formFields });

       
        } else {
          this.setState({ loading: false });
        }
      },
      (error) => {
        toast.warn("Did not get company");
        this.setState({ loading: false });
      }
    );
  }

}


export default ModalNewScanVerifyInfo1;
