import React from "react";
import PulseTable from "./PulseTable";
import PulseChannelsTable from "./PulseChannelsTable";
import PulseAnswersTable from "./PulseAnswerTable";
import { serverService } from "../../_services/server.service";

interface MyProps {

}

interface MyState {
    channels: any,
    pulse: any
}

class PulsePage extends React.Component<MyProps, MyState> {
  constructor(props) {
    super(props);

    this.state = {
        channels: [],
        pulse: {}
    };

    this.onPulseDeleted = this.onPulseDeleted.bind(this);
    this.onChannelDeleted = this.onChannelDeleted.bind(this);
    this.onChannelsFetched = this.onChannelsFetched.bind(this);
  }

  componentDidMount() {}

  componentWillUnmount() {} 

  render() {
    const { pulse } = this.state
    return (
      <div className="container mt-5 p-5" id="resultsContainer">

        {
          !pulse?.id
            ? (
              <>
                <PulseChannelsTable onChannelDeleted={this.onChannelDeleted} onChannelsFetched={this.onChannelsFetched} />
                <br/><br/>
                <PulseTable
                  onPulseDeleted={this.onPulseDeleted}
                  channels={this.state.channels}
                  onAnswerClick={(pulseId, name) => { 
                    serverService.getPulseQuestions(pulseId).then(
                      questionsRes => {
                        this.setState({ pulse: {
                          questions: questionsRes.data,
                          id: pulseId,
                          name,
                        }});
                      }
                    );
                  }}
                />
              </>
            ) : (
              <PulseAnswersTable
                onCancel={() => this.setState({ pulse:null }) }
                pulse={pulse}
              />
            )
        }
      </div>
    );
  }


    onPulseDeleted() {}

    onChannelDeleted(){}

    onChannelsFetched(channels){
        this.setState({channels: channels});
    }
}

export { PulsePage };
