/**
 * Created by Alex on 22-Mar-19.
 */
import React from 'react';
import {Button,Modal,Form,Row,Col,Jumbotron} from 'react-bootstrap'
import { toast } from "react-toastify";

import { serverService } from '../../_services/server.service';
import i18n from "i18next";
import {Trans} from "react-i18next";
import VoxSelect from "../_other/VoxSelect";


interface MyProps {
    onHide():any,
    show:boolean,
    size?:any,
    IdVox: number,
}

interface MyState {
    submitted: boolean,
    loading: boolean,
    error:string,
    success:string,
    formFields:any,
    radio1Checked:boolean,
}

class ModalTextMessage extends React.Component<MyProps,MyState> {

    constructor(props) {
        super(props);

        this.state = {
            submitted: false,
            loading: false,
            error: '',
            success: '',

            formFields:{},

            radio1Checked: true,
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleRadioChange = this.handleRadioChange.bind(this);
        this.onEntered = this.onEntered.bind(this);

    }

    onEntered(){
        let formFields = this.state.formFields;
        formFields.DefaultText = i18n.t("trans_33_intro_slack");
        this.setState({ formFields: formFields });
    }


    handleChange(e) {
        const { name, value } = e.target;
        let formFields = this.state.formFields;
        formFields[name] = value;
        this.setState({ formFields: formFields });
    }

    handleSubmit(e) {
        e.preventDefault();

        this.setState({submitted: true, loading: true,});

        let formFields = this.state.formFields;

        if(formFields.DefaultText.length == 0 || !this.props.IdVox){
            toast.warn(i18n.t("trans_33_missing_fields"));
            return;
        }


        let TextToSend = formFields.DefaultText;
        if(formFields.UserText && formFields.UserText.length > 0){
            TextToSend = formFields.UserText;
        }

        TextToSend = TextToSend.replaceAll("<br/>", "\n");
        TextToSend = TextToSend.replaceAll("<br>", "\n");
        TextToSend = TextToSend.replaceAll("<b>", "*");
        TextToSend = TextToSend.replaceAll("</b>", "*");

        TextToSend += "\n\n\n.";

        if(!this.state.radio1Checked){
            let textMessage = {'TextMessage':TextToSend,'IdVox':this.props.IdVox};
            serverService.insertTextMessage(textMessage)
                .then(result => {
                    if(result.status === "1"){
                        toast.success(i18n.t("trans_33_text_message_saved"));
                        this.props.onHide();
                    } else {
                        toast.error("Did not send");
                    }
                    this.setState({submitted: false, loading: false,});
                }, error => {
                    console.log(error);
                    toast.error("Did not send");
                    this.setState({submitted: false, loading: false,});
                })
        } else {
            serverService.sendSlackTextMessage(TextToSend,this.props.IdVox)
                .then(result => {
                    if(result.status === "1"){
                        toast.success(i18n.t("trans_33_text_message_sent"));
                        this.props.onHide();
                    } else {
                        toast.error("Did not send");
                    }
                    this.setState({submitted: false, loading: false,});
                }, error => {
                    console.log(error);
                    toast.error("Did not send");
                    this.setState({submitted: false, loading: false,});
                })
        }

    }


    render() {
        let {formFields,loading} = this.state;

        return (
            <Modal
                show={this.props.show}
                onHide={this.props.onHide}
                size={this.props.size}
                onEntered = {this.onEntered}
                dialogClassName="modal-90w"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton translate="yes">
                    <Modal.Title id="example-custom-modal-styling-title">
                        <Trans>trans_33_text_message_welcome</Trans>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <Form onSubmit={this.handleSubmit}>
                        <Form.Group controlId="formCompanyName">
                            <Form.Label>
                                <Trans>Default text that will be sent</Trans>
                            </Form.Label>

                            <Jumbotron>
                                <div dangerouslySetInnerHTML={{__html: formFields.DefaultText}}/>
                            </Jumbotron>

                            <Form.Label>
                                or type-in your own text message
                            </Form.Label>

                            <Form.Control size="sm" as="textarea"
                                          name="UserText"
                                          rows={4}
                                          value={formFields.UserText}
                                          onChange={this.handleChange}
                            />
                        </Form.Group>


                        <Form.Check
                            inline
                            label="Send the message now"
                            type="radio"
                            id={`inline-radio-1`}
                            checked={this.state.radio1Checked}
                            onChange={this.handleRadioChange}
                        />


                        <Form.Check
                            inline
                            label="Send with the first question"
                            type="radio"
                            id={`inline-radio--2`}
                            checked={!this.state.radio1Checked}
                            onChange={this.handleRadioChange}
                        />

                    <div style={{textAlign:'right'}}>

                        <Button variant="danger" onClick={this.props.onHide}>
                            <Trans>
                                trans_33_dont_send_a_welcome_message
                            </Trans>
                        </Button>

                        &nbsp;&nbsp;

                        <Button variant="primary" type="submit" disabled={loading}>
                            <Trans>
                                trans_33_Send
                            </Trans>
                        </Button>
                        {loading &&
                        <img alt="loading" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                        }
                    </div>

                    </Form>

                </Modal.Body>
                <br/>
            </Modal>
        );
    }


    handleRadioChange(){
        this.setState({ radio1Checked: !this.state.radio1Checked });
    }


}

export default ModalTextMessage;