import React from "react";
import { toast } from "react-toastify";

import { Form, Button, ButtonToolbar } from "react-bootstrap";

import { serverService } from "../../_services/server.service";

import VoxSelect from "../_other/VoxSelect";
import LocationSelect from "../_other/LocationSelect";
import i18n from "i18next";
import { Trans } from "react-i18next";

const $ = require("jquery");
$.DataTable = require("datatables.net");

require("datatables.net-bs4");
require("datatables.net-select-bs4");
require("datatables.net-buttons-bs4")();
require("datatables.net-buttons/js/buttons.html5.js")();

interface MyWindow extends Window {
  JSZip: any;
}
declare let window: MyWindow;

window.JSZip = require("jszip");

const moment = require("moment");

interface MyProps {}

interface MyState {
  loading: boolean;
  answers: any;
  totalNumberOfAnswers: number;

  from: any;
  to: any;

  showVoxAndLocationFilters: boolean;

  selectedIdVox: number;
  selectedIdLocation: number;
}

class ResultsTable extends React.Component<MyProps, MyState> {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      answers: [],
      totalNumberOfAnswers: 0,

      from: undefined,
      to: undefined,

      showVoxAndLocationFilters: false,

      selectedIdVox: 0,
      selectedIdLocation: 0,
    };

    this.onVoxSelected = this.onVoxSelected.bind(this);
    this.onLocationSelected = this.onLocationSelected.bind(this);

    this.handleFromChange = this.handleFromChange.bind(this);
    this.handleToChange = this.handleToChange.bind(this);

    this.getNumberOfVoxesFetched = this.getNumberOfVoxesFetched.bind(this);
  }
  componentDidMount() {
    let table = $(this.refs.main).DataTable({
      dom:
        "<'data-table-wrapper'" +
        "<'row'<'col-md-6 my-auto'<'toolbar'>><'col-md-6'f>>" +
        "<'row'<'col-sm-12'tr>>" +
        "<'row'<'col-sm-12 col-md-5'B><'col-sm-12 col-md-7'p>>" +
        ">",
      data: this.state.answers,
      columns: [
        {
          title: i18n.t("trans_33_Date"),
          width: 60,
          data: "Date",
        },
        {
          title: i18n.t("trans_33_Vox"),
          width: 100,
          data: "Vox",
        },
        {
          title: i18n.t("trans_33_Question"),
          width: 250,
          data: "QuestionText",
        },
        {
          title: i18n.t("trans_33_results_table_yes_percentage"),
          width: 60,
          data: "YesPercentage",
        },
        {
          title: i18n.t("trans_33_results_table_votes"),
          width: 60,
          data: "NumberOfAnswers",
        },
      ],
      ordering: true,
      paging: true,
      buttons: [{ extend: "excel", text: i18n.t("trans_33_export_excel") }],
      order: [[0, "desc"]],
      language: {
        search: i18n.t("trans_33_search"),
        emptyTable: i18n.t("trans_33_table_no_records"),
        paginate: {
          first: i18n.t("trans_33_first"),
          previous: i18n.t("trans_33_previous"),
          next: i18n.t("trans_33_next"),
          last: i18n.t("trans_33_last"),
        },
      },
      columnDefs: [
        {
          render: function (data, type, row) {
            if (type === "display" || type === "filter") {
              if (data != null) {
                return moment(data).format("DD/MM/YYYY");
              } else {
                return "";
              }
            }
            return data;
          },
          targets: 0,
        },
      ],
    });

    this.setState({
      loading: false,
      answers: [],
      totalNumberOfAnswers: 0,
    });

    this.doFetchAll();
  }

  componentWillUnmount() {
    $(".data-table-wrapper").find("table").DataTable().destroy(true);
  }

  componentDidUpdate(oldProps) {
    if (this.state.answers !== oldProps.answers) {
      reloadTableData(this.state.answers);
    }
  }

  render() {
    const {
      totalNumberOfAnswers,
      from,
      to,
      selectedIdLocation,
      showVoxAndLocationFilters,
    } = this.state;
    const modifiers = { start: from, end: to };

    return (
      <div>
        <p>
          <Trans i18nKey="trans_33_results_table_congrats">
            text<strong>{{ totalNumberOfAnswers }}</strong>text
          </Trans>
        </p>

        <div className="row">
          <div className="col-md-12" id="questionTableContainer">
            <div id="toolbar" className="row">
              <div className="col-md-5">
                {showVoxAndLocationFilters && (
                  <Form.Group controlId="formLocation">
                    <Form.Label>
                      <Trans>trans_33_select_location</Trans>
                    </Form.Label>
                    <LocationSelect
                      onLocationSelected={this.onLocationSelected}
                    />
                  </Form.Group>
                )}
              </div>
              <div className="col-md-5">
                {showVoxAndLocationFilters && (
                  <Form.Group controlId="formVox">
                    <Form.Label>
                      <Trans>trans_33_select_vox</Trans>
                    </Form.Label>
                    <VoxSelect
                      onVoxSelected={this.onVoxSelected}
                      selectedIdLocation={selectedIdLocation}
                      getNumberOfVoxesFetched={this.getNumberOfVoxesFetched}
                    />
                  </Form.Group>
                )}
              </div>
              <div className="col-md-2">
                <div className="col-md-1">
                  {this.state.loading && (
                    <img
                      alt="loading"
                      src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA=="
                    />
                  )}
                </div>
              </div>
            </div>

            <table ref="main" className="table table-bordered" />
          </div>
        </div>
      </div>
    );
  }

  onVoxSelected(IdVox) {
    this.setState({ selectedIdVox: IdVox }, function () {
      this.doFetchAll();
    });
  }

  onLocationSelected(IdLocation) {
    this.setState({ selectedIdLocation: IdLocation }, function () {
      this.doFetchAll();
    });
  }

  doFetchAll() {
    let { selectedIdVox, selectedIdLocation, from, to } = this.state;

    let FromDate = "";
    if (from != undefined) {
      FromDate = moment(from).format("YYYY-MM-DD");
    }

    let ToDate = "";
    if (to != undefined) {
      ToDate = moment(to).format("YYYY-MM-DD");
    }

    let userStr = localStorage.getItem("user");
    let IdCompany = 0;
    if (userStr) {
      let userObj = JSON.parse(userStr);
      IdCompany = userObj.IdCompany;
    }

    this.setState({ loading: true });
    serverService
      .getResultsForCompany(
        IdCompany,
        selectedIdLocation,
        selectedIdVox,
        FromDate,
        ToDate
      )
      .then(
        (answersRes) => {
          if (answersRes && answersRes.data != undefined) {
            let totalNumberOfAnswers = 0;
            for (let i = 0; i < answersRes.data.length; i++) {
              totalNumberOfAnswers += answersRes.data[i].NumberOfAnswers;
            }
            this.setState({
              answers: answersRes.data,
              totalNumberOfAnswers: totalNumberOfAnswers,
              loading: false,
            });
          } else {
            this.setState({ loading: false });
          }
        },
        (error) => {
          toast.warn("Did not get results for company");
          this.setState({ loading: false });
        }
      );
  }

  showFromMonth() {
    const { from, to } = this.state;
    if (!from) {
      return;
    }
    if (moment(to).diff(moment(from), "months") < 2) {
      // this.to.getDayPicker().showMonth(from);
    }
  }

  handleFromChange(from) {
    // Change the from date and focus the "to" input field
    this.setState({ from }, function () {
      this.doFetchAll();
    });
  }

  handleToChange(to) {
    this.setState({ to }, function () {
      this.showFromMonth();
      this.doFetchAll();
    });
  }

  getNumberOfVoxesFetched(numberOfVoxes) {
    this.setState({ showVoxAndLocationFilters: numberOfVoxes > 1 });
  }
}

function reloadTableData(answers) {
  const table = $(".data-table-wrapper").find("table").DataTable();
  table.clear();
  table.rows.add(answers);
  table.draw();

  let toolbar = $("#toolbar");

  $("div.toolbar").html(toolbar);
}

export default ResultsTable;
