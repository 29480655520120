import React from 'react';

import { serverService } from '../../_services/server.service';
import { toast } from 'react-toastify';
import { Row, Col, Form, Button } from 'react-bootstrap';
import { RouteComponentProps, Link } from 'react-router-dom';

import { config } from '../../_helpers/C';

import { Trans } from 'react-i18next';
import i18n from 'i18next';
import TextField from '@material-ui/core/TextField';

import sha1 from 'sha1' 
const qs = require('query-string');



interface MyState {
  the_props: any;
}

class Middleware extends React.Component<
  RouteComponentProps,
  MyState
> {
  constructor(props) {
    super(props);

    this.state = {
      the_props: this.props.history.location.state,
    };

  }

  componentDidMount(){
    let userStr = localStorage.getItem('user');
    let user = null;
    let user_id = null;

   if(userStr !== null){
    let user = JSON.parse(userStr);
    user_id = user.IdUser;
   }

   let formFields = this.state.the_props.state.formFields;
   console.log("formFields");
   console.log(formFields);

    serverService.updateUserPaymentAndPlan(formFields)
    .then(result => {
        if(result.status === "1"){
            toast.success('Redirecting to dashboard.');
            window.location.href = '/home'
        } else {
            toast.error("Edit user failed");
        }
    });
  }


  render() {
    const { the_props } = this.state;

    return (
        <div style={{marginTop:'200px'}}>
          If you're seeing this message, it means the PUT request to /private/updateUserPaymentAndPlan was not successful.
          <br/>You can get the info you want from this request (the most important info is on formFields), here it goes:
          <pre>
         {JSON.stringify((the_props.state), null, "\t")}
         </pre>
        </div>
    );
  }

}

export { Middleware };
