import React from 'react';

import { withStyles, makeStyles } from '@material-ui/core/styles';
import Slider from '@material-ui/core/Slider';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import PubSub from '../../../_helpers/PubSub.js';

import { serverService } from '../../../_services/server.service';

import './CampaignStandardPage.css';
import SkillsSelect from '../../_other/SkillsMultiSelect';
import { Button } from 'react-bootstrap';
import { toast } from 'react-toastify';
import ModalQuestionsPack from './ModalQuestionsPack';
import ModalConfirmAction from '../../_other/ModalConfirmAction';
import ModalLoadingQuestionPack from './ModalLoadingQuestionPack';
import i18n from 'i18next';
import { Trans } from 'react-i18next';
import PdfsGrid from '../../AdvicesPage/PdfsGrid';
import QPacksGrid from './QPacksGrid';
import ModalSchedulePack from './ModalSchedulePack';
import ModalConfirmCreateQuestionPack from "./ModalConfirmCreateQuestionPack";

interface MyProps {
  onQuestionPackScheduled: any;
}

interface MyState {
  selectedChallangeMeasure: number;
  selectedFunkiness: number;
  selectedSkills: any;

  modalQuestionPackShow: boolean;
  modalConfirmOpenQuestionPack: boolean;
  modalLoadingQuestionPack: boolean;
  modalSchedulePack: boolean;
  selectedIdQPack: number;
  questionPackStartDate: string;
}

const marksMeasureSlider = [
  {
    value: 0,
    label: i18n.t('trans_33_Measure'),
  },
  {
    value: 5,
    label: i18n.t('trans_33_Challenge'),
  },
];

const marksFunkinessSlider = [
  {
    value: 0,
    label: i18n.t('trans_33_Library'),
  },
  {
    value: 5,
    label: i18n.t('trans_33_Tomorrowland'),
  },
];

const MeasureSlider = withStyles({
  root: {
    color: '#20ABEC',
    height: 8,
    width: 200,
  },
  thumb: {
    height: 24,
    width: 24,
    backgroundColor: '#fff',
    border: '1px solid currentColor',
    marginTop: -8,
    marginLeft: -12,
    '&:focus,&:hover,&$active': {
      boxShadow: 'inherit',
    },
  },
  active: {},
  valueLabel: {
    left: 'calc(-50% + 4px)',
  },
  track: {
    height: 8,
    borderRadius: 4,
  },
  rail: {
    height: 8,
    borderRadius: 4,
  },
})(Slider);

const FunkinessSlider = withStyles({
  root: {
    color: '#20ABEC',
    height: 8,
    width: 200,
  },
  thumb: {
    height: 24,
    width: 24,
    backgroundColor: '#fff',
    border: '1px solid currentColor',
    '&:focus,&:hover,&$active': {
      boxShadow: 'inherit',
    },
  },
  track: {
    width: 8,
    borderRadius: 4,
  },
  rail: {
    width: 8,
    borderRadius: 4,
  },
})(Slider);

class CampaignStandardPage extends React.Component<MyProps, MyState> {
  constructor(props) {
    super(props);

    this.state = {
      selectedChallangeMeasure: 3,
      selectedFunkiness: 3,
      selectedSkills: [],

      modalQuestionPackShow: false,
      modalConfirmOpenQuestionPack: false,
      modalLoadingQuestionPack: false,
      modalSchedulePack: false,
      selectedIdQPack: 0,
      questionPackStartDate: "",
    };

    this.onSkillsChanged = this.onSkillsChanged.bind(this);
    this.onMeasureChallengeSliderChanged = this.onMeasureChallengeSliderChanged.bind(
      this
    );
    this.onFunkinessSliderChanged = this.onFunkinessSliderChanged.bind(this);
    this.onOpenQuestionsClick = this.onOpenQuestionsClick.bind(this);

    this.onQuestionPackScheduled = this.onQuestionPackScheduled.bind(this);

    this.onConfirmCreateQuestionPack = this.onConfirmCreateQuestionPack.bind(
      this
    );
    this.closeConfirmOpenQuestionPack = this.closeConfirmOpenQuestionPack.bind(
      this
    );

    this.closeModalLoadingQuestionPack = this.closeModalLoadingQuestionPack.bind(
      this
    );
    this.onClickPack = this.onClickPack.bind(this);
  }

  componentDidMount() {
    this.setState({});
  }

  render() {
    let { selectedSkills } = this.state;

    let closeQuestionsPackModal = () =>
      this.setState({ modalQuestionPackShow: false });

    let modalSchedulePackClose = () =>
      this.setState({ modalSchedulePack: false });
    let modalSchedulePackClick = () => {
      this.setState({ modalSchedulePack: false });
      PubSub.publish('fetchDataForSelectedMonth', true);
    };

    let selectedSkillsString = '';
    for (let i = 0; i < selectedSkills.length; i++) {
      if (i > 0) {
        selectedSkillsString += ' / ';
      }
      selectedSkillsString += selectedSkills[i].Name;
    }

    let confirmDialogBody =
      i18n.t('trans_33_campaign_standard_chose_skills1') +
      ' <strong>' +
      selectedSkillsString +
      '</strong> ' +
      i18n.t('trans_33_campaign_standard_chose_skills2') +
      ' <br/><br/> ' +
      i18n.t('trans_33_campaign_standard_chose_skills3');

    let createQuestionPackBtnDisabled = 'disabled';
    if (selectedSkills.length >= 3) {
      createQuestionPackBtnDisabled = '';
    }

    return (
      <div  className="q-pack-container">
        <h4>
          <Trans>trans_33_use_existing_packs</Trans>
        </h4>
        <div style={{padding:"2rem"}} >
          <QPacksGrid 
            horizontal={true}
            rows={1}
            packType={0}
            onClickPack={this.onClickPack}
          />
        </div>

        <br />
        <br />
        <div className="custom-pack-content-container" >
        <h4>
          <Trans>trans_33_create_your_custom_pack</Trans>
        </h4>
        <div>
          <Trans>trans_33_campaign_standard_select_your_groove</Trans>
          <ul>
            <li>
              <Trans>trans_33_campaign_standard_funkiness_defines_how</Trans>
            </li>
            <li>
              <Trans>trans_33_campaign_standard_nudge_defines_if</Trans>
            </li>
          </ul>
        </div>
        </div>
        <div className='row' style={{ height: 300 }}>
          <div className='col-md-9 text-center'>
            <img
              src='/assets/images/tape-recorder-vox-collector.png'
              width='400'
              className='tape-recorder'
            />
            <br />
            <br />
            <br />
            <p id='text-title-nudge'>
              <Trans>trans_33_Nudge</Trans>
            </p>
            <div className="frunkiness-slider-container" >
                <p>Assess</p>
            <MeasureSlider
              min={1}   
              max={5}
              step={1}
              marks={marksMeasureSlider}
              valueLabelDisplay='auto'
              aria-label='measure challenge slider'
              defaultValue={this.state.selectedChallangeMeasure}
              onChangeCommitted={this.onMeasureChallengeSliderChanged}
            />
            <p>Challenge</p>
            </div>
          </div>

          <div className='col-md-3'>
            <div className="frunkiness-slider-right" >
              <p id='text-title-funkiness'>
                <Trans>trans_33_Funkiness</Trans>
              </p>
              <div className="frunkiness-iten" >
              <p>Tomorrowland</p>
              <FunkinessSlider
                orientation='vertical'
                aria-labelledby='vertical-slider'
                marks={marksFunkinessSlider}
                min={1}
                max={5}
                step={1}
                defaultValue={this.state.selectedFunkiness}
                onChangeCommitted={this.onFunkinessSliderChanged}
              />
              <p>Library</p>
             </div>
            </div>
          </div>
        </div>
        <br />
        <br />
        <br />
        <br />
        <div className='row'>
          <div className='col-md-12'>
            <div className="skillset_container" >
            <Trans>trans_33_campaign_standard_select_3_topics</Trans>
            <br />
            <SkillsSelect onSkillsChanged={this.onSkillsChanged} />
            </div>
          </div>
        </div>
        <br />
        <br />
        <div className='row'>
          <div className='col-md-12 text-center'>
            <Button
              onClick={this.onOpenQuestionsClick}
              className={createQuestionPackBtnDisabled}
            >
              <Trans>trans_33_campaign_standard_create_question_pack</Trans>
            </Button>
          </div>
        </div>
        <br />

        <ModalConfirmCreateQuestionPack
          show={this.state.modalConfirmOpenQuestionPack}
          descriptionText={confirmDialogBody}
          onHide={this.closeConfirmOpenQuestionPack}
          onConfirmCreateQuestionPack={this.onConfirmCreateQuestionPack}
        />

        <ModalLoadingQuestionPack
          show={this.state.modalLoadingQuestionPack}
          onHide={this.closeModalLoadingQuestionPack}
        />

        <ModalQuestionsPack
          fromSection = {"campaign"}
          selectedChallangeMeasure={this.state.selectedChallangeMeasure}
          selectedFunkiness={this.state.selectedFunkiness}
          selectedSkills={this.state.selectedSkills}
          show={this.state.modalQuestionPackShow}
          onHide={closeQuestionsPackModal}
          onQuestionPackScheduled={this.onQuestionPackScheduled}
          size='lg'
          StartDate={this.state.questionPackStartDate}
        />

        <ModalSchedulePack
          selectedIdQPack={this.state.selectedIdQPack}
          show={this.state.modalSchedulePack}
          onHide={modalSchedulePackClose}
          onConfirmSchedulePack={modalSchedulePackClick}
        />
      </div>
    );
  }

  onClickPack(selectedPack) {
    let IdQPack = selectedPack['IdQPack'];
    this.setState({ selectedIdQPack: IdQPack, modalSchedulePack: true });
  }

  onSkillsChanged(skills) {
    let selectedSkills = [];
    skills.forEach(oneSkill => {
      if (oneSkill.IsSelected) {
        selectedSkills.push(oneSkill);
      }
    });

    this.setState({ selectedSkills: selectedSkills });
  }

  onMeasureChallengeSliderChanged(event, newValue) {
    this.setState({ selectedChallangeMeasure: newValue });
  }

  onFunkinessSliderChanged(event, newValue) {
    this.setState({ selectedFunkiness: newValue });
  }

  onOpenQuestionsClick() {
    if (this.state.selectedSkills.length < 3) {
      toast.error(
        i18n.t('trans_33_campaign_standard_msg_need_to_select_3_skills')
      );
    } else {
      this.setState({ modalConfirmOpenQuestionPack: true });
    }
  }

  onQuestionPackScheduled() {
    this.props.onQuestionPackScheduled();
    this.setState({ modalQuestionPackShow: false });
  }

  onConfirmCreateQuestionPack(StartDate) {
    this.setState({
      modalConfirmOpenQuestionPack: false,
      modalLoadingQuestionPack: true,
      questionPackStartDate: StartDate
    });

    let that = this;
    let secondsToLoad = 5 + Math.floor(Math.random() * 6);

    setTimeout(function () {
      that.setState({
        modalLoadingQuestionPack: false,
        modalQuestionPackShow: true,
      });
    }, secondsToLoad * 1000);
  }

  closeConfirmOpenQuestionPack() {
    this.setState({ modalConfirmOpenQuestionPack: false });
  }

  closeModalLoadingQuestionPack() {
    this.setState({ modalLoadingQuestionPack: false });
  }
}

export default CampaignStandardPage;
