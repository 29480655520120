import React from 'react';

import { Button } from 'react-bootstrap';
import { Trans } from 'react-i18next';
import i18n from "i18next";

const $ = require('jquery');
$.DataTable = require('datatables.net');

require( 'datatables.net-bs4' );
require( 'datatables.net-select-bs4' );
require('../datatables.selected.css');

interface MyProps {
  answers: any;
  onHide: any;
}

class AnswersBodyModal extends React.Component<MyProps> {
  componentDidMount() {
    $(this.refs.mainanswer).DataTable({
        dom: "<'data-table-answers-wrapper'" +
                "<tr>" +
                ">",
        data: this.props.answers,
        ordering: false,
        paging:false,
        order: [[ 0, "desc" ]],
        buttons: [
            { extend: 'excel', text: i18n.t("trans_33_export_excel") }
        ],
        columns : [
            {
                title: `Answers (${this.props.answers.length})`,
                data: 'answer'
            },
        ],
    });
  }

  componentWillUnmount(){
    $('.data-table-answers-wrapper')
      .find('table')
      .DataTable()
      .destroy(true);
  }

  render() {

    return (
      <div>
        <div className="">
            <table ref="mainanswer" className="table table-no-border questionsAnswerTable" />
            <div className="d-flex justify-content-end pt-2 pr-4">
                  <Button
                    className='px-4 py-2 mr-3 btn-outline-primary-updated no-shrink'
                    variant="outline-primary"
                    size="lg"
                    onClick={() => {
                      $(this.refs.mainanswer)
                        .DataTable().button().trigger();
                    }}
                  >
                    <Trans>trans_33_export_excel</Trans>
                  </Button>
                  <Button
                      className='px-4 py-2 no-shrink'
                      id="addBtn"
                      variant="primary"
                      size="lg"
                      onClick={this.props.onHide}
                  >
                      <Trans>trans_33_close</Trans>
                  </Button>
                </div>
        </div>
      </div>
    );
  }
}

export { AnswersBodyModal };
