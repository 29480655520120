import React from 'react';
import {Button,Modal,Form,Row,Col} from 'react-bootstrap'
import { toast } from "react-toastify";
import {serverService} from "../../_services/server.service";
import {Trans} from "react-i18next";
import i18n from "i18next";

interface MyProps {
    show:boolean,
    onHide(): any,
    onTosAccepted():any,
}


class ModalTos extends React.Component<MyProps> {

    constructor(props) {
        super(props);
        this.state = {
        };

        this.onClickAccept = this.onClickAccept.bind(this);
    }

    render() {
        return (
            <Modal
                show={this.props.show}
                onHide={this.props.onHide}
                size="lg"
                dialogClassName="modal-90w"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                backdrop="static"
            >
                <Modal.Header translate="yes">
                    <Modal.Title id="example-custom-modal-styling-title">
                        VOXCOLLECTOR – Terms and Conditions
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                        <b>Effective starting 1-1-2015</b><br/>
                        <br/>
                        <br/><h4>1. GENERAL</h4>
                        VoxCollector is a service of GEMS SPRL , BE0890.002.120 (hereinafter referred to as “VoxCollector” or “We” or “The Company” or “The Service Provider”).<br/>
                        1.1 These end user terms (“EULA”), also referred as General Conditions set out the terms under which the User may use the Services.<br/>
                        All Users indicate having read the general conditions, and agree to them. These general conditions are also reminded to you when you register for our service, and are to be accepted before your start using our service.<br/>
                        These general conditions may change over time and you are required to check them frequently.<br/>


                        <br/><h4>2. SCOPE OF SERVICES</h4>
                        2.1 The Services may contain Terminal Services, Web Services and Consulting Services as agreed between the Parties in the Service Supply Agreement.<br/>
                        2.2 Results as regards the Services are provided to the User via the Service Provider’s Online Platform or by any other means. The User agrees to keep the Online Platform’s user account and credentials secure from third parties. The User is responsible for all acts or omissions relating to User’s user account.<br/>
                        2.3 Specific terms applicable to Terminal Services:<br/>
                        2.3.1 If not stated differently in the contract or offer, any hardware supplied to the User as a part of the Terminal Services is leased and is property of the Service Provider. The User shall after the expiry or termination of this EULA at the Service Provider’s sole discretion recycle the hardware at the User’s expense pursuant to the instructions provided by The Company or the Service Provider or return the hardware to The Company at the User’s expense.<br/>
                        2.3.2 The hardware terminal is supplied ready for use. The terminal (with or without tablet) contains a data transceiver module for sending and receiving information. The User is responsible for ensuring that the necessary, electrical power, network, data and telecommunication systems which the Terminal Services require are available. The Service Provider is not liable for any issues arising as a result of third party carrier network providers.<br/>
                        2.3.3 The User shall, depending on the terminal model, be responsible for changing the batteries when necessary or connecting the charger to the power outlet and, if required, acquiring new batteries at the User’s own expense. The User shall also replace the SIM card when requested to do so by The Company.<br/>
                        2.3.4 The User shall operate all terminals with due care and ensure that the terminals are only operated indoors. The User shall keep the terminals clean.<br/>
                        2.3.5 The User may not open the terminal or tamper with it in any way unless otherwise instructed by The Company.<br/>
                        2.3.6. The terminal may be delivered on tablets using Android or iOS or any other operating systems. The User is responsible for the updates of such systems.<br/>
                        2.3.7. The terminal will be delivered with scripts that cannot be modified, copied, or transferred.<br/>
                        2.4 Specific terms applicable to Web Services:<br/>
                        2.4.1 The Web Services can be executed on the Terminals or online. Their code cannot be modified, copied or transferred.<br/>
                        2b. USE OF THE WEBSITE<br/>
                        2b.1 The content of the website is provided for informational purposes and does not replace these terms and conditions. In the event of a dispute, only these are applicable.<br/>
                        2b.2 At any time, we can modify the content of the site without notice.<br/>
                        2b.3 The User assumes full responsibility for the use of VoxCollector’s website. Any material downloaded or otherwise obtained using the services is at the sole discretion and at the User's own risk. The User will be solely responsible for any damage caused to his computer system or any loss of data resulting from downloading this type of material. The services of VoxCollector are provided “as is” and “as available”. However, VoxCollector cannot guarantee that these services will not be uninterrupted, timely, secure or free from errors, that the results obtained by using the services will be accurate and reliable and that any software defects will be corrected.<br/>
                        2b.4 VoxCollector will use all reasonable efforts to publish information that is, to our knowledge, updated on the site. However, VoxCollector does not guarantee that this information is adequate, precise and exhaustive, nor that the site will be complete and permanently updated in all respects. The information on this site, including prices and charges, may contain content errors, technical errors or typographical errors. This information is presented for informative purpose only and will be periodically modified.<br/>
                        2b.5 VoxCollector cannot be held responsible for the content of messages, hypertext links, information, images, videos or any other content submitted by the User or customers using the VoxCollector services.<br/>
                        2b.6 VoxCollector cannot systematically control the content of its site. If the content appears to be illicit, illegal, contrary to public order or morality (example: violent content, pornographic, racist or xenophobic, defamatory, etc.), the User will inform VoxCollector about it. VoxCollector will remove any content that it considers in its sole discretion to be unlawful, illegal or contrary to public order or morality, without being held responsible for the failure to remove or maintain the content.<br/>
                        2b.7 The VoxCollector website may contain hypertext links to other sites. These links are provided to the User for information purposes only. VoxCollector does not control these websites or the information they contain. VoxCollector therefore cannot guarantee the quality and / or completeness of this information.<br/>
                        2b.8 VoxCollector cannot be held responsible for direct or indirect damage, nor for any other damage of any kind whatsoever, resulting from the use or the inability to use the site, whether this responsibility is or non-contractual, based on an infringement or a technical infringement, or whether it is a liability without fault, even if VoxCollector has been informed of the possibility of such damage. VoxCollector cannot be held responsible for acts committed by Internet users.<br/>

                        2c. RIGHT TO RETRACTATION<br/>
                        2c.1 By subscribing to our service online, you automatically waive your right of withdrawal applicable to online sales.<br/>

                        2d. RESTRICTIONS ON USE AND TRANSFER<br/>
                        The User acknowledges that when using the VoxCollector Services he renounces to:<br/>

                        2d.1 Rent parts or whole of the VoxCollector Services;<br/>
                        2d.2Transfer parts or whole of the VoxCollector Services;<br/>
                        2d.3 Copy or reproduce parts or whole of the VoxCollector Services via a local area network or other network system or via a computer network subscriber system or a computer network system with electronic billboard function;<br/>
                        2d.4 Modify, adapt or create derivative products based on or similar to parts or whole of the VoxCollector Services;<br/>
                        2d.5 Reverse engineer, decompile or disassemble parts or whole of the VoxCollector Services.<br/>



                        <br/><h4>3. INTELLECTUAL PROPERTY RIGHTS</h4>
                        3.1 Intellectual Property Rights in and to the Services shall remain the sole property of the Service Provider or its licensors.<br/>
                        3.2 The Service Provider grants to the User for the term of this EULA, a non-exclusive, non-transferable, non-sublicensable limited license to use the Services for the User’s internal purposes only.<br/>
                        3.3 The User shall not have the right to register, or to have registered, any trademark, trade names or other commercial designations of the Service Provider (or which are confusingly similar to those of the Service Provider).<br/>
                        3.4 The User shall not modify or attempt to modify, reproduce, translate, reverse engineer, decompile, disassemble or otherwise attempt to find out the source code of the Services, further develop the Services or write or develop any derivative software, software program or products based upon the Services or parts thereof.<br/>
                        3.5 The User shall notify the Service Provider immediately if the User becomes aware of any unauthorized use of any of the Services or any of the Intellectual Property Rights therein or relating thereto and will assist the Service Provider in taking all reasonable steps to defend the rights of the Service Provider therein.<br/>

                        <br/><h4>4. INDEMNIFICATION</h4>
                        4.1.0 The User agrees to defend, indemnify and hold harmless<br/>
                        VoxCollector, its parent company and its affiliates, as well as his employees, subcontractors, officers, directors, and respective agents, from all responsibilities, claims and expenses, including attorney's fees, which arise from The User’s use or misuse of VoxCollector.<br/>
                        4.1.1 The Service Provider agrees to defend the User, at its own expense, against any third party claims or actions where a third party claims that the Services infringe upon Intellectual Property Rights of a third party valid in the European Economic Area (EEA), provided that the User:<br/>
                        4.1.1 notifies the Service Provider of such claim the day upon receipt of notice thereof;<br/>
                        4.1.2 provides the Service Provider, free of charge, with all available information, permissions and assistance;<br/>
                        4.1.3 grants the Service Provider the exclusive and sole right to control the defence of the claim; and<br/>
                        4.1.4 does not agree on any settlement of such claim or action prior to a final judgment thereon by a competent court of law or court of arbitration, without the express prior written consent of the Service Provider.<br/>
                        4.2 If the User has acted in accordance with Sections 4.1.1 to 4.1.4, the Service Provider shall pay any damages finally awarded to the third party claimant by a competent court of law or court of arbitration.<br/>
                        4.3 If the Service Provider justifiably deems that the Services infringe or may infringe upon any third party rights, the Service Provider shall have the right, at its own expense and in its sole discretion, to (a) acquire for the User the right to continue the use Services; or (b) replace the Services; or (c) modify the Services to the extent necessary to avoid the infringement.<br/>
                        4.4 If none of the alternatives defined in Section 4.3 are available to the Service Provider on commercially reasonable terms and/or without the significant loss of time, the User agrees to cease using the Services, and, if applicable, the Service Provider agrees to reimburse the Fees paid by the User for the terminated Services, less a proportion equal to the time of use of the Services by the User.<br/>
                        4.5 The indemnity in this Section 4 shall not apply to, and the Service Provider is not liable for any claim that (a) is based on a claim by any User Affiliate; or (b) is based on the modification or alteration of the Services or a modification or alteration influencing the Services by the User or any third party; or (c) results from complying with any instructions, specifications or design given by the User or any third party under the command and control of the User; (d) arises or results from the use of the Services in combination with any software, equipment or products not developed or supplied by the Service Provider or which are contrary to instructions given by the Service Provider; or (e) could have been avoided by using the latest version of the Services made available by the Service Provider to the User.<br/>
                        4.6 This Section 4 sets out the entire liability of the Service Provider and the User’s sole remedy in case of any infringement of any Intellectual Property Rights.

                        <br/><h4>5. PERSONAL DATA AND USER DATA</h4>
                        5.1 The Service Provider may process personal data (or have such data processed) as regards the User and its authorized users for the purpose of providing and developing the Services. In this respect the Service Provider acts as data controller and the use of the Services is subject to the Service Provider’s Privacy Policy available at the Service Provider’s Web Site.<br/>
                        5.2 To the extent the User submits, processes or stores any personal data in to the  Services, the Service Provider (or its subcontractor where appropriate) processes such data on behalf and for the benefit of the User while the User remains at all times the data controller for such personal data. The User represents and warrants that it is authorized to instruct the Service Provider and its subcontractors to process such personal data. The User represents and warrants that it has the right to collect the personal data provided to the Service Provider for processing. The following data protection clauses reflect User’s complete written instructions to Service Provider in its role as data controller.<br/>
                        5.3 Service Provider shall implement appropriate technical and organizational measures to secure the User’s personal data from unauthorized access and accidental or unlawful processing. In the event of a personal data breach, the Service Provider shall notify the User in accordance with applicable data protection law without undue delay after becoming aware of such breach and take reasonable steps to mitigate any damage resulting from such breach.<br/>
                        5.4 The Service Provider is entitled to use subcontractors for the purposes of providing the Services. The Service Provider shall use its commercially reasonable efforts to ensure that its subcontractors are subject to equivalent requirements regarding confidentiality and data protection, as set out in in this EULA. Service Provider shall inform User upon request about the subcontractors used and changes in subcontractors. The User hereby consents to the Service Provider’s use of subcontractors as described in this section.<br/>
                        5.5 The Service Provider provides the User information on where The Company’s or its subcontractors’ servers are located at. The User shall be solely responsible for ensuring that it has the right to transfer personal data to the countries where the hosting partner’s servers are located. The User shall be responsible for ensuring that adequate safeguards are in place when personal data is transferred outside of EU/EEA.<br/>
                        5.6 The Service Provider shall, at the User’s written request and the User’s sole cost and expense, assist the User by providing such information, as the User may reasonably require and which the User does not have, in complying with the requests of the data subjects or competent authority. Service Provider shall make information available to the User that is reasonably necessary to demonstrate compliance with the applicable data protection laws, if requested by the User.<br/>
                        5.7 After the termination of this EULA for any reason, the Service Provider shall retain the User’s data for thirty (30) days and make it available to the User if requested. After the thirty (30) days’ period, the Service Provider shall have the right to destroy the User’s data without any liability towards the User.<br/>
                        5.8 As part of the VoxCollector Service offering, anonymous data on User or employee experiences are collected and stored stored in the virtual private cloud in a designated secure location chosen by The Company. The Company reserves the right to use this non-personally identifiable data to provide general statistics about HR topics and benchmarks across industries and to make any derived analysis commercially available at its discretion. The Company also reserves the right to use this data for the purposes of marketing or User awareness campaigns as it deems appropriate. That notwithstanding, at no time does The Company collect personally identifiable information nor will it reference the User’s use of the VoxCollector service without its prior written consent.<br/>

                        <br/><h4>6. SUSPENSION OF SERVICE</h4>
                        6.1 The Service Provider shall have the right to suspend the provision of the Services for a reasonable period of time without any liability towards the User if this is necessary in order to perform installation, change or maintenance work in respect of the Services or if such suspension results from installation, change or maintenance work in respect of public communication networks. The Service Provider shall also have the right to suspend the provision of the Services due to a data security risk to the Services or if law or administrative order requires the Service Provider to do so.<br/>
                        6.2 The Service Provider shall have the right suspend the provision of the Services and to deny the User’s access to the Services without first hearing the User, without any liability towards the User, if the Service Provider reasonably suspects that the User burdens or uses the Services contrary to this EULA, applicable laws or administrative orders or for a purpose prohibited by this EULA, applicable laws or administrative orders or in a manner that jeopardizes the provision of the Services to other users.<br/>
                        6.3 The Service Provider may also, for no reason, with a reimbursement calculated in proportion to the days elapsed in the month or by leaving access to the platform until the next billing cycle, cancel the User’s subscription.<br/>


                        <br/><h4>7. CONFIDENTIALITY</h4>
                        7.1. Each Party shall keep in confidence all material and information received from the other Party and marked as confidential or which should be understood to be confidential (“Confidential Information”), and may not use such Confidential Information for any other purpose than those set forth in this EULA. Confidentiality automatically applies to VoxCollector’s questions and question packs, questions schedule, results, analyses outputs, benchmarking, reports, posters and derivative products/services (ex: pirate game).<br/>
                        The confidentiality obligation shall, however, not apply to material and information, (a) which is generally available or otherwise public before the first use of the service or collaboration with VoxCollector; or (b) which the receiving Party has received from a third party without any obligation of confidentiality; or (c) which was rightfully in the possession of the receiving Party prior to receipt of the same from the disclosing Party without any obligation of confidentiality related thereto; or (d) which a Party has independently developed without any use of or reference to the Confidential Information received from the other Party.<br/>
                        7.2 The obligations of confidentiality in Section 7.1 above shall not prevent a Party from disclosing Confidential Information where it is required to do so under any mandatory law, or by order of a court or governmental body of authority of competent jurisdiction, or by any mandatory requirement of a regulatory authority. If legally possible and applicable, the recipient of such order shall notify the disclosing Party to allow a reasonable opportunity to seek protective order or equivalent or to appeal, and to extent reasonably possible, make effort to protect any sensitive information.<br/>
                        7.3 Each Party shall promptly upon termination of this EULA, or when the Party no longer needs the Confidential Information in question for the purpose of performing its obligations or exercising its rights under this EULA, cease using the Confidential Information received from the other Party and, unless the Parties separately agree on destruction of such Confidential Information, return the Confidential Information in question (including all copies and reproductions thereof) to the other Party. Each Party shall, however, be entitled to retain the copies required by law or administrative orders applicable to such Party.<br/>
                        7.4 Notwithstanding the confidentiality obligation set forth herein, each Party shall be entitled to use the general professional skills and experience acquired in connection with the performance of this EULA.<br/>
                        7.5 The rights and obligations related to the Confidential Information shall survive the termination of this EULA for any reason for a period of three (3) years from such termination.<br/>

                        <br/><h4>8. LIMITATION OF LIABILITY</h4>
                        8.1 The total aggregate liability of a Party towards the other Party under this EULA shall not exceed the amount of Fees for Services paid by the User to The Company during the twelve (12) months immediately preceding the event giving rise to liability. If the User has a free or paid access to the services, whether in production, development, or testing, any damage caused by a breach of section 2d (restriction on use and transfer) cannot be limited in liability. The compensation for such breach of section 2d will be assessed under Section 10 (Governing Law and disputes).<br/>
                        8.2 The Service Provider shall not be liable for any indirect, incidental, or consequential damages, loss of profits, revenue or business, damages caused due to decrease in turnover or production or loss, alteration, destruction or corruption of data.<br/>
                        8.3 The limitations of liability shall not apply to damages caused by willful misconduct or gross negligence or to liability depending on any breach of these EULA and more specifically under Section 2b (Use of the Website), 2d (restriction on use and transfer), 4 (Indemnification) or Section 7 (Confidentiality).<br/>
                        8.4 The User is solely responsible for making appropriate backups of its data, and the Service Provider or its subcontractors shall in no way be liable for any deletion of or failure to store any data of the User or other communications maintained or transmitted to the use of the Services.<br/>

                        <br/><h4>9. TERM AND TERMINATION</h4>
                        9.1 This EULA shall enter into force either, at the first login of the User to the Service Provider’s platform or at the effective date of the first Service Supply Agreement between both Parties and shall remain in force for the initial Term set out in the Service Supply Agreement after which it shall automatically be renewed for the same Term period unless terminated by a Party at least thirty (60) days prior to the end of the then-current term.<br/>
                        9.2 The Service Provider shall have the right to terminate this EULA and the User’s right to use the Services with immediate effect upon written notice to the User if the User is in material breach of the terms and conditions of this EULA and fails to remedy such breach within thirty (30) days from the date of receipt of a written notice by the Service Provider, such written notice detailing the breach and the intention to terminate.<br/>

                        <br/><h4>10. GOVERNING LAW AND DISPUTES</h4>
                        10.1 This EULA shall be governed by and construed in accordance with the substantive laws of Belgium, excluding its choice of law provisions. The CISG shall not be applied to this EULA.<br/>
                        10.2 All disputes arising out of or relating to this EULA shall be primarily settled by amicable negotiations between the Parties. Should the Parties fail to reach an outcome acceptable to both Parties as a result of the negotiations within thirty (30) days from the date when a Party has requested in writing to commence with the aforementioned negotiations, any dispute, controversy or claim arising out of or relating to this EULA, or the breach, termination or validity thereof, shall be settled in Brussels, by Belgian Juridictions, according to Belgian Law. The language to be used in the proceedings shall be French.<br/>

                        <br/><h4>11. OTHER TERMS</h4>
                        11.1 The Service Provider shall be free to use subcontractors in the performance of its obligations and exercise of its rights under this EULA. The Service Provider shall be liable for the acts and omissions of its subcontractors under this EULA as for its own.<br/>
                        11.2 The Service Provider shall have the right to use its relationship with the User in its marketing and sales promotion activities.<br/>
                        11.3 Neither Party shall be liable for any delays or non-performance of its obligations or any damages caused by an impediment beyond its reasonable control, which it could not have reasonably taken into account at the time of entering into this EULA, and whose consequences it could not reasonably have avoided or overcome. For instance, errors in public communication networks or electricity supply shall constitute such an impediment. Strike, lockout, boycott and other industrial action shall constitute a force majeure event also when the Party concerned is the target or Party to such action. A force majeure event suffered by a subcontractor of Party shall also discharge such Party from liability, if the work to be performed under subcontracting cannot be done or acquired from another source without incurring unreasonable costs or significant loss of time. Each Party shall without delay inform the other party in writing of a force majeure event and the termination of the force majeure event.<br/>
                        11.4 Neither Party shall have the right to assign or transfer all or any of its rights, benefits and obligations under this EULA without the prior written consent of the other Party, which consent shall not be unreasonably withheld or delayed. The Service Provider shall, however, have the right to assign this EULA in connection with a sale or transfer of its business or a relevant part thereof.<br/>
                        11.5 Any terms and conditions that by their nature or otherwise reasonably should survive a cancellation or termination of this EULA shall also be deemed to survive.<br/>

                        <br/><h4>12. DEFINITIONS</h4>
                        12.1 “Affiliate” of a Party means any legal entity that is (a) directly or indirectly owning or controlling the Party, or (b) under the same direct or indirect ownership or control as the Party, or (c) directly or indirectly owned or controlled by the Party, for so long as such ownership or control lasts. Ownership or control shall exist through direct or indirect ownership of more than fifty percent (50 %) of the nominal value of the issued equity share capital or more than fifty percent (50 %) of the shares entitling the holders to vote for the election of the members of the board of directors or persons performing similar functions;<br/>
                        12.2 “Confidential Information” shall have the meaning set out in Section 7.1;<br/>
                        12.3 “User” means any company or individual entering into the Service Supply Agreement with the Service Provider. This includes any company or individual using the Service Provider’s services whether free or paid, in development, testing or production;<br/>
                        12.4 ”Fee(s)” means the compensation paid by the User to the Service Provider for the Services;<br/>
                        12.5 “Intellectual Property Rights” means: (i) patents, inventions, designs, copyright (including the right to amend, modify, develop and assign) and related rights, database rights, trade marks and related goodwill, trade names (whether registered or unregistered), and rights to apply for registration; (ii) proprietary rights in domain names; (iii) knowhow and confidential information; (iv) applications, extensions and renewals in relation to any of these rights; and (v) all other rights of a similar nature or having an equivalent effect anywhere in the world;<br/>
                        12.6 “Parties” and “Party” means the Service Provider and the User jointly and separately;<br/>
                        12.7 “Service(s)” means the Terminal Services, the Web Services and the Consulting Services, as well as any other services specified in the Service Supply Agreement;<br/>
                        12.8 “Service Provider” means VoxCollector  - G.E.M.S. SPRL(VAT BE0890002120), a company duly registered under the laws of Belgium, having its principal place of business at Rue des pères blancs 4, 1040 Bruxelles, Belgique;<br/>
                        12.9 “Service Supply Agreement” means the first agreement entered into by The Company and the User, to which the General Terms are attached to;<br/>
                        12.10 “Consulting Services” shall mean the Consulting Services provided by the Service Provider or its third party partner to the User, as separately agreed between the Parties;<br/>
                        12.11 “Terminal Service(s)” means the opinion gathering service based on hardware terminals provided by the Service Provider to the User, as set out in the Service Supply Agreement; and<br/>
                        12.12 “Web Service(s)” means the opinion gathering services based on web site or mobile scripts provided by the Service Provider to the User, as set out in the Service Supply Agreement.<br/>
                        12.13 “The Company” means VoxCollector  - G.E.M.S. SPRL(VAT BE0890002120), a company duly registered under the laws of Belgium, having its principal place of business at Rue des pères blancs 4, 1040 Bruxelles, Belgique;<br/>
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="success" onClick={this.onClickAccept}>
                        <Trans>trans_33_accept</Trans>
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }


    onClickAccept(){
        serverService.acceptTos()
            .then(
                (response) => {
                    if(response.status == 1){
                        this.props.onTosAccepted();
                    }
                },
                (error) => {
                    console.log(error);
                    toast.warn(i18n.t("trans_33_msg_something_went_wrong"));
                }
            )
    }

}

export default ModalTos;