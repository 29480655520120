import React from "react";
import RadialBarToBar from "../_other/RadialBarToBar";

import { serverService } from "../../_services/server.service";

import "./ResultsStandard.css";
import { toast } from "react-toastify";
import {Trans} from "react-i18next";

const moment = require("moment");

interface MyProps {
  selectedTabName: string;
}

interface MyState {
  questionWeights: any;
  skills: any;
  benchmarkQuestions: any;
  companyQuestions: any;
  skillsCompany: any;
  skillsBenchmark: any;
  companyChartJson: any;
  benchmarkChartJson: any;
  orderedSkillsArray: any;
  loading: boolean;
}

class ResultsStandard extends React.Component<MyProps, MyState> {
  constructor(props) {
    super(props);

    this.state = {
      questionWeights: {},
      skills: {},
      companyQuestions: [],
      benchmarkQuestions: [],
      skillsCompany: {},
      skillsBenchmark: {},
      companyChartJson: [],
      benchmarkChartJson: [],
      orderedSkillsArray: [],
      loading: false,
    };
  }

  componentDidMount() {
    this.doFetchAllSkills().then((result1) => {
      this.doFetchResults().then((result2) => {});
    });
  }

  render() {
    const {
      skillsCompany,
      skillsBenchmark,
      skills,
      orderedSkillsArray,
    } = this.state;

    // console.log(orderedSkillsArray);

    let assetsItems = orderedSkillsArray.map((oneSkill, index) => {
      if (index >= 3) {
        return;
      }
      return (
        <tr key={oneSkill.IdSkill}>
          <td>{oneSkill.Name}</td>
          <td>{this.roundAt2Decimals(oneSkill.CompanyYes)}</td>
          <td>{this.roundAt2Decimals(oneSkill.BenchmarkYes)}</td>
          <td>{this.roundAt2Decimals(oneSkill.YesDeviation)}</td>
        </tr>
      );
    });


    let challengesItems = orderedSkillsArray.map((oneSkill, index) => {
      if (index < orderedSkillsArray.length - 3) {
        return;
      }
      return (
        <tr key={oneSkill.IdSkill}>
          <td>{oneSkill.Name}</td>
          <td>{this.roundAt2Decimals(oneSkill.CompanyYes)}</td>
          <td>{this.roundAt2Decimals(oneSkill.BenchmarkYes)}</td>
          <td>{this.roundAt2Decimals(oneSkill.YesDeviation)}</td>
        </tr>
      );
    });

    // console.log("companyChartJson:");
    // console.log( this.state.companyChartJson);

    // console.log("benchmarkChartJson:");
    // console.log(this.state.benchmarkChartJson);

    return (
          <div>
            {this.state.companyChartJson.length == 0 &&
              <div>
                <br/><br/><br/>
                <h2 style={{textAlign:"center"}}>
                  <Trans>trans_33_no_data_to_analyse</Trans>
                </h2>
              </div>
            }
            {this.state.companyChartJson.length > 0 &&
              <div>
                <div className="charts-pie-containet">
                  {/*<RadialBarToBar width="500" height="500"/>*/}
                  <div className="divCharts">
                    {
                      this.state.companyChartJson.length > 0 &&
                        this.state.benchmarkChartJson.length > 0 && (
                          <RadialBarToBar
                            barData={this.state.companyChartJson}
                            radialbarData={this.state.benchmarkChartJson}
                            width="800"
                            height="800"
                          ></RadialBarToBar>

                          //
                        )
                      // barData={this.state.companyChartJson} radialbarData={this.state.benchmarkChartJson}
                    }
                  </div>
                  <br />
                  <br />

                  <div className="container">
                    <div className="row">
                      <div className="col-md-1"></div>
                      <div className="col-md-5">
                        <p><Trans>trans_33_results_standard_top_3_assets</Trans></p>
                        <table className="table-sm">
                          <thead>
                            <tr>
                              <th><Trans>trans_33_Skill</Trans></th>
                              <th><Trans>trans_33_your_results</Trans></th>
                              <th><Trans>trans_33_Benchmark</Trans></th>
                              <th><Trans>trans_33_yes_deviation</Trans></th>
                            </tr>
                          </thead>

                          <tbody>{assetsItems}</tbody>
                        </table>
                      </div>
                      {/* <div className="col-md2">&nbsp;</div> */}
                      <div className="col-md-5">
                        <p><Trans>trans_33_results_standard_top_3_challenges</Trans></p>
                        <table className="table-sm">
                          <thead>
                            <tr>
                              <th><Trans>trans_33_Skill</Trans></th>
                              <th><Trans>trans_33_your_results</Trans></th>
                              <th><Trans>trans_33_Benchmark</Trans></th>
                              <th><Trans>trans_33_yes_deviation</Trans></th>
                            </tr>
                          </thead>

                          <tbody>{challengesItems}</tbody>
                        </table>
                      </div>
                      <div className="col-md-1"></div>
                    </div>
                  </div>

                  <br />
                  <br />
                </div>
              </div>
            }
          </div>

    );
  }

  roundAt2Decimals(number) {
    return Math.round((number + Number.EPSILON) * 100) / 100;
  }

  doFetchAllSkills() {
    return serverService.getSkillsByType(0).then((resultsRes) => {
      this.setState({ skills: resultsRes.data });
    });
  }

  doFetchResults() {
    let userStr = localStorage.getItem("user");
    let IdCompany = 0;
    if (userStr) {
      let userObj = JSON.parse(userStr);
      IdCompany = userObj.IdCompany;
    }

    this.setState({ loading: true });
    let allSkills = this.state.skills;
    return serverService.getResultsWithSkills(IdCompany).then( 
      (resultsRes) => {
        if (resultsRes && resultsRes.data !== undefined) {

          console.log(resultsRes.data);

          this.setState({
            questionWeights: resultsRes.data.questionWeights,
            companyQuestions: resultsRes.data.companyQuestions,
            benchmarkQuestions: resultsRes.data.benchmarkQuestions,

            // skillsCompany: skillsCompany,
            // skillsBenchmark: skillsBenchmark,
            orderedSkillsArray: resultsRes.data.orderedSkillsArray,

            companyChartJson: resultsRes.data.companyChartJson,
            benchmarkChartJson: resultsRes.data.benchmarkChartJson,
            
            loading: false,
          });

          //console.log(skillsCompany);
          //console.log(skillsBenchmark);

          //console.log(companyChartJson);
          //console.log(benchmarkChartJson);
        } else {
          this.setState({ loading: false });
        }
      },
      (error) => {
        toast.warn("Did not get results");
        this.setState({ loading: false });
      }
    );
  }

  computeSkillsPercentages(
    questions,
    questionWeights,
    allDatesQuestionAsked,
    allSkills
  ) {
    let tempQuestion;
    let YesPercentage;
    let IdQuestion;
    let QuestionDate;
    let skillsList = {};
    for (let i = 0; i < allSkills.length; i++) {
      let IdSkill = allSkills[i].IdSkill;
      let SkillsGroup = allSkills[i].SkillsGroup;
      let Name = allSkills[i].Name;

      if (skillsList[IdSkill] === undefined) {
        skillsList[IdSkill] = {};
        skillsList[IdSkill].Name = Name;
      }
      if (skillsList[IdSkill]["dates"] === undefined) {
        skillsList[IdSkill]["dates"] = {};
      }
      skillsList[IdSkill].Name = Name;
      skillsList[IdSkill].SkillsGroup = SkillsGroup;
    }
    for (let i = 0; i < questions.length; i++) {
      tempQuestion = questions[i];
      YesPercentage = tempQuestion.YesPercentage;
      IdQuestion = tempQuestion.IdQuestion;
      QuestionDate = moment(tempQuestion.Date).format("DD.MM.YYYY");

      let skillsMap = questionWeights[IdQuestion];
      for (const IdSkill in skillsMap) {
        let Weight = skillsMap[IdSkill].Weight;
        let Name = skillsMap[IdSkill].Name;

        if (skillsList[IdSkill] === undefined) {
          skillsList[IdSkill] = {};
          skillsList[IdSkill].Name = Name;
        }
        if (skillsList[IdSkill]["dates"] === undefined) {
          skillsList[IdSkill]["dates"] = {};
        }
        if (skillsList[IdSkill]["dates"][QuestionDate] === undefined) {
          skillsList[IdSkill]["dates"][QuestionDate] = {};
        }

        let sumYesPercentage = skillsList[IdSkill].SumYesPercentage === undefined ? 0 : skillsList[IdSkill].SumYesPercentage;
        let sumWeights = skillsList[IdSkill].SumWeights === undefined ? 0 : skillsList[IdSkill].SumWeights;

        let sumYesPercentageForDate =
          skillsList[IdSkill]["dates"][QuestionDate].SumYesPercentage === undefined ? 0 : skillsList[IdSkill]["dates"][QuestionDate].SumYesPercentage;
        let sumWeightsForDate =
          skillsList[IdSkill]["dates"][QuestionDate].SumWeights === undefined ? 0 : skillsList[IdSkill]["dates"][QuestionDate].SumWeights;
        if (Weight < 0) {
          let tempSum = Math.abs(Weight) * (1 - YesPercentage / 100);
          sumYesPercentage += tempSum;
          sumYesPercentageForDate +=  tempSum;
        } else {
          let tempSum = Math.abs(Weight) * (YesPercentage / 100);
          sumYesPercentage += tempSum;
          sumYesPercentageForDate += tempSum;
        }
        let tempWeight = Math.abs(Weight);
        sumWeights += tempWeight;
        sumWeightsForDate += tempWeight;

        skillsList[IdSkill].SumYesPercentage = sumYesPercentage;
        skillsList[IdSkill].SumWeights = sumWeights;

        skillsList[IdSkill]["dates"][
          QuestionDate
        ].SumYesPercentage = sumYesPercentageForDate;
        skillsList[IdSkill]["dates"][
          QuestionDate
        ].SumWeights = sumWeightsForDate;
      }
    }

    for (const IdSkill in skillsList) {
      skillsList[IdSkill].AllDatesYesValue = this.roundAt2Decimals(
        skillsList[IdSkill].SumYesPercentage / skillsList[IdSkill].SumWeights
      );
      skillsList[IdSkill]["processeddates"] = {};
      Object.assign(
        skillsList[IdSkill]["processeddates"],
        allDatesQuestionAsked
      );
      for (const QuestionDate in skillsList[IdSkill]["dates"]) {
        skillsList[IdSkill]["processeddates"][QuestionDate] = {
          'SumYesPercentage': skillsList[IdSkill]["dates"][QuestionDate].SumYesPercentage,
          'SumWeights': skillsList[IdSkill]["dates"][QuestionDate].SumWeights,
        };
      }
    }

    // console.log("Skills list:");
    // console.log(skillsList);

    return skillsList;
  }
}


export default ResultsStandard;
