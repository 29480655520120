import React from 'react';
import {Button,Modal,Form,Row,Col} from 'react-bootstrap'
import { toast } from "react-toastify";
import {serverService} from "../../_services/server.service";
import {Trans} from "react-i18next";
import i18n from "i18next";

interface MyProps {
    show:boolean,
    onHide(): any,
    onTosAccepted():any,
}


class ModalTosAI extends React.Component<MyProps> {

    constructor(props) {
        super(props);
        this.state = {
        };

        this.onClickAccept = this.onClickAccept.bind(this);
    }

    render() {
        return (
            <Modal
                show={this.props.show}
                onHide={this.props.onHide}
                size="lg"
                dialogClassName="modal-90w"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton translate="yes">
                    <Modal.Title id="example-custom-modal-styling-title">
                        VOXCOLLECTOR - Terms and Conditions AI
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                        <b>Effective starting 1-1-2023</b><br/>
                        <br/>
                        By accepting these terms, you agree that we transfer the results of this pulse, and any pulses you want to summarize in the future, to an external AI service (through an API) that will summarize the content on your behalf.
                        On March 1st 2023, Our provided claims that: <br/>
                        - data sent through their API are not used to train his model
                        - data sent through their API are not stored more than 30 days on their systems
                        Here is the full usage policy: https://openai.com/policies/api-data-usage-policies that needs to be checked regularly.
                        Any liability arising from this transfer of data is your sole responsibility. Moreover, we are not responsible for the output (the summary) that is provided and the use thereof.
                       
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="success" onClick={this.onClickAccept}>
                        <Trans>trans_33_accept</Trans>
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }


    onClickAccept(){
        serverService.acceptTosAI()
            .then(
                (response) => {
                    if(response.status == 1){
                        this.props.onTosAccepted();
                    }
                },
                (error) => {
                    console.log(error);
                    toast.warn(i18n.t("trans_33_msg_something_went_wrong"));
                }
            )
    }

}

export default ModalTosAI;