/**
 * Created by Alex on 22-Mar-19.
 */
import React from 'react';
import { Button } from 'react-bootstrap';
import { toast } from 'react-toastify';

import { serverService } from '../../_services/server.service';
import i18n from 'i18next';
import { Trans } from 'react-i18next';
import InputQuestionsTable from './InputQuestionsTable';
import { RequestQuestionsModal } from './RequestQuestionsModal';
import ModalCopyLink from '../MyVoxPage/ModalCopyLink';
import { config } from '../../_helpers/C';
import { AnswersViewModal } from './AnswersViewModal';

interface MyProps {
  onHide(): any;
  show: boolean;
  size?: any;
}

interface MyState {
  submitted: boolean;
  loading: boolean;
  error: string;
  success: string;
  formFields: any;
  selectedIdVox: number;
  inputQuestions: any;
  voxes: any;
  openRequest: boolean;
  linkOpen: string;
  answers: any
}

class RequestQuestionsPage extends React.Component<MyProps, MyState> {
  constructor(props) {
    super(props);

    this.state = {
      submitted: false,
      loading: false,
      error: '',
      success: '',
      openRequest: false,
      linkOpen: '',

      formFields: {},
      selectedIdVox: 0,
      inputQuestions: [],
      voxes: [],
      answers: null
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onVoxSelected = this.onVoxSelected.bind(this);
    this.handleCanUsersModerate = this.handleCanUsersModerate.bind(this);
    this.onHide = this.onHide.bind(this);
    this.onEntered = this.onEntered.bind(this);

  }

  componentDidMount() {
    let formFields = this.state.formFields;
    formFields.QuestionText = i18n.t(
      'trans_33_popup_input_questions_question_text'
    );
    this.setState({ formFields: formFields });

    this.getInputQuestions();

    serverService.getAllLinkedMsTeamsBotOrSlackVoxes().then(
      result => {
        if (result.status === '1') {
          this.setState({
            voxes: result.data,
          });
        } else {
          toast.warn('Error while fetching voxes');
        }
      },
      error => {
        toast.warn('Did not get voxes');
      }
    );
  }

  getInputQuestions() {
    serverService.getInputQuestions().then(
      questionsRes => {
        this.setState({ inputQuestions: questionsRes.data });
      },
      error => {
        toast.warn('Did not get questions');
      }
    );
  }

  handleChange(e) {
    const { name, value } = e.target;
    let formFields = this.state.formFields;
    formFields[name] = value;
    this.setState({ formFields: formFields });
  }

  handleSubmit(e) {
    e.preventDefault();

    this.setState({ submitted: true, loading: true });

    let formFields = this.state.formFields;

    // eslint-disable-next-line eqeqeq
    if (formFields.QuestionText.length == 0) {
      toast.warn(i18n.t('trans_33_missing_fields'));
      return;
    }

    let voxIds = [];
    // eslint-disable-next-line eqeqeq
    if (this.state.selectedIdVox == 0) {
      for (let i = 0; i < this.state.voxes.length; i++) {
        voxIds.push(this.state.voxes[i].IdVox);
      }
    } else {
      voxIds.push(this.state.selectedIdVox);
    }

    let inputQuestion = {
      QuestionText: formFields.QuestionText,
      IdVox: this.state.selectedIdVox,
      CanUsersModerate: formFields.CanUsersModerate
    };
    serverService.insertInputQuestion(inputQuestion, voxIds).then(
      result => {
        if (result.status === '1') {
          toast.success(i18n.t('trans_33_question_sent'));
          this.getInputQuestions();
        } else {
          toast.error('Did not send');
        }
        this.setState({ submitted: false, loading: false });
      },
      error => {
        console.log(error);
        toast.error('Did not send');
        this.setState({ submitted: false, loading: false });
      }
    );
  }

  onHide() {
    this.setState({ openRequest: false });
  }

  onEntered() {

  }

  render() {
    let { formFields, loading } = this.state;

    return (
        <div className="container mt-5 p-5" id="resultsContainer">

          <div className='px-3 py-4 mb-4 d-flex flex-nowrap justify-content-between rounded align-items-center bg-primary-light'>
                <p className='w-100'>
                  <Trans>trans_33_popup_input_questions_question_text_hint</Trans>
                </p>
                <Button
                  className='p-2 btn-primary-updated no-shrink'
                  variant="primary"
                  size="lg"
                  onClick={() => {
                    this.setState({ openRequest: true });
                  }}
                >
                  <Trans>trans_33_request_questions</Trans>
                </Button>
          </div>

          <RequestQuestionsModal
            onHide={this.onHide}
            handleSubmit={this.handleSubmit}
            onEntered={this.onEntered}
            handleChange={this.handleChange}
            handleCanUsersModerate={this.handleCanUsersModerate}
            onVoxSelected={this.onVoxSelected}
            show={this.state.openRequest}
            size="lg"
            submitted={this.state.submitted}
            loading={loading}
            error={this.state.error}
            success={this.state.success}
            formFields={formFields}
            selectedIdVox={this.state.selectedIdVox}
            inputQuestions={this.state.inputQuestions}
            voxes={this.state.voxes}
          />

          <InputQuestionsTable
            inputQuestions={this.state.inputQuestions}
            onShowCopyLink={(type, idinputquestion, idvox, token) => {
              const linkOpen = config.FRONTEND_DOMAIN_NAME + "/onescan"
              + "?IdScan=" + idinputquestion
              + "&h=" + token;

              this.setState({ linkOpen })
            }}
            onShowAnswers={(type, idinputquestion, idvox, token, numofanswers) => {
              this.setState({ answers: this.state.inputQuestions.find(({ IdInputQuestion }) => IdInputQuestion === +idinputquestion) })
            }}
          />
          
          <ModalCopyLink
              show={Boolean(this.state.linkOpen)}
              selectedLink={this.state.linkOpen}
              title="Copy link"
              onHide={() => { this.setState({ linkOpen: '' }) }}
          />

          <AnswersViewModal
            answers={this.state.answers}
            show={Boolean(this.state.answers)}
            onHide={() => { this.setState({ answers: '' }) }}
          />
        <br />
      </div>
    );
  }

  onVoxSelected(IdVox) {
    this.setState({ selectedIdVox: IdVox });
  }


  handleCanUsersModerate(event) {
    let formFields = this.state.formFields;
    formFields.CanUsersModerate = event.target.checked?1:0;
    this.setState({ formFields: formFields });
  }


}

export { RequestQuestionsPage };
