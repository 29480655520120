import React from "react";

import { serverService } from "../../_services/server.service";
import { toast } from "react-toastify";

import VoxesTable from "./VoxesTable";
import SelectDaysAutocomplete from "./SelectDaysAutocomplete";
import ModalOnboardingBeforeAddVox from "../_onboarding/ModalOnboardingBeforeAddVox";
import ModalOnboardingAfterAddVox from "../_onboarding/ModalOnboardingAfterAddVox";

import {Button} from "react-bootstrap";

import "./MyVoxPage.css";
import ModalCompanyDetails from "../_modals/ModalCompanyDetails";
import LanguageSelect from "../_other/LanguageSelect";
import {Trans} from "react-i18next";
import i18n from "i18next";
import ModalOnboardingSelectTimezoneAndLanguage from "../_onboarding/ModalOnboardingSelectTimezoneAndLanguage";

interface MyProps {}

interface MyState {
  voxes: any;
  modalOnboardingBeforeAddVoxShow: boolean;
  modalOnboardingAfterAddVoxShow: boolean;
  modalAddNewShow: boolean;
  modalChooseCompanyDetailsShow: boolean;
  modalOnboardingSelectTimezoneAndLanguageShow;
}

class MyVoxPage extends React.Component<MyProps, MyState> {
  private userObj;

  constructor(props) {
    super(props);

    this.state = {
      voxes: [],
      modalOnboardingBeforeAddVoxShow: false,
      modalOnboardingAfterAddVoxShow: false,
      modalAddNewShow: false,
      modalChooseCompanyDetailsShow: false,
      modalOnboardingSelectTimezoneAndLanguageShow: false,
    };

    this.onVoxAdded = this.onVoxAdded.bind(this);
    this.onVoxDeleted = this.onVoxDeleted.bind(this);
    this.onVoxResetted = this.onVoxResetted.bind(this);
    this.onVoxSuccessfullyAssignedToChannel = this.onVoxSuccessfullyAssignedToChannel.bind(this);


    this.modalChooseCompanyDetailsHide = this.modalChooseCompanyDetailsHide.bind(this);
    this.onCompanyDetailsUpdated = this.onCompanyDetailsUpdated.bind(this);
    this.onLanguageSelected = this.onLanguageSelected.bind(this);
    this.showCompanyDetailsModalfNeeded = this.showCompanyDetailsModalfNeeded.bind(this);
    this.showOnboardingAfterAddVoxModal = this.showOnboardingAfterAddVoxModal.bind(this);
    this.continueOnboarding = this.continueOnboarding.bind(this);
    this.modalOnboardingSelectTimezoneAndLanguage = this.modalOnboardingSelectTimezoneAndLanguage.bind(this);

    this.onTimezoneAndLanguageUpdated = this.onTimezoneAndLanguageUpdated.bind(this);


  }

  componentDidMount() {
    this.setState({
      voxes: [],
    });

    const userStr = localStorage.getItem("user");
    if (userStr) {
      this.userObj = JSON.parse(userStr);
    }

    this.doFetchAllVoxes(true);

  }

  render() {
    let Email = "";
    if (this.userObj && this.userObj.Username) {
      Email = this.userObj.Username;
    }

    let IdCompany = 0;
    if (this.userObj && this.userObj.IdCompany) {
      IdCompany = this.userObj.IdCompany;
    }

    let IdLanguage = 1;
    if (this.userObj && this.userObj.IdLanguage) {
      IdLanguage = this.userObj.IdLanguage;
    }
    
    console.log(this.userObj);

    let modalOnboardingBeforeAddVoxClose = () =>
      this.setState({ modalOnboardingBeforeAddVoxShow: false });
    let modalOnboardingBeforeAddVoxClick = () =>
      this.setState({
        modalAddNewShow: true,
        modalOnboardingBeforeAddVoxShow: false,
      });

    let modalOnboardingAfterAddVoxClose = () => {
      this.setState({ modalOnboardingAfterAddVoxShow: false });
    };
    let modalOnboardingAfterAddVoxClick = () => {
      this.setState({ modalOnboardingAfterAddVoxShow: false });
    };


    return (
      <div id="myVoxContainer" className="container">
        <section className="vox-content-container">
          <VoxesTable
            voxes={this.state.voxes}
            onVoxAdded={this.onVoxAdded}
            onVoxDeleted={this.onVoxDeleted}
            onVoxResetted={this.onVoxResetted}
            onVoxSuccessfullyAssignedToChannel={this.onVoxSuccessfullyAssignedToChannel}
            modalAddNewShow={this.state.modalAddNewShow}
          />
          <br />

          <br />
          <p><Trans>trans_33_myvox_page_choose_language</Trans></p>
          <LanguageSelect
            selectedIdLanguage={IdLanguage}
            onLanguageSelected={this.onLanguageSelected}
          />
          <br />
          <br />
          <br />

          <p><Trans>trans_33_myvox_page_choose_days</Trans></p>
          <SelectDaysAutocomplete />

          <br />

          <ModalOnboardingBeforeAddVox
            show={this.state.modalOnboardingBeforeAddVoxShow}
            onHide={modalOnboardingBeforeAddVoxClose}
            onConfirmAddNewVox={modalOnboardingBeforeAddVoxClick}
          />

          <ModalOnboardingAfterAddVox 
            show={this.state.modalOnboardingAfterAddVoxShow}
            onHide={modalOnboardingAfterAddVoxClose}
            onConfirmAddNewVox={modalOnboardingAfterAddVoxClick}
          />

          <ModalCompanyDetails
            show={this.state.modalChooseCompanyDetailsShow}
            company={{ IdCompany: IdCompany, Email: Email }}
            title={i18n.t("trans_33_myvox_page_company_details_title")}
            onHide={this.modalChooseCompanyDetailsHide}
            onCompanyDetailsUpdated={this.onCompanyDetailsUpdated}
            backdrop="static"
            closeButton={false}
          />


          <ModalOnboardingSelectTimezoneAndLanguage
              show={this.state.modalOnboardingSelectTimezoneAndLanguageShow}
              onHide={this.modalOnboardingSelectTimezoneAndLanguage}
              onTimezoneAndLanguageUpdated={this.onTimezoneAndLanguageUpdated}
          />


          <br />
          <br />
        </section>
      </div>
    );
  }

  modalChooseCompanyDetailsHide() {
    this.setState({ modalChooseCompanyDetailsShow: false });
  }


  modalOnboardingSelectTimezoneAndLanguage() {
    this.setState({ modalOnboardingSelectTimezoneAndLanguageShow: false });
  }


  onTimezoneAndLanguageUpdated(Timezone, IdLanguage) {

    toast.success("Timezone and language updated");

    const userStr = localStorage.getItem("user");
    if (userStr) {
      this.userObj = JSON.parse(userStr);
    }
    this.userObj.IdLanguage = IdLanguage;
    this.userObj.Timezone = Timezone;
    localStorage.setItem("user", JSON.stringify(this.userObj));

    this.setState({
      modalOnboardingSelectTimezoneAndLanguageShow: false,
      modalOnboardingAfterAddVoxShow:true
    });
  }

  onCompanyDetailsUpdated() {
    toast.success(i18n.t("trans_33_myvox_page_company_details_msg_update_success"));

    this.userObj.CompanyDetailsSaved = 1;
    localStorage.setItem("user", JSON.stringify(this.userObj));

    this.setState({
      modalChooseCompanyDetailsShow: false,
      modalOnboardingSelectTimezoneAndLanguageShow: true,
    });
  }

  onLanguageSelected(IdLanguage) {
    let company = { IdCompany: this.userObj.IdCompany, IdLanguage: IdLanguage };
    serverService.updateCompany(company).then(
      (res) => {

        if(res.status === "1"){
          this.userObj.IdLanguage = parseInt(IdLanguage);
          localStorage.setItem("user", JSON.stringify(this.userObj));

          toast.success(i18n.t("trans_33_myvox_page_msg_language_changed_success"));
        } else {
          toast.warn(res.message);
        }

      },
      (error) => {
        toast.warn("Did not update the language");
      }
    );
  }

  doFetchAllVoxes(firstLoadOfThePage) {
    serverService.getAllVoxesWithNumberOfAnswers().then(
      (voxesRes) => {
        let showInactiveVoxes = localStorage.getItem("showInactiveVoxes")=='true';
        let voxes = [];

        if(voxesRes.data.length > 0 && firstLoadOfThePage){
          this.showCompanyDetailsModalfNeeded()
        }
        if(showInactiveVoxes){
          voxes = voxesRes.data;
        } else {
          for(let i=0;i<voxesRes.data.length;i++){
            if(voxesRes.data[i]['Status'] > 0){
              voxes.push(voxesRes.data[i]);
            }
          }
        }
        this.setState({ voxes: voxes });
        if (voxesRes.data.length === 0) {
          this.setState({ modalOnboardingBeforeAddVoxShow: true });
        }
      },
      (error) => {
        toast.warn("Did not get voxes");
      }
    );
  }


  continueOnboarding(){
    let CompanyDetailsSaved = 1;

    if (this.userObj && this.userObj.CompanyDetailsSaved !== undefined) {
      CompanyDetailsSaved = this.userObj.CompanyDetailsSaved;
    }

    if (CompanyDetailsSaved === 0) {
      this.showCompanyDetailsModalfNeeded()
    } else {
      this.showOnboardingAfterAddVoxModal()
    }
  }

  showOnboardingAfterAddVoxModal(){
    if(this.state.voxes.length <= 0){
      this.setState({ modalOnboardingAfterAddVoxShow: true });
    }
  }

  showCompanyDetailsModalfNeeded(){
      let CompanyDetailsSaved = 1;

      if (this.userObj && this.userObj.CompanyDetailsSaved !== undefined) {
        CompanyDetailsSaved = this.userObj.CompanyDetailsSaved;
      }

      if (CompanyDetailsSaved === 0) {
        this.setState({ modalChooseCompanyDetailsShow: true });
      }
  }



  onVoxAdded(vox) { 
    if(vox['Type'] == 1 || vox['Type'] == 2){
      this.continueOnboarding();
    }

    this.doFetchAllVoxes(false);
  }

  onVoxDeleted() {
    this.doFetchAllVoxes(false);
  }

  onVoxResetted() {
    this.doFetchAllVoxes(false);
  }


  onVoxSuccessfullyAssignedToChannel() {
    if(this.userObj['FinishedOnboarding'] == 0){
      this.continueOnboarding();
    }
    this.doFetchAllVoxes(false);
  }
}

export { MyVoxPage };
