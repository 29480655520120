/**
 * Created by Alex on 22-Mar-19.
 */
import React from 'react';

import { Button, Modal, Form, Row, Col,OverlayTrigger, Popover } from 'react-bootstrap';
import { toast } from 'react-toastify';

import { serverService } from '../../_services/server.service';
import { Trans } from 'react-i18next';
import i18n from 'i18next';
import ItemsSelect from '../_other/ItemsSelect';
import LanguageSelect from '../_other/LanguageSelect';

import  {
  formatDate,
  parseDate,
} from 'react-day-picker/build/addons/MomentLocaleUtils';
import DayPickerInput from 'react-day-picker/DayPickerInput';

interface MyProps {
  show: boolean;
  onHide(): any;
  onCompleteClicked(formFields: any): any;
}

interface MyState {
  submitted: boolean;
  loading: boolean;
  error: string;
  formFields: any;

  scanDeadline: string;
  numberOfRespondants: number;
}

class ModalNewScanLastStep extends React.Component<MyProps, MyState> {

  constructor(props) {
    super(props);

    let userStr = localStorage.getItem('user');
    let userObj = null;
    if(userStr) {
      userObj = JSON.parse(userStr);
    }

    this.state = {
      submitted: false,
      loading: false,
      error: '',

      formFields: {'IdLanguage': userObj['IdLanguage']},

      scanDeadline: '',
      numberOfRespondants: 0,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);

    this.handleSubmit = this.handleSubmit.bind(this);



    this.onModalEntered = this.onModalEntered.bind(this);
    this.onLanguageSelected = this.onLanguageSelected.bind(this);


  }

  handleChange(e) {
    const { name, value } = e.target;
    let formFields = this.state.formFields;
    formFields[name] = value;
    this.setState({ formFields: formFields });
  }



  handleDateChange(from) {
    let formFields = this.state.formFields;
    formFields["ScanDeadline"] = from;
    this.setState({ formFields: formFields });
}



  handleSubmit(e) {
    e.preventDefault();

    this.setState({ submitted: true, loading: true });

    const { formFields } = this.state;
    const Name = formFields.Name;

  
   this.props.onCompleteClicked(formFields);

  }

  render() {
    let { formFields,  loading } = this.state;

    return (
      <Modal
        show={this.props.show}
        onHide={this.props.onHide}
        onEntered={this.onModalEntered}
        dialogClassName='modal-90w'
        aria-labelledby='contained-modal-title-vcenter'
        centered
        backdrop="static"
      >

        <Modal.Header className="add-vox-modal-header" translate="yes">
            <Modal.Title id="example-custom-modal-styling-title">
              Last step
            </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Form onSubmit={this.handleSubmit}>

            <Form.Group controlId='formLanguage'>
              <Form.Label>
                Which language do you prefer for your questions?
              </Form.Label>

              <LanguageSelect
                  selectedIdLanguage={formFields['IdLanguage']}
                  onLanguageSelected={this.onLanguageSelected}
              />
            </Form.Group>


            <Form.Group controlId='formScanDeadline'>
              <Form.Label>
                Set a deadline for anwsering the team scan
              </Form.Label>

              <div className="ScanDeadline">
                  <DayPickerInput
                      value={formFields['ScanDeadline']}
                      placeholder={"MM/DD/YY"}
                      formatDate={formatDate}
                      parseDate={parseDate}
                      format="MM/DD/YY"
                      onDayChange={this.handleDateChange}
                      dayPickerProps={{
                          disabledDays: {before: new Date(),},
                          firstDayOfWeek:1,
                      }}
                  />
              </div>

     
            </Form.Group>

           
            <Form.Group controlId='formNumberOfRespondants'>
              <Form.Label>
                How many respondants do you expect?
              </Form.Label>


              <Form.Control
                type='number'
                placeholder={i18n.t('trans_33_number_of_respondants')}
                name='NumberOfRespondants'
                value={formFields['NumberOfRespondants']}
                onChange={this.handleChange}
              />
     
            </Form.Group>


            <div className='p-2' style={{textAlign:'right'}}>
              <Button variant='primary' className='px-4 py-2' size='lg' type='submit' disabled={loading}>
                <Trans>trans_33_complete</Trans>
              </Button>
              {loading && (
                <img
                  alt='loading'
                  src='data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA=='
                />
              )}
            </div>
          </Form>
        </Modal.Body>
        <br />
      </Modal>
    );
  }



  onLanguageSelected(IdLanguage) {
    let formFields = this.state.formFields;
    formFields['IdLanguage'] = IdLanguage;
    this.setState({ formFields: formFields });
  }



  
  onModalEntered() {
    let formFields = this.state.formFields;

    this.setState({ formFields: formFields });
  }
  

}


export default ModalNewScanLastStep;
