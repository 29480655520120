import React from 'react';
import { toast } from "react-toastify";

import ModalConfirmAction from '../_other/ModalConfirmAction';

import {Button,ButtonToolbar} from 'react-bootstrap'
import moment from "moment";

import { serverService } from '../../_services/server.service';
import i18n from "i18next";


const $ = require('jquery');
$.DataTable = require('datatables.net');

require( 'datatables.net-bs4' );
require( 'datatables.net-select-bs4' );
require('../datatables.selected.css');



interface MyProps {
    licenseLogs:any,
}

interface MyState {
}

class LicenseLogsTable extends React.Component<MyProps,MyState> {

    constructor(props) {
        super(props);

        this.state = {
        };

        this.reloadTableData = this.reloadTableData.bind(this);
    }

    componentDidMount() {
        let table = $(this.refs.main_logs_table).DataTable({
            //dom: '<"data-table-wrapper"<"toolbar"><"bottom"f>rtip>',
            dom: "<'data-table-logs-wrapper'" +
                    "<'row'<'col-md-6 my-auto'<'toolbar'>><'col-md-6'>>" +
                    "<'row'<'col-sm-12'tr>>" +
                    "<'row'<'col-sm-12 col-md-5'><'col-sm-12 col-md-7'>>" +
                    ">",
            data: this.props.licenseLogs,
            columns : [
                {
                    title: i18n.t("trans_33_settings_billing_logs_date"),
                    width: 120,
                    data: 'Added'
                },
                {
                    title: i18n.t("trans_33_settings_billing_logs_event"),
                    width: 120,
                    data: 'LogText'
                },
            ],
            ordering: true,
            paging:true,
            order: [[ 0, "desc" ]],
            language: {
                search: i18n.t("trans_33_search"),
                emptyTable:  i18n.t("trans_33_table_no_records"),
                paginate: {
                    first: i18n.t("trans_33_first"),
                    previous: i18n.t("trans_33_previous"),
                    next: i18n.t("trans_33_next"),
                    last: i18n.t("trans_33_last")
                }
            },
            columnDefs: [
                {
                    render: function ( data, type, row ) {
                        if(type === 'sort'){
                            return data;
                        }

                        if(data != null && data != "0000-00-00 00:00:00"){
                            return moment(data).format('D MMM YYYY HH:mm:ss');
                        } else {
                            return "";
                        }
                    },
                    targets: 0,
                },
            ]

        });
    }

    componentWillUnmount(){
        $('.data-table-logs-wrapper')
            .find('table')
            .DataTable()
            .destroy(true);
    }

    componentDidUpdate(oldProps) {
        if(this.props.licenseLogs !== oldProps.licenseLogs){
            this.reloadTableData(this.props.licenseLogs);
        }
    }


    render() {
        return (
            <div>
                <table ref="main_logs_table" className="table table-bordered" />
            </div>
        );
    }



    reloadTableData(items) {
        const table = $('.data-table-logs-wrapper')
            .find('table')
            .DataTable();
        table.clear();
        table.rows.add(items);
        table.draw();
    }

}


export default LicenseLogsTable;
