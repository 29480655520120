import React from 'react';
import { toast } from "react-toastify";

import {Button} from 'react-bootstrap';

import { serverService } from '../../_services/server.service';

interface MyProps {
    onSkillsChanged:any,
}

interface MyState {
    skills:any
}

class SkillsMultiSelect extends React.Component<MyProps,MyState> {

    constructor(props) {
        super(props);

        this.state = {
            skills:[],
        };
    }

    componentDidMount(){
        this.fetchSkills();
    }


    render () {
        let skills = this.state.skills;

        let skillsList = "";
        if(skills && skills.length > 0){
            skillsList = skills.map((skill) => {
                skill.DisplayClass = "secondary";
                if(skill.IsSelected){
                    skill.DisplayClass = "primary";
                }
                return <Button variant={skill.DisplayClass}
                               className="btn-topics"
                               value={skill.IdSkill}
                               key={skill.IdSkill}
                               style={{marginLeft:10,marginTop:5}}
                               onClick={buttonEvent => this.onButtonClick(buttonEvent)}
                        >
                            {skill.Name}
                       </Button>
            });
        }

        return (
            <div>
                {skillsList}
            </div>
        )
    }

    onButtonClick(buttonEvent){
        let tempSkills = this.state.skills;
        tempSkills.forEach((oneSkill) => {
            if(oneSkill.IdSkill == buttonEvent.target.value){
                oneSkill.IsSelected = !oneSkill.IsSelected;
            }
        });
        this.setState({skills:tempSkills});
        this.props.onSkillsChanged(tempSkills);
    }

    fetchSkills() {

        serverService.getSkillsByType(0)
            .then(
                result => {
                    if(result.status === "1"){
                        this.setState({
                            skills:result.data,
                        });
                    } else {
                        toast.warn("Error while fetching skills");
                    }
                },
                error => {
                    toast.warn("Did not get skills");
                }
            );
    }
}

export default SkillsMultiSelect;
