import React, { Component } from "react";
import { Chart } from "chart.js";
import "./ResultsSelfStarterChart.css";

class ResultsSelfStarterChart extends Component {
  chart1 = {};
  chart2 = {};
  dataDay = [];
  dataWeek = [];
  dataMonth = [];
  dataLabelWeek = [];
  dataLabelDay = [];
  dataLabelMonth = [];
  displayedCurrently;
  groupPerDay;
  groupPerWeek;
  groupPerMonth;
  dataKeys = [];
  graph_1_colors = [
    "#1FAAEC",
    "#00D9D7",
    "#F9F871",
    "#A64E81",
    "#394955",
    "#F5B041",
  ];
  chart1Ref = React.createRef();
  chart2Ref = React.createRef();

  classy_color_array = ["#1FAAEC", "#394955", "#9CADBC", "#DF82B6", "#A64E81"];
  constructor() {
    super();

    this.state = {
      key: Date.now(),
    };

    this.sumAfterGrouping = this.sumAfterGrouping.bind(this);
    this.onDayClick = this.onDayClick.bind(this);
    this.onWeekClick = this.onWeekClick.bind(this);
    this.onMonthClick = this.onMonthClick.bind(this);
  }

  buildChart1 = () => {
    const chart1Ref = this.chart1Ref.current.getContext("2d");

    var options = {
      //legend: false,
      tooltips: {
        mode: "index",
        intersect: false,
      },
      scales: {
        xAxes: [
          {
            ticks: {
              autoSkip: true,
              maxTicksLimit: 10,
              maxRotation: 45,
              minRotation: 45,
              fontColor: "#20abec",
            },
            gridLines: {
              color: "rgba(0, 0, 0, 0)",
              zeroLineColor: "#20abec",
            },
            scaleLabel: {
              display: true,
              labelString: "Date",
              fontColor: "#20abec",
              fontSize: 16,
              fontFamily: "Rubik, sans-serif",
              margin: 30,
            },
          },
        ],
        yAxes: [
          {
            ticks: {
              autoSkip: true,
              maxTicksLimit: 10,
              fontColor: "#20abec",
              beginAtZero: true,
              stepSize:1
            },
            gridLines: {
              color: "rgba(0, 0, 0, 0)",
              zeroLineColor: "#20abec",
            },
            scaleLabel: {
              display: true,
              labelString: "Votes",
              fontColor: "#20abec",
              fontSize: 16,
            },
          },
        ],
      },
    };

    this.chart1 = new Chart(chart1Ref, {
      type: "bar",
      data: this.processChart1Data(),
      options: options,
    });
  };

  buildChart2 = () => {
    const chart2Ref = this.chart2Ref.current.getContext("2d");

    var options = {
      legend: false,
      tooltips: {
        mode: "index",
        intersect: false,
      },
      scales: {
        xAxes: [
          {
            ticks: {
              autoSkip: false,
              maxRotation: 45,
              minRotation: 45,
              fontColor: "#20abec",
            },
            gridLines: {
              color: "rgba(0, 0, 0, 0)",
              zeroLineColor: "#20abec",
            },
            scaleLabel: {
              display: true,
              labelString: "Hours",
              fontColor: "#20abec",
              fontSize: 16,
              fontFamily: "Rubik, sans-serif",
              padding: 10,
            },
          },
        ],
        yAxes: [
          {
            ticks: {
              fontColor: "#20abec",
            },
            gridLines: {
              color: "rgba(0, 0, 0, 0)",
              zeroLineColor: "#20abec",
            },
            scaleLabel: {
              display: true,
              labelString: "Votes",
              fontColor: "#20abec",
              fontSize: 16,
            },
          },
        ],
      },
    };

    this.chart2 = new Chart(chart2Ref, {
      type: "bar",
      data: this.processChart2Data(),
      options: options,
    });
  };

  componentDidMount() {
    this.groupPerDay = this.getChart1Data();

    this.displayedCurrently = "dataDay";
    this.dataDay = this.dataGraph(this.groupPerDay);
    this.dataKeys = Object.keys(this.groupPerDay[0]);
    this.dataLabelDay = this.groupPerDay.map((item) => item[this.dataKeys[0]]);

    this.buildChart1();
    //this.buildChart2();

    this.onDayClick();
  }

  componentDidUpdate() {

    this.buildChart1();
    //this.buildChart2();
  }

  processChart1Data() {

      // let test = [{day: "Jan 2021", VoxTeams: "",VoxBefi: 2, BefiBotEnglish: ""}
      //     ,{day: "Jan 2021", VoxTeams: "", VoxBefi: 0, BefiBotEnglish: 1}
      //     , {day: "Jan 2021", VoxTeams: "", VoxBefi: 1, BefiBotEnglish: ""}];
      //
      // this.sumAfterGrouping(test);


    var formatEachObj = this.groupPerDay.map((x) => ({
      ...x,
      day: x.day.split("-").reverse().join("-"),
    }));

    /* GROUP PER MONTH */
    // RENAME THE DAY KEY WITH THIS FORMAT "JAN 2020"
    var formatEachObjMonth = formatEachObj.map((x) => ({
      ...x,
      day:
        new Date(x.day).toLocaleString("default", {
          month: "short",
        }) +
        " " +
        new Date(x.day).getFullYear(),
    }));

    this.groupPerMonth = this.sumAfterGrouping(formatEachObjMonth);
    /* END OF GROUP PER MONTH */

    /* GROUP PER WEEK */
    /* GROUP BY WEEK FUNCTION */
    Date.prototype.getWeek = function () {
      var onejan = new Date(this.getFullYear(), 0, 1);
      return Math.ceil(((this - onejan) / 86400000 + onejan.getDay() + 1) / 7);
    };
    var formatEachObjWeek = formatEachObj.map((x) => ({
      ...x,
      day: new Date(x.day).getWeek() + "th wk " + new Date(x.day).getFullYear(),
    }));
    this.groupPerWeek = this.sumAfterGrouping(formatEachObjWeek);
    /* END OF GROUP PER WEEK */

    if (this.displayedCurrently === "dataDay") {
      this.dataLabel = this.dataLabelDay;
      this.datasets = this.dataDay;
    } else if (this.displayedCurrently === "dataWeek") {
      this.dataLabel = this.dataLabelWeek;
      this.datasets = this.dataWeek;
    } else if (this.displayedCurrently === "dataMonth") {
      this.dataLabel = this.dataLabelMonth;
      this.datasets = this.dataMonth;
    }

    var data = {
      labels: this.dataLabel, // THE FIRST ROW IS ALWAY THE LABEL
      datasets: this.datasets,
    };

    return data;
  }

  getChart1Data() {
    return (this.chart1Data = this.props.chart1Data || [
      {
        day: "",
        VoxA: "",
        VoxB: 6,
        VoxC: 10,
        VoxD: 5,
      },
      {
        day: "20-07-2020",
        VoxA: "",
        VoxB: 14,
        VoxC: 14,
        VoxD: 7,
      },
      {
        day: "06-08-2020",
        VoxA: "",
        VoxB: 13,
        VoxC: 13,
        VoxD: 9,
      },
      {
        day: "06-09-2020",
        VoxA: "",
        VoxB: 12,
        VoxC: 13,
        VoxD: 8,
      },
      {
        day: "06-10-2020",
        VoxA: "",
        VoxB: 5,
        VoxC: 10,
        VoxD: 11,
      },
      {
        day: "06-11-2020",
        VoxA: 5,
        VoxB: 6,
        VoxC: 10,
        VoxD: 5,
      },
      {
        day: "06-12-2020",
        VoxA: 15,
        VoxB: 13,
        VoxC: 8,
        VoxD: 11,
      },
      {
        day: "06-06-2020",
        VoxA: "",
        VoxB: 6,
        VoxC: 10,
        VoxD: 5,
      },
      {
        day: "06-07-2020",
        VoxA: "",
        VoxB: 14,
        VoxC: 14,
        VoxD: 7,
      },
      {
        day: "06-08-2020",
        VoxA: "",
        VoxB: 13,
        VoxC: 13,
        VoxD: 9,
      },
      {
        day: "06-09-2020",
        VoxA: "",
        VoxB: 12,
        VoxC: 13,
        VoxD: 8,
      },
      {
        day: "06-10-2020",
        VoxA: "",
        VoxB: 5,
        VoxC: 10,
        VoxD: 11,
      },
      {
        day: "06-11-2020",
        VoxA: 5,
        VoxB: 6,
        VoxC: 10,
        VoxD: 5,
      },
      {
        day: "06-12-2020",
        VoxA: 15,
        VoxB: 13,
        VoxC: 8,
        VoxD: 11,
      },
    ]);
  }

  processChart2Data() {
    let groupPerHour = this.getChart2Data();

    // TO GET THE OBJECT KEYS
    let dataKeysHour = Object.keys(groupPerHour[0]);

    // THE FIRST KEY IS FOR THE LABEL
    let dataLabelHour = groupPerHour.map((item) => item[dataKeysHour[0]]);

    // THE OTHER ARE FOR VALUES
    let dataOthersHour = [];
    for (let i = 1; i < dataKeysHour.length; i++) {
      let dataKeyPushHour = dataKeysHour[i];
      let dataPushHour = groupPerHour.map((item) => item[dataKeyPushHour]);
      dataOthersHour.push({
        data: dataPushHour,
        label: dataKeyPushHour,
        borderColor: this.classy_color_array[i - 1],
        backgroundColor: this.classy_color_array[i - 1],
      });
    }
    let data = {
      labels: dataLabelHour, // THE FIRST ROW IS ALWAYS THE LABEL
      datasets: dataOthersHour,
    };

    return data;
  }

  getChart2Data() {
    return (this.chart2Data = this.props.chart2Data || [
      {
        day: "0:00",
        VoxA: "",
        VoxB: 6,
        VoxC: 10,
        VoxD: 5,
      },
      {
        day: "1:00",
        VoxA: "",
        VoxB: 14,
        VoxC: 14,
        VoxD: 7,
      },
      {
        day: "2:00",
        VoxA: "",
        VoxB: 13,
        VoxC: 13,
        VoxD: 9,
      },
      {
        day: "3:00",
        VoxA: "",
        VoxB: 12,
        VoxC: 13,
        VoxD: 8,
      },
      {
        day: "4:00",
        VoxA: "",
        VoxB: 5,
        VoxC: 10,
        VoxD: 11,
      },
      {
        day: "5:00",
        VoxA: 5,
        VoxB: 6,
        VoxC: 10,
        VoxD: 5,
      },
      {
        day: "6:00",
        VoxA: 15,
        VoxB: 13,
        VoxC: 8,
        VoxD: 11,
      },
      {
        day: "7:00",
        VoxA: "",
        VoxB: 6,
        VoxC: 10,
        VoxD: 5,
      },
      {
        day: "8:00",
        VoxA: "",
        VoxB: 14,
        VoxC: 14,
        VoxD: 7,
      },
      {
        day: "9:00",
        VoxA: "",
        VoxB: 13,
        VoxC: 13,
        VoxD: 9,
      },
      {
        day: "10:00",
        VoxA: "",
        VoxB: 12,
        VoxC: 13,
        VoxD: 8,
      },
      {
        day: "11:00",
        VoxA: "",
        VoxB: 5,
        VoxC: 10,
        VoxD: 11,
      },
      {
        day: "12:00",
        VoxA: 5,
        VoxB: 6,
        VoxC: 10,
        VoxD: 5,
      },
      {
        day: "13:00",
        VoxA: 15,
        VoxB: 13,
        VoxC: 8,
        VoxD: 11,
      },
      {
        day: "14:00",
        VoxA: "",
        VoxB: 5,
        VoxC: 10,
        VoxD: 11,
      },
      {
        day: "15:00",
        VoxA: 5,
        VoxB: 6,
        VoxC: 10,
        VoxD: 5,
      },
      {
        day: "16:00",
        VoxA: 15,
        VoxB: 13,
        VoxC: 8,
        VoxD: 11,
      },
      {
        day: "17:00",
        VoxA: "",
        VoxB: 5,
        VoxC: 10,
        VoxD: 11,
      },
      {
        day: "18:00",
        VoxA: 5,
        VoxB: 6,
        VoxC: 10,
        VoxD: 5,
      },
      {
        day: "19:00",
        VoxA: 15,
        VoxB: 13,
        VoxC: 8,
        VoxD: 11,
      },
      {
        day: "20:00",
        VoxA: "",
        VoxB: 5,
        VoxC: 10,
        VoxD: 11,
      },
      {
        day: "21:00",
        VoxA: 5,
        VoxB: 6,
        VoxC: 10,
        VoxD: 5,
      },
      {
        day: "22:00",
        VoxA: 15,
        VoxB: 13,
        VoxC: 8,
        VoxD: 11,
      },
      {
        day: "23:00",
        VoxA: "",
        VoxB: 5,
        VoxC: 10,
        VoxD: 11,
      },
      {
        day: "24:00",
        VoxA: 5,
        VoxB: 6,
        VoxC: 10,
        VoxD: 5,
      },
    ]);
  }

  /* SUMMING AFTER GROUPING */
  // sumAfterGrouping(theGrouping) {
  //   var sumPerMonthWeek = {};
  //   let ctx = this;
  //   console.log(ctx.dataKeys);
  //   var sumPerMonthWeekResult = theGrouping.reduce(function (accumulator, object) {
  //     var key = object.day;
  //
  //       if (!sumPerMonthWeek[key]) {
  //       sumPerMonthWeek[key] = Object.assign({}, object);
  //       for (let i = 1; i < ctx.dataKeys.length; i++) {
  //         sumPerMonthWeek[key][ctx.dataKeys[i]] = Number(
  //           sumPerMonthWeek[key][ctx.dataKeys[i]]
  //         );
  //       }
  //         accumulator.push(sumPerMonthWeek[key]);
  //     } else {
  //       for (let i = 1; i < ctx.dataKeys.length; i++) {
  //           sumPerMonthWeek[key][ctx.dataKeys[i]] += Number(
  //               sumPerMonthWeek[key][ctx.dataKeys[i]]
  //           );
  //       }
  //     }
  //     return accumulator;
  //   }, []);
  //   return sumPerMonthWeekResult;
  // }


    sumAfterGrouping(theGrouping) {
        let ctx = this;
        var sumPerMonthWeekResult = theGrouping.reduce(function (groups, item) {
            let group = groups.find(_group => _group.day === item.day);
            if (group) {
                ctx.dataKeys.slice(1).forEach((key) => group[key] += +(item[key] || 0));
            } else {
                group = {...item};
                ctx.dataKeys.slice(1).forEach((key) => group[key] = +(group[key] || 0));
                groups.push(group);
            }
            return groups;
        }, []);
        return sumPerMonthWeekResult;
    }



  /* PREPARE DATA FOR GRAPH */

  dataGraph(params) {
    var dataAll = [];
    for (let i = 1; i < this.dataKeys.length; i++) {
      var dataKeyPush = this.dataKeys[i];
      var dataPush = params.map((item) => item[dataKeyPush]);
      dataAll.push({
        data: dataPush,
        label: dataKeyPush,
        borderColor: this.classy_color_array[i - 1],
        backgroundColor: this.classy_color_array[i - 1],
      });
    }

      return dataAll;
  }

  render() {
    return (
      <div className="content">

          {this.props.displayButtons &&
            <div className="graph-btns">
              <button
                  className="graph-buttons btn btn-primary m-1"
                  id="dataDay"
                  onClick={this.onDayClick}
              >
                Day
              </button>
              <button
                  className="graph-buttons btn btn-primary m-1"
                  id="dataWeek"
                  onClick={this.onWeekClick}
              >
                Week
              </button>
              <button
                  className="graph-buttons btn btn-primary m-1"
                  id="dataMonth"
                  onClick={this.onMonthClick}
              >
                Month
              </button>
            </div>
          }


        <section className="graph-container">
          <div className="wrapper">
            <canvas id="chart-1" ref={this.chart1Ref} />
          </div>
          <div style={{ display: "none" }} className="wrapper">
            <canvas id="chart-2" ref={this.chart2Ref} />
          </div>
        </section>
      </div>
    );
  }

  onDayClick() {
    if (this.dataDay.length === 0) {
      this.dataDay = this.dataGraph(this.groupPerDay);
      this.datasets = this.dataDay;
      this.dataLabelDay = this.groupPerDay.map(
        (item) => item[this.dataKeys[0]]
      );
      this.dataLabel = this.dataLabelDay;
    } else {
      this.dataLabel = this.dataLabelDay;
      this.datasets = this.dataDay;
    }
    this.displayedCurrently = this.id;

    this.chart1.datasets = this.datasets;
    this.chart1.labels = this.dataLabel;
    this.chart1.destroy();

    this.setState({ randomKey: new Date() });
  }

  onWeekClick() {
    if (this.dataWeek.length === 0) {
      this.dataWeek = this.dataGraph(this.groupPerWeek);
      this.datasets = this.dataWeek;
      this.dataLabel = this.groupPerWeek.map((item) => item[this.dataKeys[0]]);
      this.dataLabelWeek = this.groupPerWeek.map(
        (item) => item[this.dataKeys[0]]
      );
      this.dataLabel = this.dataLabelWeek;
    } else {
      this.dataLabel = this.dataLabelWeek;
      this.datasets = this.dataWeek;
    }
    this.displayedCurrently = this.id;

    this.chart1.datasets = this.datasets;
    this.chart1.labels = this.dataLabel;
    this.chart1.destroy();

    this.setState({ randomKey: new Date() });
  }

  onMonthClick() {
    if (this.dataMonth.length === 0) {
      this.dataMonth = this.dataGraph(this.groupPerMonth);
      this.datasets = this.dataMonth;
      this.dataLabel = this.groupPerMonth.map((item) => item[this.dataKeys[0]]);
      this.dataLabelMonth = this.groupPerMonth.map(
        (item) => item[this.dataKeys[0]]
      );
      this.dataLabel = this.dataLabelMonth;
    } else {
      this.dataLabel = this.dataLabelMonth;
      this.datasets = this.dataMonth;
    }
    this.displayedCurrently = this.id;

    this.chart1.datasets = this.datasets;
    this.chart1.labels = this.dataLabel;
    this.chart1.destroy();

    this.setState({ randomKey: new Date() });
  }
}

export default ResultsSelfStarterChart;
