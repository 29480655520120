import React, {useState} from "react";
import ScanTable from "./ScanTable";
import {Trans} from "react-i18next";
import QPacksGrid from "../CampaignPage/Standard/QPacksGrid";
import ModalQuestionsPack from "../CampaignPage/Standard/ModalQuestionsPack";
import ModalAddNewPulseStep3 from "../PulsePage/ModalAddNewPulseStep3";
import ModalAddNewScanChooseDateToSend from "./ModalAddNewScanChooseDateToSend";
import {toast} from "react-toastify";
import { serverService } from '../../_services/server.service';
import moment from "moment";
import ModalNewScanVerifyInfo1 from "./ModalNewScanVerifyInfo1";
import ModalNewScanLastStep from "./ModalNewScanLastStep";
import ModalScanReady from "./ModalScanReady";

interface MyProps {

}

interface MyState {
    selectedQPack: any,
    selectedScan: any,

    modalChooseQuestionsShow: boolean,
    modalChooseDateShow: boolean,
    modalQuestionsFromSection: string,

    selectedQuestionIds:any,

    refreshTable:number,
    scans: any,
    lastScanDate: any,

    modalNewScanVerifyInfo1Show: boolean,
    modalNewScanLastStepShow: boolean,
    modalScanReadyShow: boolean,

    verifyInfoPopupFormFields: any,
    lastStepPopupFormFields: any,

    lastRegistredScanLink: string,
    lastScanEmail: string,
    lastScanToken: string,

}



class ScanPage extends React.Component<MyProps, MyState> {

  private userObj = null;

  constructor(props) {
    super(props);

    this.state = {
        selectedQPack: {},
        selectedScan: {},
        modalQuestionsFromSection: "",
        selectedQuestionIds: [],
        modalChooseQuestionsShow: false,
        modalChooseDateShow: false,
        refreshTable: 0,
        scans: [],
        lastScanDate: null,

        modalNewScanVerifyInfo1Show: false,
        modalNewScanLastStepShow: false,
        modalScanReadyShow: false,

        verifyInfoPopupFormFields: {},
        lastStepPopupFormFields: {},

        lastRegistredScanLink: "",
        lastScanEmail: "",
        lastScanToken: "",
    };

    this.onScanDeleted = this.onScanDeleted.bind(this);
    this.onClickPack = this.onClickPack.bind(this);
    this.onQuestionPackScheduled = this.onQuestionPackScheduled.bind(this);
    this.onScanCreated = this.onScanCreated.bind(this);
    this.onEditScan = this.onEditScan.bind(this);

    this.doFetchAllScans = this.doFetchAllScans.bind(this);


    this.setModalNewScanVerifyInfo1Show = this.setModalNewScanVerifyInfo1Show.bind(this);
    this.setModalNewScanLastStepShow = this.setModalNewScanLastStepShow.bind(this);

    this.insertScan = this.insertScan.bind(this);

    let userStr = localStorage.getItem('user');
    if(userStr) {
        this.userObj = JSON.parse(userStr);
    }
   

  }


  setModalNewScanVerifyInfo1Show = (value) => {
    this.setState({modalNewScanVerifyInfo1Show:value});
  }

  setModalNewScanLastStepShow = (value) => {
    this.setState({modalNewScanLastStepShow:value});
  }


  componentDidMount() {

  
  }

  componentWillUnmount() {}

  render() {
      let closeQuestionsPackModal = () => this.setState({ modalChooseQuestionsShow: false });
      let closeChooseDateModal = () => this.setState({ modalChooseDateShow: false });
      

      const refreshTable = this.state.refreshTable;

        const oneYearAgo = new Date();
        oneYearAgo.setFullYear(oneYearAgo.getFullYear() - 1);
        const isLastScanMoreThanAYearOld = new Date(this.state.lastScanDate) < oneYearAgo;


      const disableAddScan = !isLastScanMoreThanAYearOld && (this.userObj && (!this.userObj.LicenseType || this.userObj.LicenseType == 0)) ? true : false;


      let formattedLastScanDatePlusOneYear = "";
      if(disableAddScan){
        formattedLastScanDatePlusOneYear=  moment(this.state.lastScanDate).add(1, 'years').format('DD.MM.YYYY');
      }
    


    return (
      <div className="container mt-5 p-5" id="resultsContainer">
          <h4>
              <Trans>Create a new scan from a pack</Trans>
          </h4>

    
          <div style={{ position: 'relative', padding: "2rem" }}>
          {disableAddScan && (
              <div style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                  backgroundColor: 'rgba(0, 0, 0, 0.8)',
                  justifyContent: 'center',
                  alignItems: 'center',
                  display: 'flex',
                  color: '#FFF',
                  zIndex: 2, 
              }}>
                  <h3>Adding scans is disabled until {formattedLastScanDatePlusOneYear}</h3> 
              </div>
          )}
          <QPacksGrid
              horizontal={true}
              rows={1}
              packType={1}
              onClickPack={this.onClickPack}
          />
      </div>
          

        <h3><Trans>Scans</Trans></h3>
        <br/>
        <ScanTable scans={this.state.scans} refresh={refreshTable} onScanDeleted={this.onScanDeleted} onEditScan={this.onEditScan} doFetchAllScans={this.doFetchAllScans}/>

            {/*fromSection*/}
            {/*  campaign - campaign generated questions*/}
            {/*  add_scan - questions from selectedQPack*/}
            {/*  edit_scan - questions from selectedIdScan*/}
          <ModalQuestionsPack
              fromSection = {this.state.modalQuestionsFromSection}
              selectedQPack= {this.state.selectedQPack}
              selectedScan = {this.state.selectedScan}
              show={this.state.modalChooseQuestionsShow}
              onHide={closeQuestionsPackModal}
              onQuestionPackScheduled={this.onQuestionPackScheduled}
              size='lg'
          />

          <ModalAddNewScanChooseDateToSend
              show={this.state.modalChooseDateShow}
              selectedScan={this.state.selectedScan}
              selectedQPack={this.state.selectedQPack}
              selectedQuestionIds={this.state.selectedQuestionIds}
              onHide={closeChooseDateModal}
              onScanCreated = {this.onScanCreated}
          />


        <ModalNewScanVerifyInfo1
            show={this.state.modalNewScanVerifyInfo1Show}
            company={{  }}
            onHide={() => this.setModalNewScanVerifyInfo1Show(false)}
            onVerifyInfo1NextClicked={(formFields) => { 
                this.setState({ modalNewScanVerifyInfo1Show:false, modalNewScanLastStepShow: true, verifyInfoPopupFormFields: formFields});
                console.log(formFields);
            }}
        />


        <ModalNewScanLastStep
            show={this.state.modalNewScanLastStepShow}
            onHide={() => this.setModalNewScanLastStepShow(false)}
            onCompleteClicked={(formFields) => { 
                this.setState({lastStepPopupFormFields: formFields}, () => {
                    this.insertScan();
                });
            }}
        />

        <ModalScanReady
            show={this.state.modalScanReadyShow}
            onHide={() => this.setState({ modalScanReadyShow: false})}
            selectedLink={this.state.lastRegistredScanLink}
            selectedEmail={this.state.lastScanEmail}
            scanToken={this.state.lastScanToken}
            title="Team Scan Ready"
        />


      </div>
    );
  }


  insertScan() {
    /*

        let regJson = {
            "companyName": document.querySelector("#welcomeFormNameInput").value,
            "email": document.querySelector("#welcomeFormEmailInput").value,
            "numOfEmployees": document.querySelector("#welcomeFormPeopleNumberInput").value,
            "sector": document.querySelector("#welcomeFormIndustryInput").value,
            "pack": document.querySelector(".packageElInput:checked").value,
            "language": document.querySelector("#packageLanguage").value,
            "endDate": document.querySelector("#packageDate").value,
            "voucherCode": document.querySelector("#welcomeFormVoucherInput").value,
            "environment": document.querySelector("#welcomeFormEnvironmentInput").value,
            "country": document.querySelector("#welcomeFormCountryInput").value,
            "token":voxToken,  
            "expectedNumberOfRespondents": document.querySelector("#packageExpectedRespondents").value,
            "requiresVoucher": requiresVoucher,
            "private":  document.querySelector("#dontSendResults").checked?1:0,
        }   


        verifyInfoPopupFormFields
        {
            "Name": "CompanyTest",
            "IdIndustry": 15,
            "IdNumberOfUsers": "3",
            "IdEnvironment": "4",
            "IdCountry": "DZ"
        }

        lastStepPopupFormFields
        {
            "ScanDeadline": "2024-02-15T11:00:00.000Z",
            "NumberOfRespondants": "4"
        }
 
    */

   
    let verifyInfoFormFields = this.state.verifyInfoPopupFormFields;
    let lastStepPopupFormFields = this.state.lastStepPopupFormFields;

    console.log(verifyInfoFormFields);
    console.log(lastStepPopupFormFields);

    let endDate = moment(lastStepPopupFormFields.ScanDeadline).format('MM/DD/YY');


    serverService.registerNewScan(verifyInfoFormFields.CompanyName, verifyInfoFormFields.IdIndustry, verifyInfoFormFields.IdNumberOfUsers, verifyInfoFormFields.IdEnvironment
                                , verifyInfoFormFields.IdCountry
                                , lastStepPopupFormFields.IdLanguage, endDate, lastStepPopupFormFields.NumberOfRespondants
                                , this.state.selectedQuestionIds
    ).then(
        (result) => { 

            if(result.status == 0){
                toast.warn(result.message);
                this.setState({ modalNewScanLastStepShow:false });
                return;
            }

            if(result.status == 1){
                toast.success("Scan created");
                let refreshTable = this.state.refreshTable;
                this.setState({ modalNewScanLastStepShow:false, lastRegistredScanLink: result.link, lastScanEmail: result.email, lastScanToken: result.scanToken
                    , modalScanReadyShow: true, refreshTable:++refreshTable, });
            }
        }, 
        (error) => {
            toast.warn("Did not create scan");
            this.setState({ modalNewScanLastStepShow:false });
        }
    );

  }


    doFetchAllScans() {
        serverService.getScans().then(
            (result) => {
                if(result.data){
                    let refreshTable = this.state.refreshTable;
                    this.setState({scans: result.data,refreshTable:++refreshTable });

                    if(result.data.length > 0) {
                        let latestDate = new Date(result.data[0].Added);
                        for(let i = 1; i < result.data.length; i++) {
                            if(result.data[i].Added) {
                                const currentDate = new Date(result.data[i].Added);
                                if(currentDate > latestDate) { 
                                    latestDate = currentDate; 
                                }
                            }
                        }
                        this.setState({lastScanDate: latestDate.toISOString()});
                    }
                   
                }
                
            },
            (error) => {
                toast.warn("Did not get scans");
            }
        );
    }

    onScanCreated() {
        let refreshTable = this.state.refreshTable;

        this.setState({ modalChooseQuestionsShow:false, modalChooseDateShow: false, refreshTable:++refreshTable, });
    }

    onScanDeleted() {}

    onEditScan(oneScan:any) {
        this.setState({ modalChooseQuestionsShow:true, selectedScan: oneScan, modalQuestionsFromSection:"edit_scan" });
    }


    onClickPack(selectedQPack) {
        this.setState({ selectedQPack: selectedQPack, modalChooseQuestionsShow: true, modalQuestionsFromSection:"add_scan", selectedScan: {} });
    }

    onQuestionPackScheduled(selectedQuestions) {
        let selectedQuestionIds = [];
        for(let i=0;i<selectedQuestions.length;i++){
            if(selectedQuestions[i]['IdQuestion']){
                selectedQuestionIds.push(selectedQuestions[i]['IdQuestion']);
            }
        }
        this.setState({ modalChooseQuestionsShow:false, modalNewScanVerifyInfo1Show:true, selectedQuestionIds: selectedQuestionIds });
    }


}

export { ScanPage };
