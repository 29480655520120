import React from "react";
import { withRouter } from "react-router-dom";
import { Navbar, Nav } from "react-bootstrap";
import { Link, NavLink } from "react-router-dom";

import "./SideNavbar.css";
import { toast } from "react-toastify";
import i18n from "i18next";
import {Trans} from "react-i18next";

interface MyProps {
  user: any;
}

class SideNavbar extends React.Component<MyProps> {
  constructor(props) {
    super(props);
  }

  render() {
    var labelStyle = {
      color: "#CCCCCC",
    };

    const { user } = this.props;


    let licenseType = 0;
    if (user && user.LicenseType) {
      licenseType = user.LicenseType;
    }

    return (
      <div className="side-bar-parent">
        <div id="sidebar-menu">
          <div className="logo-container">
            <img src="assets/images/logo.png" alt="Avatar woman" />
          </div>
          <Nav className="side-nav-container">
            {user && user.AuthToken && (user.Type == 2 || user.Type == 1) && (
              <Navbar.Text>
                &nbsp;&nbsp;
                <NavLink
                  to="/home"
                  activeClassName="selected"
                  className="normal btn-block"
                >
                  <i className="fas fa-home"></i>
                  <Trans>
                    trans_33_menu_home
                  </Trans>
                </NavLink>
                <NavLink
                    to="/myvox"
                    activeClassName={licenseType == 0 ? "" : "selected"}
                    className={`normal btn-block ${licenseType == 0 ? "disabled-link" : ""}`}
                    onClick={(e) => {
                      if(licenseType == 0){
                        e.preventDefault(); // Prevents navigation
                        toast.warn(i18n.t("trans_33_page_blocked_upgrade_license"));
                      }
                    }}
                >
                  <i className="fas fa-vote-yea"></i>
                  <Trans>
                    trans_33_menu_my_vox
                  </Trans>
                </NavLink>
                <NavLink
                  to="/campaign"
                  activeClassName={licenseType == 0 ? "" : "selected"}
                    className={`normal btn-block ${licenseType == 0 ? "disabled-link" : ""}`}
                    onClick={(e) => {
                      if(licenseType == 0){
                        e.preventDefault(); // Prevents navigation
                        toast.warn(i18n.t("trans_33_page_blocked_upgrade_license"));
                      }
                    }}
                >
                  <i className="far fa-map"></i>
                  <Trans>
                    trans_33_menu_campaign
                  </Trans>
                </NavLink>


                <NavLink
                    to="/scan"
                    activeClassName="selected"
                    className="normal btn-block"
                >
                  <i className="fab fa-creative-commons-sampling"></i>
                  <Trans>
                    trans_33_menu_scan
                  </Trans>
                </NavLink>

                <NavLink
                    to="/pulse"
                    activeClassName={(licenseType == 0 || licenseType == 1)? "" : "selected"}
                    className={`normal btn-block ${(licenseType == 0 || licenseType == 1) ? "disabled-link" : ""}`}
                    onClick={(e) => {
                      if((licenseType == 0 || licenseType == 1)){
                        e.preventDefault(); // Prevents navigation
                        toast.warn(i18n.t("trans_33_page_blocked_upgrade_license"));
                      }
                    }}
                >
                  <i className="fas fa-bolt"></i>
                  <Trans>
                    trans_33_menu_pulse
                  </Trans>
                </NavLink>


                <NavLink
                    to="/request"
                    activeClassName={(licenseType == 0 || licenseType == 1) ? "" : "selected"}
                    className={`normal btn-block ${(licenseType == 0 || licenseType == 1) ? "disabled-link" : ""}`}
                    onClick={(e) => {
                      if((licenseType == 0 || licenseType == 1)){
                        e.preventDefault(); // Prevents navigation
                        toast.warn(i18n.t("trans_33_page_blocked_upgrade_license"));
                      }
                    }}
                >
                  <i className="fas fa-question"></i>
                  <Trans>
                    trans_33_menu_request
                  </Trans>
                </NavLink>

                <NavLink
                  to="/results"
                  activeClassName="selected"
                  className="normal btn-block"
                >
                  <i className="fas fa-poll-h"></i>
                  <Trans>
                    trans_33_menu_results
                  </Trans>
                </NavLink>

                <NavLink
                    to="/advices"
                    activeClassName={licenseType == 0 ? "" : "selected"}
                    className={`normal btn-block ${licenseType == 0 ? "disabled-link" : ""}`}
                    onClick={(e) => {
                      if(licenseType == 0){
                        e.preventDefault(); // Prevents navigation
                        toast.warn(i18n.t("trans_33_page_blocked_upgrade_license"));
                      }
                    }}
                  >
                    <i className="fas fa-comment-medical"></i>
                    <Trans>
                      trans_33_menu_advice
                    </Trans>
                  </NavLink>

                {user && user.AuthToken && (user.IdCompany != 69 && user.IdCompany != 71) &&
                    <NavLink
                        to="/settings"
                        activeClassName="selected"
                        className="normal btn-block"
                    >
                      <i className="fas fa-cogs"></i>
                      <Trans>
                        trans_33_menu_settings
                      </Trans>
                    </NavLink>
                }

                {user.Type == 1 && (
                  <div className="admin-nav-container">
                    <div className="admin-title">
                      <p>
                        <Trans>
                          trans_33_menu_admin_pages
                        </Trans>
                      </p>
                    </div>
                    <NavLink
                      to="/admincompanies"
                      activeClassName="selected"
                      className="normal btn-block"
                    >
                      <i className="fas fa-hotel"></i>
                      <Trans>
                        trans_33_menu_companies
                      </Trans>
                    </NavLink>
                    <NavLink
                      to="/adminusers"
                      activeClassName="selected"
                      className="normal btn-block"
                    >
                      <i className="fas fa-users"></i>
                      <Trans>
                        trans_33_menu_users
                      </Trans>
                    </NavLink>
                    <NavLink
                      to="/adminquestions"
                      activeClassName="selected"
                      className="normal btn-block"
                    >
                      <i className="fas fa-question-circle"></i>
                      <Trans>
                        trans_33_menu_questions
                      </Trans>
                    </NavLink>
                    <NavLink
                      to="/adminanswers"
                      activeClassName="selected"
                      className="normal btn-block"
                    >
                      <i className="fas fa-exclamation"></i>
                      <Trans>
                        trans_33_menu_answers
                      </Trans>
                    </NavLink>
                    <NavLink
                      to="/adminresults"
                      activeClassName="selected"
                      className="normal btn-block"
                    >
                      <i className="fas fa-poll"></i>
                      <Trans>
                        trans_33_menu_results
                      </Trans>
                    </NavLink>
                    <NavLink
                      to="/adminresources"
                      activeClassName="selected"
                      className="normal btn-block"
                    >
                      <i className="fas fa-building"></i>
                      <Trans>
                        trans_33_menu_resources
                      </Trans>
                    </NavLink>
                    <NavLink
                      to="/adminlogs"
                      activeClassName="selected"
                      className="normal btn-block"
                    >
                      <i className="fas fa-blog"></i>
                      <Trans>
                        trans_33_menu_logs
                      </Trans>
                    </NavLink>
                    <NavLink
                        to="/adminlicenses"
                        activeClassName="selected"
                        className="normal btn-block"
                    >
                      <i className="fas fa-certificate"></i>
                      <Trans>
                        trans_33_menu_licenses
                      </Trans>
                    </NavLink>
                  </div>
                )}
              </Navbar.Text>
            )}


            {user && user.AuthToken && (user.Type == 3) && (
                <Navbar.Text>
                  &nbsp;&nbsp;

                  <NavLink
                      to="/pulse"
                      activeClassName="selected"
                      className="normal btn-block"
                  >
                    <i className="fab fa-creative-commons-sampling"></i>
                    <Trans>
                      trans_33_menu_pulse
                    </Trans>
                  </NavLink>
                </Navbar.Text>
            )}
  
            {user && user.AuthToken && (user.Type == 4) && (
                <Navbar.Text>
                  &nbsp;&nbsp; 
 
                  <NavLink
                    to="/scan"
                    activeClassName="selected"
                    className="normal btn-block"
                >
                  <i className="fab fa-creative-commons-sampling"></i>
                  <Trans>
                    trans_33_menu_scan
                  </Trans>
                </NavLink>
                </Navbar.Text>
            )} 
            
            
          </Nav>
        </div>
      </div>
    );
  }
}

export default withRouter(SideNavbar);
