import React from "react";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import "bootstrap/dist/css/bootstrap.css";

import MyNavbar from "../ui/_other/MyNavbar";
import SideNavbar from "../ui/_other/SideNavbar";

import { Route, withRouter, Switch, Redirect } from "react-router-dom";

import { PrivateRoute } from "../_components/PrivateRoute";

import { LoginPage } from "../ui/LoginPage/LoginPage";
import { AccountSuspendedPage } from "../ui/_other/AccountSuspendedPage";

import { HomePage } from "../ui/HomePage/HomePage";
import { CampaignPage } from "../ui/CampaignPage/CampaignPage";
import { AdvicesPage } from "../ui/AdvicesPage/AdvicesPage";
import { ResultsPage } from "../ui/ResultsPage/ResultsPage";
import { MyVoxPage } from "../ui/MyVoxPage/MyVoxPage";
import { SettingsPage } from "../ui/SettingsPage/SettingsPage";
import { AdminQuestionsPage } from "../ui/AdminPages/AdminQuestions/AdminQuestionsPage";
import { AdminUsersPage } from "../ui/AdminPages/AdminUsers/AdminUsersPage";

import { RouteProps } from "react-router-dom";
import "./App.scss";
import { AdminCompaniesPage } from "../ui/AdminPages/AdminCompanies/AdminCompaniesPage";
import { ChangePasswordPage } from "../ui/ChangePasswordPage/ChangePasswordPage";
import { AdminResourcesPage } from "../ui/AdminPages/AdminResources/AdminResourcesPage";
import { AdminLogsPage } from "../ui/AdminPages/AdminLogs/AdminLogsPage";
import { AdminLicensesPage } from "../ui/AdminPages/AdminLicenses/AdminLicensesPage";
import { AdminAnswersPage } from "../ui/AdminPages/AdminAnswers/AdminAnswersPage";
import { AdminResultsPage } from "../ui/AdminPages/AdminResults/AdminResultsPage";
import ResultsSilvesquareHorizontalPage from "../ui/ResultsPage/ResultsSilversquareHorizontalPage";
import ResultsSilvesquareVerticalPage from "../ui/ResultsPage/ResultsSilversquareVerticalPage";
import { RegisterPage } from "../ui/RegisterPage/RegisterPage";
import { RegistrationPage } from "../ui/RegistrationPage/RegistrationPage";
import { PlansPage } from "../ui/PlansPage/PlansPage";
import { PaymentPage } from "../ui/PaymentPage/PaymentPage";
import { Middleware } from "../ui/Middleware/Middleware";
import { InputQuestionsPage } from "../ui/InputQuestionsPage/InputQuestionsPage";
import {PulsePage} from "../ui/PulsePage/PulsePage";
import { OnePulseOrOneScanPage } from "../ui/PulsePage/OnePulseOrOneScanPage"; 
import {RequestQuestionsPage} from "../ui/RequestPage/RequestQuestionsPage";
import {ScanPage} from "../ui/ScanPage/ScanPage";


type MyState = { user: string, allPulseQuestions: any, };

declare const window: any;


class App extends React.Component<RouteProps, MyState> {

  constructor(props) {
    super(props);

    this.state = {
      user: JSON.parse(localStorage.getItem("user")),
      allPulseQuestions:[]

    };
  }
  checkRadioClicked(){
    alert("asdsad");
    console.log(this);
   
}
  componentDidMount(){
    // console.log("PATH:" + this.props.location.pathname);

    if(this.props.location.pathname != "/onescan" && this.props.location.pathname != "/onepulse"){
      window.$crisp = [];
      window.CRISP_WEBSITE_ID = "8e0d5e08-0b6d-4c20-9692-179deeb44dab";
      (function() {
        let d = document;
        let s = d.createElement("script");
  
        s.src = "https://client.crisp.chat/l.js";
        s.async = true;
        d.getElementsByTagName("head")[0].appendChild(s);
      })();
    } 
   
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.setState({ user: JSON.parse(localStorage.getItem("user")) });
    }
    const allPulseQuestions = this.state.allPulseQuestions;
    allPulseQuestions.forEach((oneQuestionObject) => {
    let questionTypeID =  "yes_" + oneQuestionObject.IdPulseQuestion;
    console.log(questionTypeID);
    let questionRadioButtons = document.querySelectorAll('input[name="'+questionTypeID+'"]');
    console.log(questionRadioButtons);
    for (let i = 0; i < questionRadioButtons.length; i++) {
        questionRadioButtons[i].addEventListener("change", this.checkRadioClicked);
      }
    }); 
  }

  render() {
    let { user } = this.state;

    return (
      <div className="container-fluid p-0">
        <ToastContainer autoClose={3000} hideProgressBar={true} />
        {user && this.props.location.pathname != "/register" && this.props.location.pathname != "/account_suspended" && this.props.location.pathname != "/onepulse"
            && this.props.location.pathname != "/onescan" && (
          <>
            <MyNavbar user={this.state.user} />
          </>
        )}
        <div className="row">
          {user && this.props.location.pathname != "/register" && this.props.location.pathname != "/account_suspended" && this.props.location.pathname != "/onepulse"
              && this.props.location.pathname != "/onescan"  && (
            <div className="col-2 col-sm-2 col-md-2">
              <SideNavbar user={this.state.user} />
            </div>
          )} 
          <div
            className={
              (user && this.props.location.pathname != "/register"  && this.props.location.pathname != "/onepulse" 
                  && this.props.location.pathname != "/onescan" && this.props.location.pathname != "/login" && this.props.location.pathname != "/account_suspended" 
                  && this.props.location.pathname != "/input_questions") 
            ? 'col-10 col-sm-10 col-md-10'
            :  (this.props.location.pathname == "/onepulse" || this.props.location.pathname == "/onescan") ? "survey-section" : "col-md-12"
          }
          >
            <Switch>
              <Route path="/login" component={LoginPage} />
              <Route path="/account_suspended" component={AccountSuspendedPage} />
              <Route
                exact
                path="/changepassword"
                component={ChangePasswordPage}
              />


            
              <Route
                  exact
                  path="/input_questions"
                  component={InputQuestionsPage}
              />

              <Route
                  exact
                  path="/onepulse"
                  component={OnePulseOrOneScanPage}
              />

              <Route
                  exact
                  path="/onescan"
                  component={OnePulseOrOneScanPage}
              />


              <Route path="/register" component={RegisterPage} />
              <Route path="/registration" component={RegistrationPage} />
              <Route path="/plans-page" component={PlansPage} />
              <Route path="/payment-page" component={PaymentPage} />
              <Route path="/middleware" component={Middleware} />

              <Route
                exact
                path="/resultssilversquare_h"
                component={ResultsSilvesquareHorizontalPage}
              />
              <Route
                exact
                path="/resultssilversquare_v"
                component={ResultsSilvesquareVerticalPage}
              />


         

              <PrivateRoute exact path="/home" component={HomePage} />
              <PrivateRoute exact path="/home" component={HomePage} /> 
              <PrivateRoute exact path="/campaign" component={CampaignPage} />
              <PrivateRoute exact path="/scan" component={ScanPage} />
              <PrivateRoute exact path="/pulse" component={PulsePage} />
              <PrivateRoute exact path="/advices" component={AdvicesPage} />
              <PrivateRoute exact path="/results" component={ResultsPage} />
              <PrivateRoute exact path="/myvox" component={MyVoxPage} />
              <PrivateRoute
                  exact
                  path="/request"
                  component={RequestQuestionsPage}
              />
              <PrivateRoute exact path="/settings" component={SettingsPage} />
            
        

              <PrivateRoute
                exact 
                path="/admincompanies"
                component={AdminCompaniesPage}
              />
              <PrivateRoute
                exact
                path="/adminusers"
                component={AdminUsersPage}
              />
              <PrivateRoute
                exact
                path="/adminquestions"
                component={AdminQuestionsPage}
              />
              <PrivateRoute
                exact
                path="/adminresources"
                component={AdminResourcesPage}
              />
              <PrivateRoute
                exact
                path="/adminanswers"
                component={AdminAnswersPage}
              />
              <PrivateRoute
                exact
                path="/adminresults"
                component={AdminResultsPage}
              />
              <PrivateRoute exact path="/adminlogs" component={AdminLogsPage} />

              <PrivateRoute exact path="/adminlicenses" component={AdminLicensesPage} />

              <Redirect from="*" to="/home" />
            </Switch>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter((props) => <App {...props} />);
