import React from "react";

import { serverService } from "../../../_services/server.service";

import UsersTable from "./AdminUsersTable";

import "./AdminUsersPage.css";
import { toast } from "react-toastify";

interface MyProps {}

interface MyState {
  users: any;
}

class AdminUsersPage extends React.Component<MyProps, MyState> {
  constructor(props) {
    super(props);

    this.state = {
      users: [],
    };

    this.onUserAdded = this.onUserAdded.bind(this);
    this.onUserEdited = this.onUserEdited.bind(this);
    this.onUserDeleted = this.onUserDeleted.bind(this);
  }

  componentDidMount() {
    this.setState({
      users: [],
    });

    this.doFetchAllUsers();
  }

  render() {
    return (
      <div className="container mt-5 p-5">
        <UsersTable
          users={this.state.users}
          onUserAdded={this.onUserAdded}
          onUserEdited={this.onUserEdited}
          onUserDeleted={this.onUserDeleted}
        />
      </div>
    );
  }

  doFetchAllUsers() {
    serverService.getAllAdminUsersForTableDisplay().then(
      (result) => {
        this.setState({ users: result.data });
      },
      (error) => {
        toast.warn("Did not get users");
      }
    );
  }

  onUserAdded() {
    this.doFetchAllUsers();
  }

  onUserEdited() {
    this.doFetchAllUsers();
  }

  onUserDeleted() {
    this.doFetchAllUsers();
  }
}

export { AdminUsersPage };
