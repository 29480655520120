import React from "react";
import { Button, Modal, Form, Row, Col } from "react-bootstrap";
import { toast } from "react-toastify";

import { serverService } from "../../_services/server.service";

import PubSub from "../../_helpers/PubSub.js";
import LicenseSelect from "../_other/LicenseSelect";
import {Trans} from "react-i18next";
import i18n from "i18next";

const moment = require("moment");

interface MyProps {
  show: boolean;
  onHide(): any;
  company: any;
  license: any;
  onLicenseUpdated(isItADowngrade:boolean): any;
  voxes: any;
}

interface MyState {
  submitted: boolean;
  loading: boolean;
  error: string;

  newLicense: any;
  currentLicense: any;
}

class ModalChooseLicense extends React.Component<MyProps, MyState> {
  constructor(props) {
    super(props);

    this.state = {
      submitted: false,
      loading: false,
      error: "",

      newLicense: {},
      currentLicense:{},
    };

    this.onSubmitButtonClick = this.onSubmitButtonClick.bind(this);
    this.onLicenseSelected = this.onLicenseSelected.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.license !== prevState.newLicense) {
      this.setState({ newLicense: this.props.license, currentLicense: {...this.props.license}  });
    }
  }

  render() {
    let { loading } = this.state;

    return (
      <Modal
        show={this.props.show}
        onHide={this.props.onHide}
        dialogClassName="modal-90w"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton translate="yes">
          <Modal.Title id="example-custom-modal-styling-title">
            <Trans>trans_33_choose_license</Trans>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex justify-content-center">
            <LicenseSelect
              theme="dark"
              licenseType={
                this.state.newLicense ? this.state.newLicense.Type : ""
              }
              virtualVoxNum={
                this.state.newLicense ? this.state.newLicense.VirtualVoxNum : ""
              }
              physicalVoxNum={
                this.state.newLicense
                  ? this.state.newLicense.PhysicalVoxNum
                  : ""
              }
              onLicenseSelected={this.onLicenseSelected}
            />
          </div>

          <br />

          <Button variant="primary" onClick={this.onSubmitButtonClick}>
            <Trans>trans_33_Upgrade</Trans>
          </Button>
          {loading && (
            <img
              alt="loading"
              src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA=="
            />
          )}
        </Modal.Body>
      </Modal>
    );
  }

  onLicenseSelected(licenseType, virtualVoxNum, physicalVoxNum) {
    let newLicense = this.state.newLicense;
    newLicense["Type"] = licenseType;
    newLicense["VirtualVoxNum"] = virtualVoxNum;
    newLicense["PhysicalVoxNum"] = physicalVoxNum;

    this.setState({ newLicense: newLicense });
  }

  onSubmitButtonClick() {
    this.setState({ submitted: true, loading: true });

    let currentLicense = this.state.currentLicense;
    let newLicense = this.state.newLicense;

    let {voxes} = this.props;

    let currentVirtualVoxNum = 0;
    let currentPhysicalVoxNum = 0;
    for(let i=0;i<voxes.length;i++){
      if(voxes[i]['Type'] == 2 ||  voxes[i]['Type'] == 4||  voxes[i]['Type'] == 5){
        currentVirtualVoxNum++;
      } else if(voxes[i]['Type'] == 1 ||  voxes[i]['Type'] == 3){
        currentPhysicalVoxNum++;
      }
    }

    if(newLicense['VirtualVoxNum'] < currentVirtualVoxNum){
      toast.error(i18n.t("trans_33_choose_license_msg_delete_virtual",{"currentVirtualVoxNum": currentVirtualVoxNum}));
      this.setState({ submitted: false, loading: false });
      return;
    } else if(newLicense['PhysicalVoxNum'] < currentPhysicalVoxNum){
      toast.error(i18n.t("trans_33_choose_license_msg_delete_physical",{"currentPhysicalVoxNum": currentPhysicalVoxNum}));
      this.setState({ submitted: false, loading: false });
      return;
    }


    let isItADowngrade = newLicense['Type'] < currentLicense['Type']
                  || newLicense['VirtualVoxNum'] < currentLicense['VirtualVoxNum']
                  || newLicense['PhysicalVoxNum'] < currentLicense['PhysicalVoxNum'];


    let promiseToExecute;
    if(isItADowngrade){
      let licensePendingUpdate = {'IdLicense': newLicense['IdLicense'], 'Type': newLicense['Type']
        , 'VirtualVoxNum': newLicense['VirtualVoxNum'], 'PhysicalVoxNum': newLicense['PhysicalVoxNum']
        ,'Status': '2'};
      promiseToExecute = serverService.insertLicensesPendingUpdate(licensePendingUpdate);
    } else {
      promiseToExecute = serverService.updateLicense(newLicense);
    }

    promiseToExecute.then(
      (result) => {
        this.setState({ submitted: false, loading: false });
        if (result.status !== "1") {
          toast.error("Issues while communicating with the server");
        } else {
          this.props.onLicenseUpdated(isItADowngrade);
        }
      },
      (error) => {
        this.setState({ submitted: false, loading: false });
        toast.error("Licence update failed");
      }
    );
  }
}

export default ModalChooseLicense;
