import React from 'react';
import {Button,Modal,Form,Row,Col} from 'react-bootstrap'
import { toast } from "react-toastify";
import {serverService} from "../../_services/server.service";
import {Trans} from "react-i18next";
import i18n from "i18next";

interface MyProps {
    show:boolean,
    onHide(): any,
    IdPulse: number,
    Token: string,
} 
 

interface MyState {
    summary: string,
}

class ModalSummary extends React.Component<MyProps, MyState> {

    constructor(props) {
        super(props);
        this.state = {
            summary: "",
        };

        this.onEntered = this.onEntered.bind(this);
    }

    onEntered(){
        this.setState({summary: "Loading..."});
        serverService.getPulseSummary(this.props.IdPulse,this.props.Token)
            .then(
                (response) => {
                    if(response.status == 1){
                        this.setState({summary: response.data.summary})
                    }
                },
                (error) => {
                    console.log(error);
                    toast.warn(i18n.t("trans_33_msg_something_went_wrong"));
                }
            )
    }


    render() {
        return (
            <Modal
                show={this.props.show}
                onHide={this.props.onHide}
                size="lg"
                dialogClassName="modal-90w"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                onEntered = {this.onEntered}
            >
                <Modal.Header closeButton translate="yes">
                    <Modal.Title id="example-custom-modal-styling-title">
                        Executive Summary
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div dangerouslySetInnerHTML={{ __html: this.state.summary }} />
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="success" onClick={() => this.props.onHide()}>
                        <Trans>trans_33_close</Trans>
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }



}

export default ModalSummary;