import React from "react";
import {Trans} from "react-i18next";
import { serverService } from '../../../_services/server.service';
import {toast} from "react-toastify";

interface MyProps {
  rows: number;
  horizontal?: boolean;
  packType: number;
  onClickPack(IdQPack:number):any;
}

interface MyState {
  loading: boolean;
  pageNum: number;
  resources: any;
}

class QPacksGrid extends React.Component<MyProps, MyState> {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      pageNum: 0,
      resources : [{'IdQPack':'-3','Name': 'Pack1'},{'IdQPack':'-2','Name': 'Pack2'},{'IdQPack':'-1','Name': 'Pack3'}]
  };

    this.prevClick = this.prevClick.bind(this);
    this.nextClick = this.nextClick.bind(this);
    this.onClickPack = this.onClickPack.bind(this);

  }

  componentDidMount() {
    serverService.getAllQPacks(this.props.packType)
        .then(result => {
          if(result.status === "1"){
            this.setState({resources:result.data});
          }
        }, error => {
          console.log(error);
        })
  }

  render() {
    const { rows, horizontal } = this.props;
    const { resources, pageNum } = this.state;

    let items = [];
    for (let i = 0; i < rows; i++) {
      let colItems = [];
      for (let j = 0; j < 4; j++) {
        let resourceIndex = pageNum * (rows * 4) + i * 3 + j;

        if (resources == undefined) {
          break;
        }

        let oneResource = resources[resourceIndex];
        if (oneResource === undefined) {
          break;
        }

        colItems.push(
          <div
            className={`mt-3 w-100  ${horizontal ? "col-md-5" : "col-md-12"}`}
            key={"col_items_" + i + "_" + j}
          >
            <div className="guide-links"  data-id={oneResource.IdQPack} onClick={this.onClickPack}>
              <div className="row align-items-center top-buffer">
                <div className="col-md-3 col-3 pr-0 pr-md-2">
                  <div className="pdf-placeholder text-center">
                    <b>PACK</b>
                  </div>
                </div>
                <div className="col-8">
                  <div className="ml-3">
                    <span className="guide-item-title">{oneResource.Name}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      }

      items.push(
        <div className="w-100 row mx-0 justify-content-between" key={"pdf_items_" + i}>
          {colItems}
        </div>
      );
    }

    return (
      <div className="container-fluid">
        <div className="row">{items}</div>
        <div className="pdf-pagination">
          {pageNum > 0 && (
            <span className="pdf-btn-previous" onClick={this.prevClick}>
              <b>
                <Trans>trans_33_previous</Trans>
              </b>
            </span>
          )}

          {pageNum < resources.length / (rows * 4) - 1 && (
            <span className="pdf-btn-next" onClick={this.nextClick}>
              &nbsp;&nbsp;&nbsp;
              <b>
                <Trans>trans_33_next</Trans>
              </b>
            </span>
          )}
        </div>
      </div>
    );
  }

  onClickPack(event){
    let IdQPack = event.currentTarget.getAttribute('data-id');

    let resources = this.state.resources

    for(let i=0;i<resources.length;i++){
      if(resources[i]['IdQPack'] == IdQPack){
        this.props.onClickPack(resources[i]);
        break;
      }
    }

  }

  prevClick() {
    let pageNum = this.state.pageNum;
    this.setState({ pageNum: pageNum - 1 });
  }

  nextClick() {
    let pageNum = this.state.pageNum;
    this.setState({ pageNum: pageNum + 1 });
  }
}

export default QPacksGrid;
