import React from 'react';
import Cookies from 'universal-cookie';
import i18n from "i18next";
import { RouteComponentProps } from "react-router-dom";
import { Trans } from "react-i18next";
import { colors, Divider, FormControlLabel, Radio, RadioGroup } from '@material-ui/core';
import { toast } from "react-toastify";
import { Row, Col, Button } from "react-bootstrap";
import moment from 'moment'

import { PulseQuestion } from '../../models/pulse';
import { ScanQuestion } from '../../models/scan';

import { serverService } from '../../_services/server.service';
import ProgressBar from '../../_components/ProgressBar';
import AgreeQuestion from "../components/AgreeQuestion"
import { StringLiteral } from 'typescript';
import LanguageSelect from '../_other/LanguageSelect';

const qs = require('query-string');

const PAGE_SIZE = 5;

interface Answer {
  agrees?: boolean;
  comment?: string;
}
interface State {
    questions?: Array<any>,  
    answers: {[id: string]: Answer|undefined},

    languageCode: string,

    page: number,
 
    loading: boolean,
    generatingReport: boolean,
    submitting: boolean,
    submitted: boolean,
    error: string,

    notEnoughVotesForReport: boolean, 
    scanEndDateJustExtened: boolean, 

    displayQuestionsOrReport: number;
    fileUrl: string,

    DateToEnd:string,

    selectedIdLanguage: number,

}

function cookiename(id: string): string {
  return `submitted_${id}`;
}

class OnePulseOrOneScanPage extends React.Component<RouteComponentProps, State> {

    private cookies = new Cookies();
    pulse_id: string;
    scan_id: string;
    h: string;
    isPrivate: boolean;

    constructor(props) {
        super(props);

        this.pulse_id = qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).IdPulse;
        this.scan_id = qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).IdScan;

        let f = qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).f;
        if(f){
          this.isPrivate = true;
        }

        let idPulseOrIdScan = this.pulse_id;
        if(this.scan_id){
          idPulseOrIdScan = this.scan_id;
        }



        this.h = qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).h;

        this.state = {
          questions: [],
          answers: {},

          displayQuestionsOrReport: 1,
          fileUrl: "",

          languageCode: "en",

          page: 0,

          loading: false,
          generatingReport: false,
          submitting: false,
          submitted: !!this.cookies.get(cookiename(idPulseOrIdScan)),
          error: '',

          DateToEnd: "",

          notEnoughVotesForReport: false,
          scanEndDateJustExtened:false,

          selectedIdLanguage: 0,
 
        };


        this.extendScan7Days = this.extendScan7Days.bind(this);
        this.onLanguageSelected = this.onLanguageSelected.bind(this);

        this.fetchPulseOrScan = this.fetchPulseOrScan.bind(this);
        this.fetchScan = this.fetchScan.bind(this);
        this.fetchPulse = this.fetchPulse.bind(this);

    }

    componentDidMount() { 
      this.fetchPulseOrScan();
    }

    fetchPulseOrScan(){
      if (this.pulse_id && this.h) {
        this.fetchPulse();
      } else if (this.scan_id && this.h) {
        this.fetchScan();
      }
    }

    fetchScan(){
      this.setState({ loading: true }); 

          
      serverService.getScanById(this.scan_id, this.h, this.state.selectedIdLanguage)
          .then(
              response => {
                  if (response.status == 0) {
                      toast.warn(response.message);
                      this.setState({ loading: false })
                  } else {
                    let languageCode = "en";
                    let DateToEnd = "";
                    if(response.data.length > 0){
                      languageCode = response.data[0]['LanguageCode'];
                      DateToEnd = response.data[0]['DateToEnd'];
                    } 

                    let displayQuestionsOrReport = 1;
                    if(DateToEnd.length == 0 || DateToEnd.includes("0000-00-00")){
                      displayQuestionsOrReport = 1;
                    } else {
                      var DateToEndMoment = moment(DateToEnd);
                      var nowMoment = moment();
                      if (nowMoment >= DateToEndMoment){ 
                        this.setState({ displayQuestionsOrReport:2, loading: true, languageCode: languageCode });

                        serverService.getScanResults(this.scan_id,this.h)
                                .then(
                                    response => {  
                                        console.log(response);
                                        this.setState({ loading: false});

                                        if(response.status == 1){
                                            this.setState({ displayQuestionsOrReport: 2, fileUrl: response.file});
                                        } else {
                                           if(response.status == 2){
                                             this.setState({ notEnoughVotesForReport: true});
                                           } else {
                                             toast.warn(response.message);
                                           }
                                        }
                                    },
                                    error => {
                                      this.setState({ loading: false});
                                      toast.warn(error.message);
                                    } 
                                )

                        return;
                      } else {
                        this.setState({ DateToEnd: moment(DateToEnd).format("D MMMM")});
                      }
                    }

                    this.setState({ questions: response.data, displayQuestionsOrReport:1, loading: false, languageCode: languageCode });
                  }
              }, 
              error => {
                  toast.error("Server error");
                  this.setState({ error, loading: false })
              }
          );
    }


    fetchPulse(){
      this.setState({ loading: true });
      serverService.getPulseById(this.pulse_id, this.h)
          .then(
              response => {
                  if (response.status == 0) {
                      toast.warn(response.message);
                      this.setState({ loading: false })
                  } else { 
                      this.setState({ questions: response.data, loading: false });
                  }
              },
              error => {
                  toast.error("Server error");
                  this.setState({ error, loading: false })
              }
          );
    }

    onSubmit(e) {
        e.preventDefault();

      let {
        answers,
        questions,
      } = this.state;

      // This shouldn't happen, as we should've disabled the submit button
      // if not all questions have been answered.
      if (questions.length > Object.keys(answers).length) {
        toast.warn(i18n.t("trans_33_pulse_form_you_need_to_answer_all"));
        return;
      }

      this.setState({submitting: true});
 

      if(this.pulse_id){
        let data = Object.entries(answers).map(([id, {agrees, comment}]) => {
          return {
            IdPulseQuestion: id,
            YesOrNo: agrees ? '1' : '0',
            Comments: comment,
          };
        });
 
        serverService.insertPulseAnswers(this.pulse_id, data, this.h)
          .then(
              response => {
                if(response.status == 1){
                  toast.success(i18n.t("trans_33_pulse_form_thank_you"));
                  this.setState({ submitting: false, submitted: true });
                  this.cookies.set('submitted_' + this.pulse_id, true, { path: '/' });
                } else {
                  toast.warn(response.message);
                  this.setState({ submitting: false })
              }
              },
              error => {
                  toast.error("Server error");
                  this.setState({ submitting: false })
              }
          );
      } else if(this.scan_id){
        //console.log(questions); 
        //console.log(answers);
        let data = Object.entries(answers).map(([id, {agrees, comment}]) => {
          return {
            IdQuestion: id,
            YesOrNo: agrees ? '1' : '0',
            Comments: comment,
          }; 
        }); 

        serverService.insertScanAnswers(this.scan_id, data, this.h)
          .then( 
              response => {
                if(response.status == 1){
                  toast.success(i18n.t("trans_33_pulse_form_thank_you"));
                  this.setState({ submitting: false, submitted: true });
                  this.cookies.set('submitted_' + this.scan_id, true, { path: '/' });
                } else {
                  toast.warn(response.message);
                  this.setState({ submitting: false })
              }
                 
              },
              error => {
                  toast.error("Server error");
                  this.setState({ submitting: false })
              }
          );
      }
        
    }

    onAnswer(id: number, agrees: boolean) {
      let answer = this.state.answers[id] || {};
      answer.agrees = agrees;

      let answers = {
        ...this.state.answers,
        [id]: answer,
      };

      this.setState({answers});
    }

    onComment(id: number, comment: string) {
      let answer = this.state.answers[id] || {};
      answer.comment = comment;

      let answers = {
        ...this.state.answers,
        [id]: answer,
      };

      this.setState({answers});
    }

    render() {
        const {
          questions,
          answers,
          submitted,
          submitting,
          loading,
          error,
          page,
          DateToEnd,
        } = this.state;

      const num_questions = this.state.questions.length;
      const num_answers = Object.values(this.state.answers).filter(a => typeof a.agrees === 'boolean').length;

      const num_pages = Math.ceil(num_questions / PAGE_SIZE);
      const last_page = num_pages-1;

      const current_page_first_question_index = page * PAGE_SIZE;
      const current_page_questions = questions.slice(current_page_first_question_index, current_page_first_question_index + PAGE_SIZE);

      let pageTitle = ""
      if(this.pulse_id){
        pageTitle = i18n.t("trans_33_onepulse_title");
      } else if(this.scan_id){
        pageTitle = i18n.t("trans_33_onescan_title",{'lng': this.state.languageCode});
      }


      let letsStartText = i18n.t("trans_33_lets_start",{'lng': this.state.languageCode})
      let pleaseSubmitText = i18n.t("trans_33_please_sumbit_your_answers",{'lng': this.state.languageCode})

      let previousText = i18n.t("trans_33_previous",{'lng': this.state.languageCode})
      let nextText = i18n.t("trans_33_next",{'lng': this.state.languageCode})
      let sumbitText = i18n.t("trans_33_Submit",{'lng': this.state.languageCode})

      let msgYouNeedToAnswerAll = i18n.t("trans_33_pulse_form_you_need_to_answer_all",{'lng': this.state.languageCode})
      let msgThankYouForYourAnswers = i18n.t("trans_33_pulse_form_thank_you",{'lng': this.state.languageCode})


      let msgComeBackToSeeTheResults = "";
      if(!this.pulse_id){
        msgComeBackToSeeTheResults = i18n.t("trans_33_pulse_come_back_for_results",{'lng': this.state.languageCode , DateToEnd: DateToEnd });
      }
      

 
      let on_next = () => {
        let ids = []
        if(this.pulse_id){
          ids = current_page_questions.map(q => q.IdPulseQuestion);
        } else if(this.scan_id){
          ids = current_page_questions.map(q => q.IdQuestion); 
        } 
        
        if (ids.every(id => 'boolean' === typeof answers[id]?.agrees)) {
          this.setState({page: page+1});
        } else {
          toast.warn(msgYouNeedToAnswerAll);
        }
      }; 

     
      if(this.isPrivate && this.state.displayQuestionsOrReport > 1){
        return (
          <div className="text-center" style={{'paddingLeft':'16px','paddingRight':'16px'}}>
            <br/>
            <br/>
            <br/>
            <img src="assets/images/logo.png" />
            <br/>
            <br/>
            <br/>
            <h1>{i18n.t("trans_33_scan_not_available")}</h1>
          </div>
        )
      }


      if(this.state.displayQuestionsOrReport > 1){
        return (
            <div className="text-center" style={{'paddingLeft':'16px','paddingRight':'16px'}}>
                <br/>
                <br/>
                <br/>
                <img src="assets/images/logo.png" />
                <br/>

                <br/>
                <br/> 
                <br/>
             
                {!this.state.notEnoughVotesForReport && 
                    <h1>{i18n.t("trans_33_scan_expired_message")}</h1>
                }


                {this.state.fileUrl &&
                <a href={this.state.fileUrl} target="_blank" rel="noopener noreferrer" download>
                  <Button variant="primary" >
                      <Trans>
                      trans_33_download
                      </Trans>
                  </Button> 
                </a>  
                }
                {(this.state.loading || this.state.generatingReport) &&
                  <img alt="loading" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                } 
  
                {this.state.notEnoughVotesForReport && !this.state.scanEndDateJustExtened &&
                  <div>
                    <br/>
                    <h3>Not enough votes to generate a representative report</h3>
                    <br/>
                    <Button variant="primary" onClick={this.extendScan7Days}>
                      <Trans>
                      Extend 7 more days
                      </Trans> 
                    </Button> 
                  </div> 
                } 


                {this.state.scanEndDateJustExtened &&
                  <div>
                    <h1>Scan was extended for 7 more days</h1>
                    <br/>
                    <br/>
                    <h4>Send the link to your team</h4>
                    <Button variant="primary" onClick={this.copyLink}>
                      <Trans>
                        Copy link
                      </Trans> 
                    </Button> 
                  </div>
                 
                }


            </div> 
        );
    }

    let displayQuestionsOrReport = this.state.displayQuestionsOrReport;
        return (
          <div className="survey-section-wrap">
            <div className='row'>

              <div className="col-md-3">
              </div>

                <div className="col-md-6">
                  <div className="text-center">
                    <img src="assets/images/logo.png" style={{ 'width': '20%'}} />
                    <h3>{pageTitle}</h3>
                  </div>
                   
                </div>

                <div className="col-md-3">
                  {this.scan_id &&
                      <LanguageSelect
                      selectedIdLanguage={this.state.selectedIdLanguage}
                      onLanguageSelected={this.onLanguageSelected}
                    />
                  }
                </div>

            </div>
                
            
          
                {this.state.submitted && displayQuestionsOrReport <= 1 &&
                    <div className="text-center" style={{ 'paddingLeft': '16px','paddingTop': '16px', 'paddingRight': '16px' }}>
                        <h2>{msgThankYouForYourAnswers}</h2>
                        <h4>{msgComeBackToSeeTheResults}</h4>
                    </div> 
                } 

                {!this.state.submitted && displayQuestionsOrReport<= 1 &&
                    <div>
                        <br />

                        <div className="progress-wrap">
                            <span className="let">{letsStartText}</span>
                            <span className="fraction">{num_answers}/{num_questions}</span>
                            <ProgressBar progress={num_answers/num_questions} />
                        </div>
                        <h3 className='head-text'>{pleaseSubmitText}</h3>
                        <br />

                        <form name="form" onSubmit={e=>e.preventDefault()} >
                            {current_page_questions.map((q) => {
                              let id = q.IdPulseQuestion; 
                              if(!id){
                                id = q.IdQuestion;
                              } 
                              let answer = this.state.answers[id] || {};
                              return (
                                <AgreeQuestion
                                  key={id} 
                                  text={q.QuestionText}
                                  languageCode={this.state.languageCode}
                                  acceptComment={!!q.AcceptsComments}
                                  agrees={answer.agrees}
                                  comment={answer.comment}
                                  onAnswer={(a) => this.onAnswer(id, a)}
                                  onComment={(c) => this.onComment(id, c)}
                                />);
                            })}
                            <br />
                            <Row><Col><div className="form-group">
                              {loading && (
                                <img alt="loading" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                              )}
                            </div></Col></Row>

                            {error && <div className="alert alert-danger">{error}</div>}

                            <div className='button'>
                                <div className="text-left">
                                    { page > 0 && (
                                        <button
                                          onClick={e => this.setState({page: page-1})}
                                          disabled={loading}
                                          type="button"
                                        >
                                            <i className="fa-solid fa-arrow-left" style={{ padding: "0rem 0.5rem" }}></i>
                                            {previousText}
                                        </button>
                                    )}
                                </div>

                                <div className="text-right" id='text-right'>
                                    { page === last_page ? (
                                        <button
                                          onClick={e => this.onSubmit(e)}
                                          disabled={loading || submitting}
                                          type="button"
                                        >
                                            {sumbitText}
                                            <i className="fa-solid fa-arrow-right" style={{ padding: "0rem 0.5rem" }}></i>
                                        </button>
                                    ):(
                                        <button
                                          onClick={on_next}
                                          disabled={loading}
                                          type="button"
                                        >
                                            {nextText}
                                            <i className="fa-solid fa-arrow-right" style={{ padding: "0rem 0.5rem"}}></i>
                                        </button>
                                    )}
                                </div>
                              </div>
                          </form>
                      </div>
                  }
            </div>
        );
    }

    extendScan7Days() {
      serverService.extendScan7Days(this.scan_id, this.h).then(result => {
            if(result.status == 1){
                this.setState({scanEndDateJustExtened: true})
            } else {
              toast.warn(result.message);
            }
            console.log(result);
          }, error => {
              console.log(error);
          })
    }

    copyLink() {
      navigator.clipboard.writeText(window.location.toString())
      toast.success("Link copied to clipboard");
    }


    onLanguageSelected(selectedIdLanguage: number) {
      this.setState({ selectedIdLanguage: selectedIdLanguage }, () => {
        this.fetchPulseOrScan();
      });
      
    }
    

}





export { OnePulseOrOneScanPage as OnePulseOrOneScanPage };
