import React from 'react'

import i18n from "i18next";


interface Props {
  // The Question
  text: string;
  acceptComment: boolean;

  // Current response
  agrees? : boolean | null;
  comment?: string;

  languageCode?: string;

  // input handlers
  onAnswer(agrees: boolean): void;
  onComment(value: string): void;
}

export default function AgreeQuestion({
  text, acceptComment,
  agrees, comment
  ,languageCode,
  onAnswer, onComment,
}: Props): JSX.Element {
  let agreeText = i18n.t("trans_33_pulse_form_agree",{'lng': languageCode})
  let disagreeText = i18n.t("trans_33_pulse_form_disagree",{'lng': languageCode})

  return (
    <div className="question-container">
      <div className='question'>
    {text}
      </div>

      <div className="input-wrapper">
        <label className={'radio-label agree ' + (agrees === true ? 'checked' : '')}>
          {agreeText}
          <input
            type="radio"
            className='agree-button'
            checked={agrees === true}
            onChange={()=>onAnswer(true)}
          />
        </label>
        <label className={'radio-label disagree ' + (agrees === false ? 'checked' : '')}>
          {disagreeText}
          <input
            type="radio"
            className='disagree-button'
            checked={agrees === false}
            onChange={()=>onAnswer(false)}
          /> 
        </label>
      </div>

      {acceptComment &&
        <textarea
          className='comment'
          rows={1}
          placeholder="comment"
          value={comment}
          onChange={(e)=>onComment(e.target.value)}
        />
      }
    </div>
  );
}





// import React from 'react'
// 
// import { Question } from '../../models/pulse';
// 
// interface Props {
//   text: string;
//   agrees? : boolean | null;
//   acceptsComment? : boolean;
//   comment?: string;
// 
//   onAnswer(agrees: boolean): void;
//   onComment(value: string): void;
// }
// 
// export default function NextButton({
//   id, text, answer, comment,
// }: Props): JSX.Element {
//   return <div>
//     <div className='question'>
//      <label className='label-quest quest-in'>{props.question.QuestionText}</label>
//     </div>
// 
//     <div className="input-wrapper">
//      <input
//        type="radio"
//        className='agree-button'
//        id={`yes_${props.question.IdPulseQuestion}`}
//        name={`yesorno${props.question.IdPulseQuestion}`}
//        value="1"
//        onChange={props.onChange}
//      />
//      <label
//        className='agree'
//        htmlFor={`yes_${props.question.IdPulseQuestion}`}
//      > Agree </label>
//      <input
//        type="radio"
//        className='disagree-button'
//        id={`no_${props.question.IdPulseQuestion}`}
//        name={`yesorno${props.question.IdPulseQuestion}`}
//        value="0"
//        onChange={props.onChange}
//      /> 
//      <label
//        className='disagree'
//        htmlFor={`no_${props.question.IdPulseQuestion}`}
//      > Disagree </label>
//     </div>
// 
//     <div>
//      {props.question.AcceptsComments===1 ?<textarea className='comment' id={`comment_${props.question.IdPulseQuestion}`} name="comment" rows="1" placeholder="comments"/>:"" }  
//     </div>
//   </div>;
// }
