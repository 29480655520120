/**
 * Created by Alex on 22-Mar-19.
 */
import React from 'react';
import {Button,Modal,Form,Row,Col} from 'react-bootstrap'
import { toast } from "react-toastify";


import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';

import  {
    formatDate,
    parseDate,
} from 'react-day-picker/build/addons/MomentLocaleUtils';
import ModalOnboardingCancelScheduleGiftPack from "./ModalOnboardingCancelScheduleGiftPack";
import {serverService} from "../../_services/server.service";
import i18n from "i18next";
import {Trans} from "react-i18next";
import ItemsSelect from "../_other/ItemsSelect";

interface MyProps {
    onConfirmOnboardingScheduleGiftPack(): void,
    show:boolean,
    onHide(): any,
}

interface MyState {
    formFields:any,
    loading:boolean,
    modalOnboardingCancelScheduleGiftPack:boolean,
    qpacks: any,
}


class ModalOnboardingScheduleGiftPack extends React.Component<MyProps,MyState> {

    constructor(props) {
        super(props);
        this.state = {
            formFields:{},
            loading:false,
            modalOnboardingCancelScheduleGiftPack:false,
            qpacks:[],
        };



        this.handleDateChange = this.handleDateChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleCancelClick = this.handleCancelClick.bind(this);
        this.handleYesChangedMyMindClick = this.handleYesChangedMyMindClick.bind(this);
        this.handleCancelGiftPackClick = this.handleCancelGiftPackClick.bind(this);
        this.onPackSelected = this.onPackSelected.bind(this);
        this.onEntered = this.onEntered.bind(this);

    }


    onEntered(){
        serverService.getAllQPacks(0)
            .then(result => {
                if(result.status === "1"){
                    let qpacks = [];
                    for(let i=0;i<result.data.length;i++){
                        qpacks.push({'IdItem': result.data[i].IdQPack,'Name': result.data[i].Name})
                    }
                    this.setState({qpacks: qpacks});
                }
            }, error => {
                console.log(error);
            })
    }

    handleDateChange(from) {
        let formFields = this.state.formFields;
        formFields["StartDate"] = from;
        this.setState({ formFields: formFields });
    }

    handleSubmit(e) {

        e.preventDefault();

        const { formFields } = this.state;
        const StartDate = formFields.StartDate;
        const IdQPack = formFields.IdQPack;

        // stop here if form is invalid
        if (!(StartDate)) {
            toast.error(i18n.t("trans_33_modal_onboarding_gift_pack_msg_select_date"));
            return;
        }

        if (!(IdQPack)) {
            toast.error(i18n.t("trans_33_modal_onboarding_gift_pack_msg_select_pack"));
            return;
        }

        let userStr = localStorage.getItem('user');
        let IdCompany = 0;
        if(userStr) {
            let userObj = JSON.parse(userStr);
            IdCompany = userObj.IdCompany;
        }
        this.setState({ loading: true });
        

        serverService.schedulePack(formFields.IdQPack,StartDate)
            .then(
                result => {
                    this.setState({ loading: false });
                    if(result.status == 1){
                        toast.success(i18n.t("trans_33_modal_onboarding_gift_pack_msg_questions_scheduled"));
                        this.props.onConfirmOnboardingScheduleGiftPack();
                    } else {
                        toast.warn("An issue occurred while scheduling questions")
                    }
                }
                ,
                error => {
                    toast.warn("Did not schedule");
                    this.setState({ loading: false });
                }
            );
    }

    handleCancelClick() {
        this.setState({modalOnboardingCancelScheduleGiftPack:true});
    }

    handleYesChangedMyMindClick() {
        this.setState({modalOnboardingCancelScheduleGiftPack:false});
    }

    handleCancelGiftPackClick() {
        this.props.onHide();
    }

    render() {
        let {loading,qpacks,formFields} = this.state;

        const today = new Date();
        const tomorrowDate = new Date(today);
        tomorrowDate.setDate(tomorrowDate.getDate() + 1);

        return (
            <Modal
                show={this.props.show}
                onHide={this.props.onHide}
                size="lg"
                dialogClassName="modal-90w"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                backdrop="static"
                onEntered = {this.onEntered}
            >
                <Modal.Header  className="add-vox-modal-header"  translate="yes">
                    <Modal.Title id="example-custom-modal-styling-title">
                        <img src="/assets/images/onboarding/onboarding_gift.png" id="gift_img"/>
                        <Trans>
                            trans_33_modal_onboarding_gift_pack_title
                        </Trans>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="add-vox-modal-content" >
                    <p>
                        <Trans>
                            trans_33_modal_onboarding_gift_pack_description1
                        </Trans>
                    </p>
                    <p>
                        <Trans>
                            trans_33_modal_onboarding_gift_pack_description2
                        </Trans>
                    </p>
                    <p>
                        <Trans>
                            trans_33_modal_onboarding_gift_pack_description3
                        </Trans>
                    </p>
                    <br/>


                    <Trans>trans_33_modal_onboarding_gift_pack_choose_pack</Trans>
                    <div className="container">
                        <div className="row">
                            <div className="col-md6">
                                <ItemsSelect selectedIdItem={formFields.IdQPack} items={qpacks}
                                             onItemSelected={this.onPackSelected} defaultText={i18n.t("trans_33_select_pack")} />
                            </div>
                        </div>
                    </div>

                    <br/>

                    <Form onSubmit={this.handleSubmit}>
                            <Form.Group controlId="formBasicName">
                                <Trans>
                                    trans_33_modal_onboarding_gift_pack_when_to_start
                                </Trans>
                                <br/>
                                <div className="InputStartDate">
                                    <DayPickerInput
                                        placeholder={i18n.t('trans_33_choose_date')}
                                        formatDate={formatDate}
                                        parseDate={parseDate}
                                        format="DD/MM/YYYY"
                                        onDayChange={this.handleDateChange}
                                        dayPickerProps={{
                                            disabledDays: {before: tomorrowDate,},
                                            firstDayOfWeek:1,
                                        }}
                                    />
                                </div>
                            </Form.Group>

                        <br/>

                        <Modal.Footer>
                            <a href="#" onClick={this.handleCancelClick}>
                                <Trans>
                                    trans_33_modal_onboarding_gift_pack_no_gift_btn
                                </Trans>
                            </a>
                            <Button variant="primary" type="submit">
                                <Trans>
                                    trans_33_modal_onboarding_gift_pack_schedule_btn
                                </Trans>
                            </Button>
                            {loading &&
                            <img alt="loading" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                            }
                        </Modal.Footer>

                    </Form>

                </Modal.Body>

                <ModalOnboardingCancelScheduleGiftPack
                    show={this.state.modalOnboardingCancelScheduleGiftPack}
                    onConfirmChangedMyMind={this.handleYesChangedMyMindClick}
                    onHide={this.handleYesChangedMyMindClick}
                    onConfirmCancelGiftPack={this.handleCancelGiftPackClick}
                />

            </Modal>
        );
    }


    onPackSelected(IdQPack){
        let formFields = this.state.formFields;
        formFields['IdQPack'] = Number(IdQPack);
        this.setState({ formFields: formFields });
    }


}

export default ModalOnboardingScheduleGiftPack;