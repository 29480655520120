import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Player } from "video-react";

import { serverService } from "../../_services/server.service";


import "../../../node_modules/video-react/dist/video-react.css";
import VideosGrid from "./VideosGrid";
import PdfsGrid from "./PdfsGrid";
import { toast } from "react-toastify";
import {Trans} from "react-i18next";

const moment = require("moment");

interface MyProps {}

interface MyState {
  loading: boolean;
  videoResources: any;
  pdfResources: any;
  pdfPage: number;

  rowsVideo: number;
  rowsPdf: number;
  advice: boolean;
}

class AdvicesSelfStarter extends React.Component<MyProps, MyState> {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      videoResources: [],
      pdfResources: [],
      pdfPage: 0,
      advice: true,
      rowsVideo: 2,
      rowsPdf: 3,
    };
  }

  componentDidMount() {
    this.setState({});

    this.doFetchResources();
  }

  render() {
    const {
      videoResources,
      pdfResources,
      rowsVideo,
      rowsPdf,
      advice,
    } = this.state;

    return (
      <div className="container pl-4">
        <br />
        <br />
        <span style={{ fontWeight: "bold" }}><Trans>trans_33_advices_videos</Trans></span>
        <br />
        <VideosGrid videoResources={videoResources} rowsVideo={rowsVideo} />
        <hr style={{ backgroundColor: "#20ABEC", opacity: 0.1 }} />
        <br />
        <span style={{ fontWeight: "bold" }}><Trans>trans_33_advices_guides</Trans></span>
        <br />
        {pdfResources != undefined && (
          <div>
            <PdfsGrid
              advice={advice}
              pdfResources={pdfResources}
              rowsPdf={rowsPdf}
              displayDate={false}
            />
          </div>
        )}
      </div>
    );
  }

  doFetchResources() {
    let userStr = localStorage.getItem("user");
    let IdCompany = 0;
    if (userStr) {
      let userObj = JSON.parse(userStr);
      IdCompany = userObj.IdCompany;
    }

    this.setState({ loading: true });
    serverService.getResources(null, null, null, 1).then(
      (resultsRes) => {
        if (resultsRes && resultsRes.data !== undefined) {
          let videoResources = [];
          let pdfResources = [];
          for (let i = 0; i < resultsRes.data.length; i++) {
            if (resultsRes.data[i]["Type"] === 2) {
              videoResources.push(resultsRes.data[i]);
            } else if (resultsRes.data[i]["Type"] === 1) {
              pdfResources.push(resultsRes.data[i]);
            }
          }
          this.setState({
            videoResources: videoResources,
            pdfResources: pdfResources,
            loading: false,
          });
        } else {
          this.setState({ loading: false });
        }
      },
      (error) => {
        toast.warn("Did not get resources1");
        this.setState({ loading: false });
      }
    );
  }
}

export default AdvicesSelfStarter;
