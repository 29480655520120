import React from "react";
import { Form, Container, Row, Col, Button } from "react-bootstrap";
import { Player } from "video-react";

import { serverService } from "../../_services/server.service";

import "../../../node_modules/video-react/dist/video-react.css";
import VideosGrid from "./VideosGrid";
import PdfsGrid from "./PdfsGrid";
import SkillSelect from "../_other/SkillSelect";
import { toast } from "react-toastify";

import { config } from "../../_helpers/C";
import {Trans} from "react-i18next";

import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
import "./AdvicesStandard.css";

import  {
  formatDate,
  parseDate,
} from 'react-day-picker/build/addons/MomentLocaleUtils';

import i18n from "i18next";
import LanguageSelect from "../_other/LanguageSelect";


const moment = require("moment");

interface MyProps {}

interface MyState {
  loading: boolean;
  pdfSuggestedResources: any;
  pdfMonthlyReportResources: any;
  videoResources: any;
  pdfResources: any;
  pdfPage: number;
  rowsVideo: number;
  rowsPdf: number;

  selectedIdSkill: number;
  pdfChecked: boolean;
  videoChecked: boolean;

  from: any;
  to: any;
  fileUrl:string;

  selectedDaysModifiers: any;

}

class AdvicesStandard extends React.Component<MyProps, MyState> {
  private CompanyToken;

  private ctxTo: React.RefObject<DayPickerInput>;

  private userObj;

  constructor(props) {
    super(props);

    this.ctxTo = React.createRef<DayPickerInput>();

    this.state = {
      loading: false,
      pdfSuggestedResources: [],
      pdfMonthlyReportResources: [],
      videoResources: [],
      pdfResources: [],
      pdfPage: 0,
      rowsVideo: 1,
      rowsPdf: 2,

      selectedIdSkill: 0,
      pdfChecked: true,
      videoChecked: true,

      from: undefined,
      to: undefined,
      fileUrl:undefined,

      selectedDaysModifiers: {}

    };

    this.onSkillSelected = this.onSkillSelected.bind(this);
    this.handlePdfChecked = this.handlePdfChecked.bind(this);
    this.handleVideoChecked = this.handleVideoChecked.bind(this);

    this.handleFromChange = this.handleFromChange.bind(this);
    this.handleToChange = this.handleToChange.bind(this);
    this.generateReport = this.generateReport.bind(this);

    this.fetchDataForSelectedMonth = this.fetchDataForSelectedMonth.bind(this);
    this.onLanguageSelected = this.onLanguageSelected.bind(this);


  }

  componentDidMount() {
    this.setState({});


    let userStr = localStorage.getItem("user");
    if (userStr) {
      this.userObj = JSON.parse(userStr);
      this.CompanyToken = this.userObj.CompanyToken;
    }


    this.doFetchSuggestedResources();
    this.doFetchMontlyhReportResources();

  }

  render() {
    const {
      videoResources,
      pdfResources,
      pdfSuggestedResources,
      pdfMonthlyReportResources,
      rowsVideo,
      rowsPdf,
      selectedIdSkill,
      pdfChecked,
      videoChecked,
    } = this.state;


    const { from, to } = this.state;

    let modifiers = { start: from, end: to };
    modifiers = this.state.selectedDaysModifiers;


    let IdLanguageForReports = 1;
    if (this.userObj && this.userObj.IdLanguageForReports) {
      IdLanguageForReports = this.userObj.IdLanguageForReports;
    } else if (this.userObj && this.userObj.IdLanguage) {
      IdLanguageForReports = this.userObj.IdLanguage;
    }

    return (
      <div className="container ml-2">
          {" "}
          <div className="row">
            <div className="col-md-12">
              <br />
              <p><Trans>trans_33_advice_page_choose_language</Trans></p>
              <LanguageSelect
                  selectedIdLanguage={IdLanguageForReports}
                  onLanguageSelected={this.onLanguageSelected}
              />
            </div>


            <div className="col-md-12">

              {pdfMonthlyReportResources.length > 0 && (
                  <div className="w-100" >
                    <br />
                    <span style={{ fontWeight: "bold" }}>
                      <Trans>
                        trans_33_advices_standard_monthly_reports
                      </Trans>
                    </span>
                    <br />
                    {pdfMonthlyReportResources != undefined && (
                        <PdfsGrid
                            colNo={6}
                            advice={true}
                            pdfResources={pdfMonthlyReportResources}
                            rowsPdf={2}
                            displayDate={false}
                        />
                    )}
                  </div>
              )}

            </div>
          </div>

          <hr style={{backgroundColor:'#20ABEC', opacity:0.1}}/>
          <br />

          <div className="row">
            <div className="col-md-12">
              <span style={{ fontWeight: "bold" }}>
                <Trans>
                  trans_33_generate_report
                </Trans>
              </span>
              <div>
                <DayPickerInput
                    value={from}
                    placeholder="From"
                    format="LL"
                    formatDate={formatDate}
                    parseDate={parseDate}
                    dayPickerProps={{
                      firstDayOfWeek: 1,
                      selectedDays: [from, { from, to }],
                      disabledDays: { after: to },
                      toMonth: to,
                      modifiers,
                      numberOfMonths: 1,
                      onDayClick: () => this.ctxTo.current.getInput().focus(),
                      onMonthChange: (month) => {
                          let dateStr = moment(month).format("YYYY-MM-DD");
                          this.fetchDataForSelectedMonth(dateStr);
                      }
                    }}
                    onDayChange={this.handleFromChange}
                    onDayPickerShow={ () => this.fetchDataForSelectedMonth(null)}
                />{' '}
                —{' '}
                <span className="InputFromTo-to">
                      <DayPickerInput
                          ref={this.ctxTo}
                          value={to}
                          placeholder="To"
                          format="LL"
                          formatDate={formatDate}
                          parseDate={parseDate}
                          dayPickerProps={{
                            firstDayOfWeek: 1,
                            selectedDays: [from, { from, to }],
                            disabledDays: { before: from },
                            modifiers, 
                            //month: from,
                            fromMonth: from,
                            numberOfMonths: 1,
                            onMonthChange: (month) => {
                              let dateStr = moment(month).format("YYYY-MM-DD");
                              this.fetchDataForSelectedMonth(dateStr);
                            }
                          }}
                          onDayChange={this.handleToChange}
                          onDayPickerShow={ () => this.fetchDataForSelectedMonth(null)}
                      />
                  </span>

                <br />
                <br />

                {this.state.fileUrl &&
                <a href={this.state.fileUrl} target="_blank" rel="noopener noreferrer" download>
                  <Button variant="primary" >
                    <Trans>
                      trans_33_download
                    </Trans>
                  </Button>
                </a>
                }

                <hr style={{backgroundColor:'#20ABEC', opacity:0.1}}/>
                <br/>

              </div>


            </div>
          </div>


        <div className="row">
          <div className="col-md-12">
            {pdfSuggestedResources.length > 0 && (
              <div>
                <br />
                <span style={{ fontWeight: "bold" }}>
                  <Trans>
                    trans_33_advices_standard_suggestion
                  </Trans>
                </span>
                <br />
                {pdfResources != undefined && rowsPdf != undefined && (
                  <PdfsGrid
                    colNo={3}
                    pdfResources={pdfSuggestedResources}
                    rowsPdf={1}
                    displayDate={false}
                    advice={true}
                  />
                )}
              </div>
            )}
            <br/>
            <hr style={{ backgroundColor: "#20ABEC", opacity: 0.1 }} />

            <div className="row">
              <div className="col-md-4">
                <SkillSelect onSkillSelected={this.onSkillSelected} />
              </div>
              <div className="col-md-1">
                <Form.Check
                  inline
                  label="pdf"
                  type="checkbox"
                  id={`inline-checkbox-1`}
                  checked={pdfChecked}
                  onChange={this.handlePdfChecked}
                />
              </div>
              <div className="col-md-1">
                <Form.Check
                  inline
                  label="video"
                  type="checkbox"
                  id={`inline-checkbox-2`}
                  checked={videoChecked}
                  onChange={this.handleVideoChecked}
                />
              </div>
            </div>

            {selectedIdSkill > 0 && pdfChecked && (
              <div>
                <br />
                <span style={{ fontWeight: "bold" }}>
                  <Trans>
                    trans_33_advices_guides
                  </Trans>
                </span>
                <br />
                {pdfResources.length > 0 && rowsPdf != undefined && (
                  <PdfsGrid
                    pdfResources={pdfResources}
                    rowsPdf={rowsPdf}
                    displayDate={false}
                  />
                )}

                {pdfResources.length == 0 && <span>
                  <Trans>
                    trans_33_advices_no_pdfs_found
                  </Trans>
                </span>}
              </div>
            )}

            {selectedIdSkill > 0 && videoChecked && (
              <div>
                <br />
                <span style={{ fontWeight: "bold" }}>
                   <Trans>
                    trans_33_advices_videos
                  </Trans>
                </span>
                <br />
                <br />
                {videoResources.length > 0 && (
                  <VideosGrid
                    videoResources={videoResources}
                    rowsVideo={rowsVideo}
                  />
                )}

                {videoResources.length == 0 && <span>
                  <Trans>
                    trans_33_advices_no_videos_found
                  </Trans>
                </span>}
              </div>
            )}

            <br />
            <hr style={{ backgroundColor: "#20ABEC", opacity: 0.1 }} />
            <br />


            <br />
            <br />
            <br />
          </div>
        </div>
      </div>
    );
  }


  showFromMonth() {
    const { from, to } = this.state;
    if (!from) {
      return;
    }
    if (moment(to).diff(moment(from), 'months') < 2) {
      this.ctxTo.current.getDayPicker().showMonth(from);
    }
  }

  handleFromChange(from) {
    // Change the from date and focus the "to" input field
    this.setState({ from });
  }

  handleToChange(to) {
    this.setState({ to }, function(){
      this.showFromMonth();
      this.generateReport();
    });

  }


  fetchDataForSelectedMonth(aMonth) {
    // let day = moment(this.state.selectedDay).format("YYYY-MM-DD");
    // if (this.state.selectedDay === undefined) {
    //   day = moment().format("YYYY-MM-DD");
    // }
    //
    // let month = moment(this.state.selectedMonth).format("YYYY-MM-DD");
    // if (this.state.selectedMonth === undefined) {
    //   month = moment().format("YYYY-MM-DD");
    // }

    let month = moment().format("YYYY-MM-DD");

    if(aMonth){
      month = aMonth
    }

    this.setState({ loading: true });
    return serverService
        .getSchedulesDataForAgendaByMonth(
            month,
            0,
            0
        )
        .then(
            (result) => {
              if (result.status === "1") {
                let schedulesData = result.data;
                let numberOfVoxes = result.numberofvoxes;

                let userStr = localStorage.getItem("user");
                let IdUser = 0;
                let userObj = {};
                if (userStr) {
                  userObj = JSON.parse(userStr);
                  IdUser = userObj['IdUser'];
                }

                let FinishedOnboarding = 1;
                if(!userObj['FinishedOnboarding'] || userObj['FinishedOnboarding'] == 0){
                  FinishedOnboarding = 0;
                }

                if (
                    (FinishedOnboarding == 0 &&
                    schedulesData.length === 0 &&
                    month === moment().format("YYYY-MM-DD"))) {
                  // this.setState({ modalOnboardingScheduleGiftPack: true });
                }

                // {'2019-10-23': [{'IdVox':'32', 'IdQuestion': '88'},{'IdVox':'33', 'IdQuestion': '88'}]}
                let schedulesDataFormattedObject = {};

                for (let i = 0; i < schedulesData.length; i++) {
                  const date = moment(schedulesData[i].Date).format("YYYY-MM-DD");
                  if (schedulesDataFormattedObject[date] === undefined) {
                    schedulesDataFormattedObject[date] = [];
                  }
                  schedulesDataFormattedObject[date].push({
                    IdQuestion: schedulesData[i].IdQuestion,
                    IdVox: schedulesData[i].IdVox,
                    VoxName: schedulesData[i].Name,
                    QuestionText: schedulesData[i].QuestionText,
                    Type: schedulesData[i].Type,
                  });
                }

                let tempSelectedDaysModifiers = {
                  partiallyassigned: [],
                  assigned: [],
                };

                let weeklyViewColorCodes = {};
                for (let dateKey in schedulesDataFormattedObject) {
                  weeklyViewColorCodes[dateKey] = {};
                  weeklyViewColorCodes[dateKey].color = "none";
                  if (numberOfVoxes === 1) {
                    weeklyViewColorCodes[dateKey].color = "colorGreen";
                    tempSelectedDaysModifiers.assigned.push(new Date(dateKey));
                  } else if (
                      numberOfVoxes > 1 &&
                      schedulesDataFormattedObject[dateKey].length < numberOfVoxes
                  ) {
                    weeklyViewColorCodes[dateKey].color = "colorOrange";
                    tempSelectedDaysModifiers.partiallyassigned.push(
                        new Date(dateKey)
                    );
                  } else if (
                      numberOfVoxes > 1 &&
                      schedulesDataFormattedObject[dateKey].length >= numberOfVoxes
                  ) {
                    weeklyViewColorCodes[dateKey].color = "colorGreen";
                    tempSelectedDaysModifiers.assigned.push(new Date(dateKey));
                  }
                }


                console.log(tempSelectedDaysModifiers);

                this.setState({
                  selectedDaysModifiers: tempSelectedDaysModifiers,
                  loading: false,
                  // weeklyViewColorCodes: weeklyViewColorCodes,
                  // schedulesDataFormattedObject: schedulesDataFormattedObject,
                });
              } else {
                toast("Something went wrong while fetching data 2");
              }
            },
            (error) => {
              toast.warn("Did not get schedules");
              this.setState({ loading: false });
            }
        );
  }


  generateReport() {
    const {from,to} = this.state;

    const fromStr = moment(from).format("YYYY-MM-DD");
    const toStr = moment(to).format("YYYY-MM-DD");


    serverService.getPdfReport(fromStr,toStr)
        .then(result => {
          // console.log(result);

          this.setState({fileUrl: result.file});

          // window.location.assign(result);

        }, error => {
          console.log(error);
          toast.error(i18n.t("trans_33_msg_something_went_wrong"))
        })
  }



  onSkillSelected(IdSkill) {
    this.setState({ selectedIdSkill: IdSkill }, () => this.doFetchResources());
  }

  handlePdfChecked(event) {
    this.setState({ pdfChecked: event.target.checked });
  }

  handleVideoChecked(event) {
    this.setState({ videoChecked: event.target.checked });
  }

  doFetchResources() {
    let selectedIdSkill = this.state.selectedIdSkill;

    this.setState({ loading: true });
    serverService.getResources(null, selectedIdSkill, null, 2).then(
      (resultsRes) => {
        if (resultsRes && resultsRes.data !== undefined) {
          let videoResources = [];
          let pdfResources = [];
          for (let i = 0; i < resultsRes.data.length; i++) {
            if (resultsRes.data[i]["Type"] === 2) {
              videoResources.push(resultsRes.data[i]);
            } else if (resultsRes.data[i]["Type"] === 1) {
              pdfResources.push(resultsRes.data[i]);
            }
          }
          this.setState({
            videoResources: videoResources,
            pdfResources: pdfResources,
            //,pdfSuggestedResources: pdfResources
            loading: false,
          });
        } else {
          this.setState({ loading: false });
        }
      },
      (error) => {
        toast.warn("Did not get resources3");
        this.setState({ loading: false });
      }
    );
  }

  doFetchSuggestedResources() {
    this.setState({ loading: true });
    serverService.getSuggestedResources().then(
      (resultsRes) => {
        if (resultsRes && resultsRes.data !== undefined) {
          let pdfSuggestedResources = resultsRes.data;
          this.setState({
            pdfSuggestedResources: pdfSuggestedResources,
            loading: false,
          });
        } else {
          this.setState({ loading: false });
        }
      },
      (error) => {
        toast.warn("Did not get resources4");
        this.setState({ loading: false });
      }
    );
  }

  doFetchMontlyhReportResources() {
    serverService.getMonthlyReportResources().then(
      (resultsRes) => {
        if (resultsRes && resultsRes.data !== undefined) {
          let fileNames = resultsRes.data;
          let pdfsResources = [];

          for (let i = fileNames.length - 1; i >= 0; i--) {
            pdfsResources.push({
              Name: fileNames[i],
              Link:
                config.API_BASE_URL +
                "/pdfs/monthly_report/" +
                this.CompanyToken +
                "/" +
                fileNames[i],
            });
          }
          this.setState({ pdfMonthlyReportResources: pdfsResources });
        }
      },
      (error) => {
        //toast.warn("Did not get resources5");
      }
    );
  }

  onLanguageSelected(IdLanguageForReports) {
    let company = { IdCompany: this.userObj.IdCompany, IdLanguageForReports : IdLanguageForReports };
    serverService.updateCompany(company).then(
        (res) => {
          if(res.status === "1"){
            this.userObj.IdLanguageForReports = parseInt(IdLanguageForReports);
            localStorage.setItem("user", JSON.stringify(this.userObj));

            toast.success(i18n.t("trans_33_language_changed_success"));
          } else {
            toast.warn(res.message);
          }

        },
        (error) => {
          toast.warn("Did not update the language");
        }
    );
  }


}

export default AdvicesStandard;
