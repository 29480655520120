import React from 'react';
import {Button,Modal,Form,Row,Col} from 'react-bootstrap'
import { toast } from "react-toastify";

import { serverService } from '../../../_services/server.service';
import {Trans} from "react-i18next";


interface MyProps {
    selectedCompany:any,
    onCompanyAdded(extra:string):any,
    onCompanyEdited(extra:string):any,
    show:boolean,
    onHide():any,
}

interface MyState {
    submitted: boolean,
    loading: boolean,
    error: string,
    formFields:any,
    selectedCompany:any,
}

class AdminModalAddNewCompany extends React.Component<MyProps,MyState> {

    constructor(props) {
        super(props);

        this.state = {
            submitted: false,
            loading: false,
            error: '',

            formFields:{},

            selectedCompany: {},
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount(){
    }

    handleChange(e) {
        const { name, value } = e.target;
        let formFields = this.state.formFields;
        formFields[name] = value;
        this.setState({ formFields: formFields });
    }


    handleSubmit(e) {
        e.preventDefault();

        this.setState({submitted: true});

        const { formFields } = this.state;
        const Name = formFields.Name;
        const AutoPilot = formFields.AutoPilot;
        const ResponsableFirstName = formFields.ResponsableFirstName;
        const Address = formFields.Address;
        const VATNumber = formFields.VATNumber;
        const PhoneNumber = formFields.PhoneNumber;
        const Email = formFields.Email;
        const IdLanguage = formFields.IdLanguage;

        const IdStripe = this.state.selectedCompany.IdStripe;

        // stop here if form is invalid
        if (!(Name)) {
            toast.error("Missing fields");
            return;
        }

        let company = {'Name':Name,'AutoPilot':AutoPilot,'ResponsableFirstName':ResponsableFirstName,
            'Address':Address,'VATNumber':VATNumber,'PhoneNumber':PhoneNumber,'Email':Email
            ,'IdLanguage':IdLanguage, 'IdStripe': IdStripe};

        if(this.state.selectedCompany && typeof(this.state.selectedCompany.IdCompany) !== "undefined"){
            company['IdCompany'] = this.state.selectedCompany.IdCompany.toString();

            serverService.insertOrUpdateCompanyAdmin(company)
                .then(
                    result => {
                        if(result.status == 1){
                            let messageStr = "Company edited";
                            toast.success(messageStr);
                            this.props.onCompanyEdited(result.extra);
                        } else {
                            toast.error("Edit company failed");
                        }
                    },
                    error => {
                        toast.warn("Did not update company");
                    }
                );
        } else {
            serverService.insertOrUpdateCompanyAdmin(company)
                .then(
                    result => {
                        if(result.status == 1){
                            let messageStr = "Company added";
                            toast.success(messageStr);
                            this.props.onCompanyAdded(result.extra);
                        } else {
                            toast.error("Add company failed");
                        }
                    },
                    error => {
                        toast.warn("Did not get insert company");
                    }
                );
        }
    }

    componentDidUpdate(prevProps, prevState){
        if(this.props.selectedCompany !== prevState.selectedCompany){
            this.setState({selectedCompany:this.props.selectedCompany});

            let formFields = this.state.formFields;
            formFields.Name = "";
            formFields.AutoPilot = "0";
            formFields.ResponsableFirstName = "";
            formFields.Address = "";
            formFields.VATNumber = "";
            formFields.PhoneNumber = "";
            formFields.Email = "";
            formFields.IdLanguage = "";

            if(this.state.selectedCompany && typeof(this.state.selectedCompany.IdCompany) !== "undefined"){
                formFields.Name = this.props.selectedCompany.Name;
                formFields.AutoPilot = this.props.selectedCompany.AutoPilot;
                formFields.ResponsableFirstName = this.props.selectedCompany.ResponsableFirstName;
                formFields.Address = this.props.selectedCompany.Address;
                formFields.VATNumber = this.props.selectedCompany.VATNumber;
                formFields.PhoneNumber = this.props.selectedCompany.PhoneNumber;
                formFields.Email = this.props.selectedCompany.Email;
                formFields.IdLanguage = this.props.selectedCompany.IdLanguage;
            }

            this.setState({formFields:formFields});
        }
    }


    render() {
        let titleStr = "Add Company";
        if(this.state.selectedCompany && typeof(this.state.selectedCompany.IdCompany) !== "undefined"){
            titleStr = "Edit Company";
        }

        let confirmButtonStr = "Add Company";
        if(this.state.selectedCompany && typeof(this.state.selectedCompany.IdCompany) !== "undefined"){
            confirmButtonStr = "Save";
        }

        return (
            <Modal
                show={this.props.show}
                onHide={this.props.onHide}
                dialogClassName="modal-90w"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton translate="yes">
                    <Modal.Title id="example-custom-modal-styling-title">
                        {titleStr}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={this.handleSubmit}>

                        <Form.Group controlId="formBasicName">
                            <Form.Label>*Name</Form.Label>
                            <Form.Control size="sm" type="text" placeholder="Type-in the company name"
                                          name="Name"
                                          value={this.state.formFields.Name}
                                          onChange={this.handleChange}
                            />
                        </Form.Group>

                        <Form.Group controlId="formAutoPilot">
                            <Form.Label>*AutoPilot (0 or 1)</Form.Label>
                            <Form.Control size="sm" type="text" placeholder="Type-in AutoPilot (0 or 1)"
                                          name="AutoPilot"
                                          value={this.state.formFields.AutoPilot}
                                          onChange={this.handleChange}
                            />
                        </Form.Group>


                        <Form.Group controlId="formResponsableFirstName">
                            <Form.Label>Responsable</Form.Label>
                            <Form.Control size="sm" type="text" placeholder="Type-in the responsable first name"
                                          name="ResponsableFirstName"
                                          value={this.state.formFields.ResponsableFirstName}
                                          onChange={this.handleChange}
                            />
                        </Form.Group>


                        <Form.Group controlId="formAddress">
                            <Form.Label>Address</Form.Label>
                            <Form.Control size="sm" type="text" placeholder="Type-in the company address"
                                          name="Address"
                                          value={this.state.formFields.Address}
                                          onChange={this.handleChange}
                            />
                        </Form.Group>

                        <Form.Group controlId="formVAT">
                            <Form.Label>
                                <Trans>
                                    *vat
                                </Trans>
                            </Form.Label>
                            <Form.Control size="sm" type="text" placeholder="Type-in the company VAT"
                                          name="VATNumber"
                                          value={this.state.formFields.VATNumber}
                                          onChange={this.handleChange}
                            />
                        </Form.Group>

                        <Form.Group controlId="formBasicPhoneNumber">
                            <Form.Label>PhoneNumber</Form.Label>
                            <Form.Control size="sm" type="text" placeholder="Type-in the company phonenumber"
                                          name="PhoneNumber"
                                          value={this.state.formFields.PhoneNumber}
                                          onChange={this.handleChange}
                            />
                        </Form.Group>

                        <Form.Group controlId="formBasicEmail">
                            <Form.Label>Email</Form.Label>
                            <Form.Control size="sm" type="text" placeholder="Type-in the company email"
                                          name="Email"
                                          value={this.state.formFields.Email}
                                          onChange={this.handleChange}
                            />
                        </Form.Group>


                        <Form.Group controlId="formBasicIdLanguage">
                            <Form.Label>IdLanguage</Form.Label>
                            <Form.Control size="sm" type="text" placeholder="Type-in the company language code"
                                          name="IdLanguage"
                                          value={this.state.formFields.IdLanguage}
                                          onChange={this.handleChange}
                            />
                        </Form.Group>


                        <br/>

                        <Button variant="primary" type="submit">
                            {confirmButtonStr}
                        </Button>
                    </Form>
                </Modal.Body>
                <br/>
            </Modal>
        );
    }

}

export default AdminModalAddNewCompany;