import React from 'react';
import { toast } from "react-toastify";

import ModalConfirmAction from '../_other/ModalConfirmAction';

import {Button,ButtonToolbar} from 'react-bootstrap'
import moment from "moment";

import { serverService } from '../../_services/server.service';
import i18n from "i18next";
import {Trans} from "react-i18next";


const $ = require('jquery');
$.DataTable = require('datatables.net');

require( 'datatables.net-bs4' );
require( 'datatables.net-select-bs4' );
require('../datatables.selected.css');



interface MyProps {
    voxes:any,
    onVoxDeleted():any,
}

interface MyState {
    modalConfirmDeleteShow: boolean,
    selectedVox: any,
}

class VoxesPaymentsTable extends React.Component<MyProps,MyState> {
    private textArea:any;

    constructor(props) {
        super(props);

        this.state = {
            modalConfirmDeleteShow:false
            ,selectedVox:{IdVox:0}
        };

        this.reloadTableData = this.reloadTableData.bind(this);
    }

    componentDidMount() {
        let table = $(this.refs.main).DataTable({
            //dom: '<"data-table-wrapper"<"toolbar"><"bottom"f>rtip>',
            dom: "<'data-table-wrapper'" +
                    "<'row'<'col-md-6 my-auto'<'toolbar'>><'col-md-6'>>" +
                    "<'row'<'col-sm-12'tr>>" +
                    "<'row'<'col-sm-12 col-md-5'><'col-sm-12 col-md-7'>>" +
                  ">",
            data: this.props.voxes,
            columns: [
                {
                    title: i18n.t("trans_33_active_vox"),
                    width: 80,
                    data: 'Name'
                },
                {
                    title: i18n.t("trans_33_Type"),
                    width: 80,
                    data: 'Type'
                },
                {
                    title: i18n.t("trans_33_settings_monthly_cost"),
                    width: 80,
                    data: 'MonthlyCost'
                },
                {
                    title: i18n.t("trans_33_payments_voxes_table_date"),
                    width: 80,
                    data: 'Added'
                }
            ],
            ordering: true,
            paging:true,
            select: {
                style: 'single',
                //blurable: true
            },
            language: {
                search: i18n.t("trans_33_search"),
                emptyTable:  i18n.t("trans_33_table_no_records"),
                paginate: {
                    first: i18n.t("trans_33_first"),
                    previous: i18n.t("trans_33_previous"),
                    next: i18n.t("trans_33_next"),
                    last: i18n.t("trans_33_last")
                }
            },
            columnDefs: [
                {
                    render: function ( data, type, row ) {
                        if(data != null){
                            return moment(data).format('D MMM YYYY HH:mm:ss');
                        } else {
                            return "";
                        }
                    },
                    targets: 3,
                },
                {
                    render: function ( data, type, row ) {
                        if(data != null){
                            let voxTypeStr = "N/A";
                            if(data === 1){
                                voxTypeStr = i18n.t("trans_33_Cardboard");
                            } else if(data === 2) {
                                voxTypeStr = i18n.t("trans_33_Slack");
                            } else if(data === 3) {
                                voxTypeStr = i18n.t("trans_33_Wooden");
                            } else if(data === 4) {
                                voxTypeStr = i18n.t("trans_33_teams_webhook");
                            } else if(data === 5) {
                                voxTypeStr = i18n.t("trans_33_teams_bot");
                            }
                            return voxTypeStr;
                        } else {
                            return "";
                        }
                    },
                    targets: 1,
                },
            ],
        });

        $("#deleteBtn").hide();
        $("#editBtn").hide();

        table.on( 'select', function ( e, dt, type, indexes ) {
            //var rowData = table.rows( indexes ).data().toArray();
            $("#deleteBtn").show();
            // $("#editBtn").show();
        });

        table.on( 'deselect', function ( e, dt, type, indexes ) {
            $("#deleteBtn").hide();
            $("#editBtn").hide();
        });

    }
    componentWillUnmount(){
        $('.data-table-wrapper')
            .find('table')
            .DataTable()
            .destroy(true);
    }

    componentDidUpdate(oldProps) {
        if(this.props.voxes !== oldProps.voxes){
            this.reloadTableData(this.props.voxes);
        }

    }


    render() {

        let modalConfirmDeleteOpen = (itemToEdit) => this.setState({ modalConfirmDeleteShow: true });
        let modalConfirmDeleteClose = () => this.setState({ modalConfirmDeleteShow: false });

        let deleteSelectedItemWithConfirmation = () => {
            modalConfirmDeleteOpen(null);
        };

        let deleteSelectedItem = () => {
            const table = $('.data-table-wrapper')
                .find('table')
                .DataTable();
            let data = table.rows( { selected: true }).data();
            let IdVox = data[0].IdVox;

            serverService.deleteVox(IdVox)
                .then(
                    result => {
                        if(result.status === "1"){
                            toast.success(i18n.t("trans_33_vox_table_msg_deleted"));
                            $("#deleteBtn").hide();
                            this.props.onVoxDeleted();
                        } else {
                            toast.error("Delete failed");
                        }

                        this.setState({ modalConfirmDeleteShow: false });
                    },
                    error => {
                        toast.warn("Did not delete vox");
                    }
                );
        };



        return (
            <div>

                <table ref="main" className="table table-bordered" />

                <div className="float-right">

                    <ButtonToolbar>

                        <Button
                            id="editBtn"
                            variant="light"
                        >
                            <Trans>trans_33_Edit</Trans>
                        </Button>
                        &nbsp;
                        <Button
                            id="deleteBtn"
                            variant="light"
                            onClick={deleteSelectedItemWithConfirmation}
                        >
                            <Trans>trans_33_Delete</Trans>
                        </Button>

                        &nbsp;


                        <ModalConfirmAction
                            show={this.state.modalConfirmDeleteShow}
                            actionTitle={i18n.t("trans_33_confirm_delete")}
                            actionBody={i18n.t("trans_33_vox_table_msg_confirm_delete_vox")}
                            noBtnText={i18n.t("trans_33_no_go_back")}
                            yesBtnText={i18n.t("trans_33_yes_confirm")}
                            onConfirmNo={modalConfirmDeleteClose}
                            onHide={modalConfirmDeleteClose}
                            onConfirmYes={deleteSelectedItem}
                        />

                    </ButtonToolbar>

                </div>

                <br/>
                <br/>
                <br/>
                <br/>
                <br/>

            </div>);
    }



    reloadTableData(items) {
        const table = $('.data-table-wrapper')
            .find('table')
            .DataTable();
        table.clear();
        table.rows.add(items);
        table.draw();

        $("#deleteBtn").hide();
        $("#editBtn").hide();
    }

}


export default VoxesPaymentsTable;
