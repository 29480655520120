import React from "react";

import { serverService } from "../../_services/server.service";
import { toast } from "react-toastify";
import { Row, Col } from "react-bootstrap";
import { RouteComponentProps } from "react-router-dom";
import TextField from "@material-ui/core/TextField";
import "./LoginPage.css";
import {Trans} from "react-i18next";
import i18n from "i18next";
import ModalForgotPassword from "../_modals/ModalForgotPassword";

let sha1 = require("sha1");

interface MyProps {}

interface MyState {
  submitted: boolean;
  loading: boolean;
  error: string;
  formFields: any;
  modalForgotPasswordShown:boolean;
}

class LoginPage extends React.Component<
  MyProps & RouteComponentProps,
  MyState
> {
  constructor(props) {
    super(props);

    localStorage.removeItem("user");

    this.state = {
      submitted: false,
      loading: false,
      error: "",
      formFields: {},
      modalForgotPasswordShown:false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.forgotPassword = this.forgotPassword.bind(this);
  }

  handleChange(e) {
    //console.log(e.target.value);
    const { name, value } = e.target;
    let formFields = this.state.formFields;
    formFields[name] = value;
    this.setState({ formFields: formFields });
  }

  handleSubmit(e) {
    e.preventDefault();

    this.setState({ submitted: true });

    const { formFields } = this.state;
    const username = formFields.username;
    const password = formFields.password;

    // stop here if form is invalid
    if (!(username && password)) {
      return;
    }

    this.setState({ loading: true });
    serverService.login(username, sha1(password)).then(
      (response) => {
        this.setState({ loading: false });
        if (response.status == 0) {
          toast.warn(response.message);
        } else {
          if (response.data == undefined || response.data.length == 0) {
            this.setState({ error: "Server errror" });
          } else {
            toast.success(response.message);

            let user = response.data[0];
            localStorage.setItem("user", JSON.stringify(user));

            // ToDo: this.props.location.state ||
            let { from } =  {
              from: { pathname: "/" },
            };

            if (user.FirstLogin == 1) {
              from = { pathname: "/home" };
            }
            this.props.history.push(from);
          }
        }
      },
      (error) => {
        console.log(error);
        toast.error("Server error");
        this.setState({ loading: false });
      }
    );
  }

  render() {
    const { formFields, submitted, loading, error } = this.state;
    const username = formFields.username;
    const password = formFields.password;

    let closeForgotPasswordModal = () => this.setState({ modalForgotPasswordShown: false });


    return (
      <div className="container-fluid p-0">
        <div className="row no-gutters">
          <div className="col-md-6">
            <section className="section-left">
              <img src="assets/images/logo.png" />
              <h2>
                <Trans>trans_33_login_page_title</Trans>
              </h2>
              <p>
                <Trans>trans_33_login_page_description</Trans>
              </p>
              <div className="flex-imgs">
                <img className="superman" src="/assets/rob.gif" />
                <img className="machine" src="/assets/machine.png" />
              </div>
            </section>
          </div>
          <div className="col-md-6">
            <div id="loginContainer" className="section-right">
              <h2 className="text-signin"><Trans>trans_33_login_page_welcome</Trans></h2>
              <br />
              <form
                name="form"
                className="text-center w-100"
                onSubmit={this.handleSubmit}
              >
                <div
                  className={
                    "form-group" + (submitted && !username ? " has-error" : "")
                  }
                >
                  {/* <input
                    type="text"
                    className="form-control login-field"
                    name="username"
                    value={username}
                    placeholder="Your login"
                    onChange={this.handleChange}
                  /> */}
                  <TextField
                    variant="outlined"
                    onChange={this.handleChange}
                    className="form-field card_fieldset"
                    name="username"
                    value={username}
                    placeholder={i18n.t("trans_33_login_page_your_login")}
                    type="text"
                    InputProps={{
                      style: { color: "#000" },
                    }}
                  />
                  {submitted && !username && (
                    <div className="help-block"><Trans>trans_33_login_page_msg_username_mandatory</Trans></div>
                  )}
                </div>
                <div
                  className={
                    "form-group" + (submitted && !password ? " has-error" : "")
                  }
                >
                  {/* <input
                    type="password"
                    className="form-control login-field"
                    name="password"
                    value={password}
                    placeholder="Password"
                    onChange={this.handleChange}
                  /> */}
                  <TextField
                    variant="outlined"
                    onChange={this.handleChange}
                    className="form-field card_fieldset"
                    name="password"
                    value={password}
                    placeholder={i18n.t("trans_33_Password")}
                    type="password"
                    InputProps={{
                      style: { color: "#000" },
                    }}
                  />
                  {submitted && !password && (
                    <div className="help-block"><Trans>trans_33_password_is_mandatory</Trans></div>
                  )}
                </div>
                <br />
                <Row>
                  <Col>
                    <div className="form-group">
                      <button
                        className="landing-button"
                        style={{ backgroundColor: "#0a1727", color: "#FFFFFF" }}
                        disabled={loading}
                      >
                        <Trans>trans_33_Login</Trans>
                      </button>
                      {loading && (
                        <img
                          alt="loading"
                          src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA=="
                        />
                      )}
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="text-center text-white">
                      <p>
                        <Trans>trans_33_login_page_forgot_password</Trans>{" "}
                        <a
                          href="#"
                          className="text-white"
                          onClick={this.forgotPassword}
                        >
                          <Trans>trans_33_click_here</Trans>
                        </a>
                      </p>
                    </div>
                  </Col>
                </Row>

                {error && <div className={"alert alert-danger"}>{error}</div>}
              </form>
            </div>
          </div>
        </div>


         <ModalForgotPassword
             show={this.state.modalForgotPasswordShown}
             onHide={closeForgotPasswordModal}
         />
      </div>
    );
  }

  forgotPassword() {
    this.setState({ modalForgotPasswordShown: true });
  }
}

export { LoginPage };
