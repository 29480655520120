import React from 'react';
import {Button,Modal,Form,Row,Col} from 'react-bootstrap'
import { toast } from "react-toastify";

import { serverService } from '../../../_services/server.service';

import CompaniesAutocomplete from "../CompaniesAutocomplete";

import sha1 from 'sha1' 

interface MyProps {
    onVoucherAdded(extra:string):any,
    show:boolean,
    onHide():any,
}

interface MyState {
    submitted: boolean,
    loading: boolean,
    error: string,
    formFields:any,
    selectedCompanyObject:any,
}

class AdminModalAddNewVoucher extends React.Component<MyProps,MyState> {

    constructor(props) {
        super(props);

        this.state = {
            submitted: false,
            loading: false,
            error: '',

            formFields:{},

            selectedCompanyObject:{}
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleCompanyChange = this.handleCompanyChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.onEntered = this.onEntered.bind(this);
    }  

    componentDidMount(){
    }

    onEntered(){
        var formFields = this.state.formFields;
        formFields.Code = generateRandomString(10);
        this.setState({ formFields: formFields })
    } 

    handleChange(e) {
        const { name, value } = e.target;
        let formFields = this.state.formFields;
        formFields[name] = value;
        this.setState({ formFields: formFields });
    }

    handleCompanyChange(companyString,companyIfExists) {
        let formFields = this.state.formFields;
        formFields.Company = companyString;
        this.setState({ formFields: formFields, selectedCompanyObject:companyIfExists });
    }


    handleSubmit(e) {
        e.preventDefault();

        this.setState({submitted: true});

        const { formFields,selectedCompanyObject } = this.state;
        const Code = formFields.Code;
        const Company = formFields.Company;

        // stop here if form is invalid
        if (!(Code)) {
            toast.error("Missing fields");
            return;
        }

        // 2022.01.09 - Remove the obligation to select a company
        // if(!selectedCompanyObject || !selectedCompanyObject.IdCompany){
        //     toast.error("You need to select an existing company");
        //     return;
        // }
   

        let voucher = {'IdVoucher': '0', 'Code':Code};
 
        if(selectedCompanyObject && selectedCompanyObject.IdCompany){
            voucher['IdCompany'] = selectedCompanyObject.IdCompany.toString();
        }
        
        serverService.insertVoucher(voucher)
            .then(
                result => {
                    if(result.status === "1"){
                        let messageStr = "Voucher added";
                        toast.success(messageStr);
                        this.props.onVoucherAdded(result.extra);
                    } else {
                        toast.error("Add voucher failed");
                    }
                },
                error => {
                    toast.warn("Did not insert voucher");
                }
            );

    }



    render() {
        let titleStr = "Add Voucher";
        let confirmButtonStr = "Add Voucher";

        return (
            <Modal
                show={this.props.show}
                onHide={this.props.onHide}
                dialogClassName="modal-90w"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                onEntered = {this.onEntered}
            > 
                <Modal.Header closeButton translate="yes">
                    <Modal.Title id="example-custom-modal-styling-title">
                        {titleStr}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={this.handleSubmit}>

                        <Form.Group controlId="formBasicCode">
                            <Form.Label>*Code</Form.Label>
                            <Form.Control size="sm" type="text" placeholder="Type-in the code"
                                          name="Code"
                                          value={this.state.formFields.Code}
                                          onChange={this.handleChange}
                            />
                        </Form.Group>

                        <Form.Group controlId="formBasicName">
                            <Form.Label>Company</Form.Label>
                            <CompaniesAutocomplete selectedCompany={this.state.formFields.Company}
                                                   handleCompanyChange={this.handleCompanyChange}
                            />
                        </Form.Group>

                        <br/>

                        <Button variant="primary" type="submit">
                            {confirmButtonStr}
                        </Button>
                    </Form>
                </Modal.Body>
                <br/>
            </Modal>
        );
    }
 
}

function generateRandomString(length) {
    let text = "";
    let possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

    for (let i = 0; i < length; i++)
        text += possible.charAt(Math.floor(Math.random() * possible.length));

    return text;
} 
 
export default AdminModalAddNewVoucher;