import React from 'react';

import { serverService } from '../../_services/server.service';
import { toast } from 'react-toastify';
import { Row, Col, Form, Button } from 'react-bootstrap';
import { RouteComponentProps, Link } from 'react-router-dom';

import './RegistrationPage.css';

import { config } from '../../_helpers/C';

import { Trans } from 'react-i18next';
import i18n from 'i18next';
import TextField from '@material-ui/core/TextField';

import sha1 from 'sha1' 
const qs = require('query-string');


const showPasswordSVG = (
<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
<g clip-path="url(#clip0_1_11693)">
<path d="M16.7934 13.6C16.9849 13.0884 17.082 12.5463 17.0801 12C17.0801 10.7871 16.5983 9.62386 15.7406 8.76619C14.8829 7.90853 13.7197 7.4267 12.5068 7.4267C11.9672 7.42733 11.4321 7.52441 10.9268 7.71336L12.0001 8.82003C12.1633 8.79393 12.3282 8.78055 12.4934 8.78003C13.351 8.77824 14.1745 9.11586 14.7841 9.71916C15.3936 10.3225 15.7397 11.1424 15.7468 12C15.7462 12.1653 15.7328 12.3302 15.7068 12.4934L16.7934 13.6Z" fill="black"/>
<path d="M22.8601 11.6867C20.6134 7.53335 16.6734 5.02002 12.3134 5.02002C11.1263 5.02281 9.94718 5.21408 8.82007 5.58669L9.8934 6.66669C10.684 6.46237 11.4969 6.35712 12.3134 6.35335C16.0667 6.35335 19.4801 8.44669 21.5134 11.9734C20.7675 13.2818 19.7787 14.4357 18.6001 15.3734L19.5467 16.32C20.9108 15.2196 22.0436 13.8601 22.8801 12.32L23.0534 12L22.8601 11.6867Z" fill="black"/>
<path d="M3.24667 3.85332L6.22 6.82665C4.34062 8.03673 2.8046 9.7105 1.76 11.6867L1.58667 12L1.76 12.32C4.00667 16.4733 7.94667 18.9867 12.3067 18.9867C14.0084 18.9863 15.6881 18.6011 17.22 17.86L20.5533 21.1933L21.72 20.1933L4.38667 2.85999L3.24667 3.85332ZM9.74667 10.3533L14.18 14.7867C13.6791 15.0967 13.1024 15.2628 12.5133 15.2667C12.0853 15.2667 11.6615 15.182 11.2663 15.0176C10.871 14.8532 10.5122 14.6123 10.2105 14.3087C9.90875 14.0051 9.67002 13.6448 9.50804 13.2486C9.34606 12.8524 9.26403 12.428 9.26667 12C9.27418 11.4177 9.44012 10.8484 9.74667 10.3533ZM8.78 9.38665C8.15629 10.2663 7.86395 11.3382 7.95475 12.4126C8.04554 13.4871 8.51361 14.4948 9.27607 15.2572C10.0385 16.0197 11.0462 16.4878 12.1207 16.5786C13.1951 16.6694 14.2671 16.377 15.1467 15.7533L16.2133 16.82C14.9785 17.3478 13.6496 17.62 12.3067 17.62C8.55333 17.62 5.14 15.5267 3.10667 12C4.08247 10.2721 5.48782 8.82545 7.18667 7.79999L8.78 9.38665Z" fill="black"/>
</g>
<defs>
<clipPath id="clip0_1_11693">
<rect width="24" height="24" fill="white"/>
</clipPath>
</defs>
</svg>
)

interface MyProps {
  elements: any;
  handleResult: any;
}

interface MyState {
  submitted: boolean;
  showPassword: boolean;
  loading: boolean;
  firstStep: boolean;
  error: string;
  formFields: any;
  has_Email: any;
  the_props: any;
}

class RegistrationPage extends React.Component<
  MyProps & RouteComponentProps,
  MyState
> {
  constructor(props) {
    super(props);

    let _Email = qs.parse(this.props.location.search, {
      ignoreQueryPrefix: true,
    })._email;
    let has_Email = qs.parse(this.props.location.search, {
      ignoreQueryPrefix: true,
    }).has_email;

    if (!_Email) {
      _Email = '';
    }
    if (has_Email == undefined || has_Email == '') {
      has_Email = false;
    }
    else { 
      has_Email = true; 
    }

    this.state = {
      submitted: false,
      the_props: this.props.history.location,
      loading: false,
      firstStep: true,
      error: '',
      formFields: { Username: '', Password: '', Email: _Email, EmailCode:'' },
      showPassword: false,
      has_Email: has_Email,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSendCode = this.handleSendCode.bind(this);
    this.handleShowPassword = this.handleShowPassword.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.hideFirstStep = this.hideFirstStep.bind(this);
    
    this.handleCheckboxChange = this.handleCheckboxChange.bind(this);

    
  }

  handleSubmit(evt) {
    evt.preventDefault();

    let formFields = this.state.formFields;

    let format = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
    if (
      formFields.Password.length < 10 ||
      formFields.Password.toLowerCase() == formFields.Password ||
      !format.test(formFields.Password)
    ) {
      toast.error(i18n.t('trans_33_msg_password_not_valid'));
      return;
    }
    formFields['EncPassword'] = sha1(formFields.Password);
    this.setState({ loading: true });

    formFields.PaymentMethodId = '0';
    formFields.licenseType = 1;
    formFields.virtualVoxNum = 0;
    formFields.physicalVoxNum = 1;
    formFields.acceptTosPolicy = true;
    formFields.code = this.state.formFields.EmailCode;
    
    //aqui
    function createEmailCode(input) {
      return "1234";
      let output = '';
      for (let i = 0; i < input.length; i++) {
          output += input[i].charCodeAt(0);
      }
      return output.substr(output.length - 6);
      }
//this.setState({ formFields: {code: createEmailCode(formFields.Email)} })

      if(formFields.code !== createEmailCode(formFields.Email)) {
        toast.error(i18n.t('trans_33_msg_code_not_valid'));
        return;
      }

    let newFormFields = Object.assign({}, formFields);


    //return serverService.registerUser(newFormFields);
    serverService.registerUser_v2(newFormFields).then(
      (response) => {
        if (response != undefined) {
          if (response.status == 1 || response.status == '1') {
            this.setState({
              submitted: false,
              loading: false,
              formFields: { Username: '', Password: '', Email: '' },
            });

            if (response.data == undefined || response.data.length == 0) {
              this.setState({ error: i18n.t('trans_33_msg_server_error') });
            } else {
              //aqui
              toast.success(i18n.t('trans_33_msg_registration_completed'));

              let user = response.data[0];
              localStorage.setItem('user', JSON.stringify(user));

              if((this.state.the_props.state)){
                let cardinfo =  this.state.the_props.state.the_props.cardinfo;
                let isMonthly = this.state.the_props.state.the_props.isMonthly;
                let index =     this.state.the_props.state.the_props.index;
              this.props.history.push({pathname:"/payment-page", state: { cardinfo, isMonthly, index },  });
              }
              else {
              setTimeout(function () {
                window.location.href = '/home';
              }, 3000);
            }
              

            }
          } else {
            toast.warn(response.message);
            this.setState({ submitted: false, loading: false });
          }
        }
      },
      (error) => {
        this.setState({ submitted: false, loading: false });
        console.log(error);
      }
    );



    //this.props.history.push("/plans-page");

  }

  handleChange(e) {
    const { name, value } = e.target;
    let formFields = this.state.formFields;
    formFields[name] = value;
    this.setState({ formFields: formFields });
  }
  handleSendCode(e) {
    if(this.state.formFields.Email !== ''){
    toast.success('Code has been sent to your e-mail: '+this.state.formFields.Email);
    }
    else {
      toast.error('Please enter your e-mail');
    }
  }
  handleShowPassword() {
    this.setState({ showPassword: !this.state.showPassword });
  }
   hideFirstStep() {

    this.props.history.push("/plans-page");

  }

  render() {
    const { formFields, submitted, loading, error, firstStep } = this.state;
    // const test = (this.state.the_props.state.hasOwnProperty('cardinfo')) ? this.state.the_props.state.the_props.cardinfo : 'nao';
    const Password = formFields.Password;
    const Email = formFields.Email;
    const EmailCode = formFields.EmailCode;

    return (
      <>
        <div className='split left'>
          <div className='centered'>
            <img src='assets/images/logo.png' alt='Avatar woman' />
            <br />
            <br />
            <br />
            <br />

          </div>
        </div>

  
{/* init first step */}
        <div className='split right n_vox limit_input' style={{display: this.state.firstStep ? '': 'none'}}>
          <div className='container centered'>
            <div
              className='col-md-8 col-md-offset-3 text-center mx-auto customContainer'
              id='loginContainer'
            >
              <div className="step_one limited">
              <div className='text-end-register'><span><Trans>trans_33_start_your_subscription_already_sign_in_text</Trans></span><Link to="home"> <Trans>trans_33_start_your_subscription_sign_in_text</Trans> </Link> </div>
              <div className="center_on_mobile">
              <img
                src='assets/images/logo.png'
                className='logo-right'
                alt='Avatar woman'
              />

              <h4 className='register-text mt-4'>
                <Trans>trans_33_start_your_subscription</Trans>
              </h4>
              <p className='sub_text'>
                <Trans>trans_33_start_your_subscription_sub_text</Trans>
              </p>
              </div>
              <br />
              <form name='form' onSubmit={this.handleSubmit}>
              <div style={{display: this.state.has_Email ? 'none': ''}} className={ 'form-group' + (submitted && !Email ? ' has-error' : '')}>
                <TextField
                  variant='outlined'
                  onChange={this.handleChange}
                  className='form-field card_fieldset'
                  label={i18n.t('trans_33_enter_your_email')}
                  type='email'
                  name='Email'
                  value={Email}
                  placeholder={i18n.t('trans_33_enter_your_email')}
                  InputProps={{
                    style: { color: '#000' },
                  }}
                />
                {submitted && !Email && (
                  <div className='help-block'>
                    <Trans>trans_33_email_is_mandatory</Trans>
                  </div>
                )}
              </div>

              <div className={ 'form-group' }>
              <div>
                <TextField
                  variant='outlined'
                  onChange={this.handleChange}
                  className='form-field card_fieldset'
                  label={i18n.t('Input your email verification code')}
                  name='EmailCode'
                  value={EmailCode}
                  placeholder={i18n.t('trans_33_password')}
                  type={'name'}
                  InputProps={{
                    style: { color: '#000' },
                  }}
                />
                <button type="button" className='buttonShowPassword' style={{position: 'absolute', marginLeft: -100, border: '1px solid #afafaf', borderRadius: 4}} 
                onClick={this.handleSendCode}>Send code</button>
                </div>
                </div>

              <div className={ 'form-group' + (submitted && !Password ? ' has-error' : '') }>
                <div>
                <TextField
                  variant='outlined'
                  onChange={this.handleChange}
                  className='form-field card_fieldset'
                  label={i18n.t('trans_33_password')}
                  name='Password'
                  value={Password}
                  placeholder={i18n.t('trans_33_password')}
                  type={this.state.showPassword? 'name':'password'}
                  InputProps={{
                    style: { color: '#000' },
                  }}
                />
                <a href="#" className='buttonShowPassword' onClick={this.handleShowPassword}>{showPasswordSVG}</a>
                </div>
                {submitted && !Password && (
                  <div className='help-block'>
                    <Trans>trans_33_password_is_mandatory</Trans>
                  </div>
                )}
                    <div className="helper-text">
                    <div className="flex_basis_50">
                    <li><Trans>trans_33_password_requirements1</Trans></li>       
                    <li><Trans>trans_33_password_requirements2</Trans></li>   
                    </div>    
                    <div className="flex_basis_50">
                    <li><Trans>trans_33_password_requirements3</Trans></li>       
                    <li><Trans>trans_33_password_requirements4</Trans></li>      
                    </div>
                    </div>
              </div>
              
              <p className="small_gray_helper"><Trans>trans_33_by_creating</Trans>
              <a href="https://www.voxcollector.com/pdf/TOS.pdf" style={{color: '#35BFF4', textDecoration: 'underline'}}><Trans>trans_33_by_creating_terms</Trans></a> 
              <Trans>trans_33_by_creating_and</Trans> 
              <a href="https://www.voxcollector.com/pdf/privacy.pdf" style={{color: '#35BFF4', textDecoration: 'underline'}}><Trans>trans_33_by_creating_privacy_policy</Trans></a></p>
             
              <button type="submit" className="lightblue_button next_step"><Trans>trans_33_by_creating_continue</Trans></button>
              </form>
              </div>
              {/* end first step */}
              <br />
              {error && <div className={'alert alert-danger'}>{error}</div>}
            </div>
          </div>
        </div>
      </>
    );
  }

  handleCheckboxChange(e) {
    const { name, checked } = e.target;
    let formFields = this.state.formFields;
    formFields[name] = checked;
    this.setState({ formFields: formFields });
  }

  handleResult() {
    toast('Handle result');
  }
}

function validateEmail(email) {
  let re =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

export { RegistrationPage };
