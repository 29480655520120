/**
 * Created by Alex on 22-Mar-19.
 */
import React from 'react';
import {Button,Modal,Form,Row,Col} from 'react-bootstrap'
import { toast } from "react-toastify";

import  {
    formatDate,
    parseDate,
} from 'react-day-picker/build/addons/MomentLocaleUtils';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';


import {Trans} from "react-i18next";
import i18n from "i18next";

import {serverService} from "../../../_services/server.service";
const moment = require("moment");


interface MyProps {
    onConfirmCreateQuestionPack(StartDate:string): void,
    show:boolean,
    onHide(): any,
    descriptionText: string,
}

interface MyState {
    formFields:any,
    loading:boolean,
    lastScheduledQuestionDate: string,
}

class ModalConfirmCreateQuestionPack extends React.Component<MyProps,MyState> {

    constructor(props) {
        super(props);
        this.state = {
            formFields:{},
            loading:false,
            lastScheduledQuestionDate: "",
        };

        this.handleDateChange = this.handleDateChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.onEntered = this.onEntered.bind(this);
        this.onConfirmCreateQuestionPack = this.onConfirmCreateQuestionPack.bind(this);

    }

    render() {
        return (
            <Modal
                show={this.props.show}
                onHide={this.props.onHide}
                onEntered = {this.onEntered}
                dialogClassName="modal-90w"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                backdrop="static"
            >
                <Modal.Header translate="yes">
                    <Modal.Title id="example-custom-modal-styling-title">
                        <Trans>trans_33_campaign_standard_confirm_create_question_pack</Trans>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <div className="modal_content" dangerouslySetInnerHTML={{__html: this.props.descriptionText}}/>
                    <br/>

                    {this.state.lastScheduledQuestionDate &&
                        <div>
                            <Trans>trans_33_confirm_question_pack_last_question_date</Trans> <strong>{this.state.lastScheduledQuestionDate}</strong>
                        </div>
                    }

                    <br/>
                    <Form onSubmit={this.handleSubmit}>
                        <Form.Group controlId="formBasicName">
                            <Trans>
                                trans_33_modal_schedule_pack_when_to_start
                            </Trans>
                            <br/>
                            <div className="InputStartDate">
                                <DayPickerInput
                                    value={this.state.formFields.StartDate}
                                    placeholder={i18n.t('trans_33_choose_date')}
                                    formatDate={formatDate}
                                    parseDate={parseDate}
                                    format="DD/MM/YYYY"
                                    onDayChange={this.handleDateChange}
                                    dayPickerProps={{
                                        disabledDays: {before: new Date(),},
                                        firstDayOfWeek:1,
                                    }}
                                />
                            </div>
                        </Form.Group>
                    </Form>
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="secondary" onClick={this.props.onHide}>
                       <Trans>trans_33_Cancel</Trans>
                    </Button>

                    <Button variant="primary" className="btn btn-primary" onClick={this.onConfirmCreateQuestionPack}>
                        <Trans>trans_33_campaign_standard_confirm_question_pack_creation</Trans>
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }

    onEntered(){
        let userObj = JSON.parse(localStorage.getItem("user"));
        let getCompanyPromise = serverService.getCompany(userObj.IdCompany);
        let getLastScheduledQuestionPromise = serverService.getLastScheduledQuestion();

        Promise.all([getCompanyPromise,getLastScheduledQuestionPromise])
            .then(result => {
                let resultCompany = result[0];
                let resultLastScheduledQuestion = result[1];

                if(resultLastScheduledQuestion.status === "1"){
                    if(resultLastScheduledQuestion.data && resultLastScheduledQuestion.data.length > 0){
                        let LastScheduledDate = moment(resultLastScheduledQuestion.data[0]['LastScheduledDate']).format('DD/MM/YYYY');
                        this.setState({lastScheduledQuestionDate:LastScheduledDate});

                        let StartDate = moment(LastScheduledDate,'DD/MM/YYYY').add(1, 'days');
                        let ToDate = moment(StartDate).add(7, 'days');

                        const selectedDays = resultCompany.data[0].SelectedDays;

                        for (let m = moment(StartDate); m.isBefore(ToDate); m.add(1, 'days')) {
                            const dateFormat = m.format('DD/MM/YYYY');
                            const dayOfWeek = m.format('dddd');

                            if(selectedDays && selectedDays.toLowerCase().includes(dayOfWeek.toLowerCase())){
                                this.handleDateChange(dateFormat);
                                break;
                            }
                        }

                    }
                }
            }, error => {
                toast.warn("Did not get last scheduled question");
            })
    }

    handleDateChange(from) {
        let formFields = this.state.formFields;
        formFields["StartDate"] = from;
        this.setState({ formFields: formFields });
    }

    handleSubmit(e) {
        e.preventDefault();

        const {formFields} = this.state;
        const StartDate = formFields.StartDate;

        // stop here if form is invalid
        if (!(StartDate)) {
            toast.error(i18n.t("trans_33_modal_onboarding_gift_pack_msg_select_date"));
            return;
        }

        let userStr = localStorage.getItem('user');
        let IdCompany = 0;
        if (userStr) {
            let userObj = JSON.parse(userStr);
            IdCompany = userObj.IdCompany;
        }
    }

    onConfirmCreateQuestionPack(){
        let StartDate = this.state.formFields["StartDate"];
        this.props.onConfirmCreateQuestionPack(StartDate);
    }

}

export default ModalConfirmCreateQuestionPack;