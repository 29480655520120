/**
 * Created by Alex on 22-Mar-19.
 */
import React from 'react';
import {Button,Modal,Form,Row,Col} from 'react-bootstrap'
import { toast } from "react-toastify";

import { serverService } from '../../_services/server.service';

import {StripeProvider, Elements, CardElement,
    CardNumberElement,CardExpiryElement,CardCVCElement,injectStripe} from 'react-stripe-elements';

import './ModalPaymentInfo.css'
import i18n from "i18next";
import {Trans} from "react-i18next";

const createOptions = () => {
    return {
        style: {
            base: {
                fontSize: '16px',
                color: '#424770',
                letterSpacing: '0.025em',
                '::placeholder': {
                    color: '#aab7c4',
                },
            },
            invalid: {
                color: '#c23d4b',
            },
        },
    };
};

interface MyProps {
    stripe:any,
    elements:any,
    handleResult:any,
}

interface MyState {
    loading: boolean,
    error: string,
    formFields:any,
    clientSecret:string,
}
class _SplitFieldsForm extends React.Component<MyProps,MyState> {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            error: '',
            formFields:{},
            clientSecret:'',
        };

        this.handleStripeFieldsChange = this.handleStripeFieldsChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);

        this.handleChange = this.handleChange.bind(this);
    }

    handleStripeFieldsChange = ({error}) => {
        if (error) {
            this.setState({error: error.message});
        } else {
            this.setState({error: ''});
        }
    };


    handleChange(e) {
        const { name, value } = e.target;
        let formFields = this.state.formFields;
        formFields[name] = value;
        this.setState({ formFields: formFields });
    }

    handleSubmit = (evt) => {
        evt.preventDefault();
        if (this.props.stripe) {
            // this.props.stripe.createToken().then(val => {
            //     console.log(val);
            // });
            const cardElement = this.props.elements.getElement('cardNumber');

            let CardHolder = this.state.formFields.CardHolder;
            if(CardHolder === undefined){
                CardHolder = "";
            }
            if(CardHolder === undefined || cardElement == undefined || cardElement == null){
                toast.error(i18n.t("trans_33_missing_fields"));
                return;
            }

            this.setState({loading:true,});


            this.props.stripe.confirmCardSetup(
                this.state.clientSecret
                ,{
                    payment_method: {
                        card: cardElement,
                    },
                }
                ).then(result => {

                    this.setState({loading:false,});

                    if(result.error !== undefined){
                        this.setState({error:result.error.message,});
                        return;
                    }
                   

                    serverService.paymentsSavePaymentMethod(result.setupIntent.payment_method, CardHolder).then(
                   
                        result => {
                            
                            if(result.status !== "1"){
                                toast.error("Issues while communicating with the server");
                            } else {
                                this.props.handleResult();
                            }
                        },
                        error => {
                            toast.warn("Did not get save payment method");
                        }
                    );

            });
        } else {
            console.log("Stripe.js hasn't loaded yet.");
        }
    };

    componentDidMount(){
        serverService.paymentsGetClientSecret().then(
            result => {
                if(result.status !== "1"){
                    toast.error("Issues while accessing client wallet");
                } else {
                    this.setState({clientSecret: result.data});
                }
            },
            error => {
                toast.warn("Did not access client wallet");
            }
        );
    }

    render() {
        return (
            <form onSubmit={this.handleSubmit}>
                <div className="split-form">
                    <label>
                        <Trans>trans_33_modal_payment_card_number</Trans>
                        <CardNumberElement
                            id="cardNumber"
                            {...createOptions()}
                            onChange={this.handleStripeFieldsChange}
                        />
                    </label>
                    <label>
                        <Trans>trans_33_modal_payment_expiration_date</Trans>
                        <CardExpiryElement
                            {...createOptions()}
                            onChange={this.handleStripeFieldsChange}
                        />
                    </label>
                </div>
                <div className="split-form">
                    <label>
                        <Trans>trans_33_CVC</Trans>
                        <CardCVCElement {...createOptions()} onChange={this.handleStripeFieldsChange} />
                    </label>

                    {/*<Form.Group controlId="formCompanyName">*/}
                        {/*<Form.Label>Card holder</Form.Label>*/}
                        {/*<Form.Control size="sm" type="text" placeholder="Type-in the card holder"*/}
                                      {/*name="CardHolder"*/}
                                      {/*value={this.state.formFields.CardHolder}*/}
                                      {/*onChange={this.handleChange}*/}
                        {/*/>*/}
                    {/*</Form.Group>*/}

                </div>

                <div className="error" role="alert">
                    {this.state.error}
                </div>

                <p style={{fontSize:'11px'}}><Trans>trans_33_modal_payment_authorize</Trans></p>

                <Button variant="primary" disabled={this.state.loading} type="submit"><Trans>trans_33_save</Trans></Button>
                {this.state.loading &&
                <img alt="loading" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                }
            </form>
        );
    }
}

const SplitFieldsForm = injectStripe(_SplitFieldsForm);

interface MyPropsModal {
    show:boolean,
    onHide():any,
}

interface MyStateModal {
    submitted: boolean,
    loading: boolean,
    error: string,
    formFields:any,
}
class ModalPaymentInfo extends React.Component<MyPropsModal,MyStateModal> {

    constructor(props) {
        super(props);

        this.state = {
            submitted: false,
            loading: false,
            error: '',
            formFields:{},
        };
    }

    render() {
        return (
            <Modal
                show={this.props.show}
                onHide={this.props.onHide}
                dialogClassName="modal-90w"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton translate="yes">
                    <Modal.Title id="example-custom-modal-styling-title">
                        <Trans>trans_33_modal_payment_change_payment_info</Trans>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                        <StripeProvider apiKey="pk_test_CDdrEYrRErlE9vhzu1yV24dX00ly9rvxxW">
                            <Elements>
                                <SplitFieldsForm handleResult={this.handleResult} />
                            </Elements>
                        </StripeProvider>
                </Modal.Body>
                <br/>
            </Modal>
        );
    }

    handleResult(){
        toast("Handle result");
    }



}

export default ModalPaymentInfo;