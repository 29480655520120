import React from 'react';

import { serverService } from '../../../_services/server.service';

import QuestionsTable from './QuestionsTable';

import './CampaignQuestionsPage.css'
import {toast} from "react-toastify";

interface MyProps {

}

interface MyState {
    questions: any,
}

class CampaignQuestionsPage extends React.Component<MyProps,MyState> {

    constructor(props) {
        super(props);

        this.state = {
            questions: [],
        };

        this.onQuestionAdded = this.onQuestionAdded.bind(this);
        this.onQuestionDeleted = this.onQuestionDeleted.bind(this);
        this.onQuestionsImported = this.onQuestionsImported.bind(this);
    }

    componentDidMount() {
        this.setState({
            questions: []
        });

        this.doFetchAllQuestions();
    }

    render() {
        return (
            <QuestionsTable questions={this.state.questions}
                            onQuestionsImported={this.onQuestionsImported}
                            onQuestionAdded={this.onQuestionAdded}
                            onQuestionDeleted={this.onQuestionDeleted}
            />
        );
    }

    doFetchAllQuestions(){
        const userStr = localStorage.getItem('user');
        let IdCompany = 0;
        if(userStr) {
            const userObj = JSON.parse(userStr);
            IdCompany = userObj.IdCompany;
        }

        serverService.getAllQuestionsForTableDisplay().then(
            questionsRes => {
                this.setState({questions: questionsRes.data})
            },
            error => {
                toast.warn("Did not get questions");
            }
        );
    }

    onQuestionAdded(){
        this.doFetchAllQuestions();
    }

    onQuestionDeleted(){
        this.doFetchAllQuestions();
    }

    onQuestionsImported(){
        this.doFetchAllQuestions();
    }


}

export default CampaignQuestionsPage;