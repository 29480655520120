import React from 'react';
import { Form } from 'react-bootstrap';
import { toast } from 'react-toastify';

import { serverService } from '../../_services/server.service';
import { emphasize, Slider } from '@material-ui/core';
import withStyles from '@material-ui/core/styles/withStyles';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { config } from '../../_helpers/C';
import { Trans } from 'react-i18next';
import i18n from 'i18next';

let BulletObject = [];

interface MyProps {
  onLicenseSelected(
    licenseType: number,
    virtualVoxNum: number,
    physicalVoxNum: number
  ): any;
  licenseType?: number;
  virtualVoxNum?: number;
  physicalVoxNum?: number;
  theme: string;
}

interface MyState {
  step: number;
  licenseType: number;
  virtualVoxNum: number;
  physicalVoxNum: number;
  theme: string;
}

const LicenseSliderLight = withStyles({
  root: {
    color: '#FFF',
    height: 8,
    width: 300,
  },
  thumb: {
    height: 24,
    width: 24,
    backgroundColor: '#FFF',
    border: '1px solid currentColor',
    marginTop: -8,
    marginLeft: -12,
    '&:focus,&:hover,&$active': {
      boxShadow: 'inherit',
    },
  },
  active: {},
  valueLabel: {
    left: 'calc(-50% + 4px)',
  },
  track: {
    height: 8,
    borderRadius: 4,
  },
  rail: {
    height: 8,
    borderRadius: 4,
  },
})(Slider);

const LicenseSliderDark = withStyles({
  root: {
    color: '#555',
    height: 8,
    width: 300,
  },
  thumb: {
    height: 24,
    width: 24,
    backgroundColor: '#FFF',
    border: '1px solid currentColor',
    marginTop: -8,
    marginLeft: -12,
    '&:focus,&:hover,&$active': {
      boxShadow: 'inherit',
    },
  },
  active: {},
  valueLabel: {
    left: 'calc(-50% + 4px)',
  },
  track: {
    height: 8,
    borderRadius: 4,
  },
  rail: {
    height: 8,
    borderRadius: 4,
  },
})(Slider);

let licenseSliderMarks;

class LicenseSelect extends React.Component<MyProps, MyState> {
  constructor(props) {
    super(props);

    this.state = {
      step: 1,
      licenseType: this.props.licenseType,
      virtualVoxNum: Number(this.props.virtualVoxNum),
      physicalVoxNum: Number(this.props.physicalVoxNum),
      theme: this.props.theme,
    };

    licenseSliderMarks = [
      {
        value: 1,
        label: i18n.t('trans_33_license_self_starter'),
      },
      {
        value: 2,
        label: i18n.t('trans_33_license_standard'),
      },
      {
        value: 3,
        label: i18n.t('trans_33_license_enterprise'),
      },
    ];

    BulletObject = [
      [
        { list: i18n.t("trans_33_register_benefits_ss1") },
        { list: i18n.t("trans_33_register_benefits_ss2") },
        { list: i18n.t("trans_33_register_benefits_ss3") },
        { list: i18n.t("trans_33_register_benefits_ss4") },
      ],
      [
        { title: i18n.t("trans_33_register_title_standard") },
        { list: i18n.t("trans_33_register_benefits_standard1") },
        { list: i18n.t("trans_33_register_benefits_standard2") },
        { list: i18n.t("trans_33_register_benefits_standard3") },
        { list: i18n.t("trans_33_register_benefits_standard4") },
      ],
      [
        { title: i18n.t("trans_33_register_title_enterprise") },
        { list: i18n.t("trans_33_register_benefits_enterprise1") },
        { list: i18n.t("trans_33_register_benefits_enterprise2") },
        {
          list: i18n.t("trans_33_register_benefits_enterprise3"),
        },
        { list: i18n.t("trans_33_register_benefits_enterprise4") },
        {
          list: i18n.t("trans_33_register_benefits_enterprise5"),
        },
      ],
    ];

    this.onLicenseSliderChanged = this.onLicenseSliderChanged.bind(this);

    this.handleVirtualChange = this.handleVirtualChange.bind(this);
    this.handleAddVirtual = this.handleAddVirtual.bind(this);
    this.handleSubtractVirtual = this.handleSubtractVirtual.bind(this);

    this.handlePhysicalChange = this.handlePhysicalChange.bind(this);
    this.handleAddPhysical = this.handleAddPhysical.bind(this);
    this.handleSubtractPhysical = this.handleSubtractPhysical.bind(this);
  }

  render() {
    const { licenseType, virtualVoxNum, physicalVoxNum } = this.state;
    const bulletList = BulletObject[licenseType - 1];

    let mothlyCost = 0;
    let setupCost = 0;

    if (config.PRICE_LICENSES[licenseType]) {
      mothlyCost += config.PRICE_LICENSES[licenseType];
    }

    let totalNumberOfVoxes = virtualVoxNum + physicalVoxNum;
    if (totalNumberOfVoxes > 0) {
      mothlyCost += config.PRICE_EXTRA_VOX_MONTHLY * (totalNumberOfVoxes - 1);
    }

    if (physicalVoxNum > 0) {
      setupCost = physicalVoxNum * config.PRICE_PHYSICAL_VOX_SETUP;
    }

    return (
      <div>
        {this.state.theme === 'dark' && (
          <LicenseSliderDark
            min={1}
            max={3}
            step={1}
            marks={licenseSliderMarks}
            aria-label='license slider'
            value={licenseType}
            onChangeCommitted={this.onLicenseSliderChanged}
          />
        )}

        {this.state.theme === 'light' && (
          <LicenseSliderLight
            min={1}
            max={3}
            step={1}
            marks={licenseSliderMarks}
            aria-label='license slider'
            value={licenseType}
            onChangeCommitted={this.onLicenseSliderChanged}
          />
        )}
        <ul className='bulletlist-register'>
          {bulletList.map((item, index) => (
            <div key={index}>
              <h1 className='bulletlist-title'>{item?.title}</h1>
              {item.list && <li>{item.list}</li>}
            </div>
          ))}
        </ul>
        <div className='container'>
          <div className='row'>
            <div className='col-md-6'>
              <p>
                <Trans>trans_33_license_component_virtual_vox</Trans>
              </p>
              <div>
                <input
                  value={virtualVoxNum}
                  onChange={this.handleVirtualChange}
                  style={{ width: '30px' }}
                />
                <button
                  className='actionButton'
                  onClick={this.handleAddVirtual}
                >
                  <AddIcon />
                </button>
                <button
                  className='actionButton'
                  onClick={this.handleSubtractVirtual}
                >
                  <RemoveIcon />
                </button>
              </div>
            </div>

            <div className='col-md-6'>
              <p>
                <Trans>trans_33_license_component_physical_vox</Trans>
              </p>

              <a href='mailto:support@voxcollector.com'>
                <Trans>trans_33_register_physical_vox_mail_to_support</Trans>
              </a>
              {/*<div>*/}
              {/*<input value={physicalVoxNum} onChange={this.handlePhysicalChange} style={{width:'30px'}}  />*/}
              {/*<button className="actionButton" onClick={this.handleAddPhysical}><AddIcon  /></button>*/}
              {/*<button className="actionButton" onClick={this.handleSubtractPhysical} ><RemoveIcon  /></button>*/}
              {/*</div>*/}
            </div>
          </div>
        </div>

        <br />
        <Trans i18nKey='trans_33_license_component_monthly_cost'>
          text<strong>{{ mothlyCost }}</strong>
          <strong>{{ setupCost }}</strong>
        </Trans>
      </div>
    );
  }

  onLicenseSliderChanged(event, newValue) {
    this.setState({ licenseType: newValue });

    let { licenseType, virtualVoxNum, physicalVoxNum } = this.state;
    this.props.onLicenseSelected(licenseType, virtualVoxNum, physicalVoxNum);
  }

  handleVirtualChange(event) {
    let virtualVoxNum = event.target.value;
    let { licenseType, physicalVoxNum } = this.state;

    if (virtualVoxNum == 0 && physicalVoxNum == 0) {
      virtualVoxNum = 1;
      // physicalVoxNum = 1;
      this.setState({
        virtualVoxNum: Number(virtualVoxNum),
        physicalVoxNum: Number(physicalVoxNum),
      });
    } else if (virtualVoxNum >= 0) {
      this.setState({ virtualVoxNum: Number(virtualVoxNum) });
    }

    this.props.onLicenseSelected(licenseType, virtualVoxNum, physicalVoxNum);
  }

  handleAddVirtual() {
    let { licenseType, virtualVoxNum, physicalVoxNum } = this.state;

    virtualVoxNum = virtualVoxNum + 1;

    if (virtualVoxNum == 0 && physicalVoxNum == 0) {
      physicalVoxNum = 1;
      this.setState({
        virtualVoxNum: Number(virtualVoxNum),
        physicalVoxNum: Number(physicalVoxNum),
      });
    } else if (virtualVoxNum >= 0) {
      this.setState({ virtualVoxNum: Number(virtualVoxNum) });
    }

    this.props.onLicenseSelected(licenseType, virtualVoxNum, physicalVoxNum);
  }

  handleSubtractVirtual() {
    let { licenseType, virtualVoxNum, physicalVoxNum } = this.state;

    if (virtualVoxNum > 1) {
      virtualVoxNum = virtualVoxNum - 1;

      if (virtualVoxNum == 0 && physicalVoxNum == 0) {
        physicalVoxNum = 1;
        this.setState({
          virtualVoxNum: Number(virtualVoxNum),
          physicalVoxNum: Number(physicalVoxNum),
        });
      } else if (virtualVoxNum >= 0) {
        this.setState({ virtualVoxNum: Number(virtualVoxNum) });
      }

      this.props.onLicenseSelected(licenseType, virtualVoxNum, physicalVoxNum);
    }
  }

  handlePhysicalChange(event) {
    let physicalVoxNum = event.target.value;
    let { licenseType, virtualVoxNum } = this.state;

    if (virtualVoxNum == 0 && physicalVoxNum == 0) {
      virtualVoxNum = 1;
      this.setState({
        physicalVoxNum: Number(physicalVoxNum),
        virtualVoxNum: Number(virtualVoxNum),
      });
    } else if (physicalVoxNum >= 0) {
      this.setState({ physicalVoxNum: Number(physicalVoxNum) });
    }

    this.props.onLicenseSelected(licenseType, virtualVoxNum, physicalVoxNum);
  }

  handleAddPhysical() {
    let { licenseType, virtualVoxNum, physicalVoxNum } = this.state;
    physicalVoxNum = physicalVoxNum + 1;

    if (virtualVoxNum == 0 && physicalVoxNum == 0) {
      virtualVoxNum = 1;
      this.setState({
        physicalVoxNum: Number(physicalVoxNum),
        virtualVoxNum: Number(virtualVoxNum),
      });
    } else if (physicalVoxNum >= 0) {
      this.setState({ physicalVoxNum: Number(physicalVoxNum) });
    }

    this.props.onLicenseSelected(licenseType, virtualVoxNum, physicalVoxNum);
  }

  handleSubtractPhysical() {
    let { licenseType, virtualVoxNum, physicalVoxNum } = this.state;
    physicalVoxNum = physicalVoxNum - 1;

    if (virtualVoxNum == 0 && physicalVoxNum == 0) {
      virtualVoxNum = 1;
      this.setState({
        physicalVoxNum: Number(physicalVoxNum),
        virtualVoxNum: Number(virtualVoxNum),
      });
    } else if (physicalVoxNum >= 0) {
      this.setState({ physicalVoxNum: Number(physicalVoxNum) });
    }

    this.props.onLicenseSelected(licenseType, virtualVoxNum, physicalVoxNum);
  }
}

export default LicenseSelect;
