import React from 'react';
import {Button} from 'react-bootstrap'
import AdminModalImportAnswers from "./AdminModalImportAnswers";
import {toast} from "react-toastify";

interface MyProps {
}

interface MyState {
    modalImportShow: boolean,
}

class AdminAnswersPage extends React.Component<MyProps,MyState> {

    constructor(props) {
        super(props);

        this.state = {
            modalImportShow: false,
        };
    }

    componentDidMount() {

    }

    render() {

        let modalImportOpen = (itemToEdit) => this.setState({ modalImportShow: true });
        let closeImportModal = () => this.setState({ modalImportShow: false });

        return (
            <div className="container mt-5 p-5" id="resultsContainer">
                <Button
                    id="importBtn"
                    variant="light"
                    onClick={modalImportOpen}
                >
                    Import answers
                </Button>


                <AdminModalImportAnswers
                    show={this.state.modalImportShow}
                    onHide={closeImportModal}
                    onAnswersImported={this.onAnswersImported}
                />
            </div>
        );
    }


    onAnswersImported(item){
    }

}

export { AdminAnswersPage };
