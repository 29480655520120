/**
 * Created by Alex on 22-Mar-19.
 */
import React from 'react';
import {Form,Button,Modal} from 'react-bootstrap'
import { toast } from "react-toastify";

import { serverService } from '../../../_services/server.service';

import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";


import 'react-day-picker/lib/style.css';

import  {
    formatDate,
    parseDate,
} from 'react-day-picker/build/addons/MomentLocaleUtils';

import './ModalQuestionsPack.css'
import QuestionsAutocomplete from "../../_other/QuestionsAutocomplete";
import ModalConfirmAction from "../../_other/ModalConfirmAction";
import i18n from "i18next";
import {Trans} from "react-i18next";

import moment from 'moment'

interface MyProps {
    fromSection: string, // campaign or scan or campaign_ai_questions

    // Campaign create your custom pack
    selectedChallangeMeasure?:number,
    selectedFunkiness?:number,
    selectedSkills?:any,
    StartDate?:string,

    // Scan section - add scan
    selectedQPack?: any

    // Scan section - edit scan
    selectedScan?: any,

    // Campaign -> Generate questions
    questionsFromAi?: [],
    selectedIdVox?: number,

    show:boolean,
    onHide():any,
    size:any,

    onQuestionPackScheduled(visibleQuestionsArray:any):any,
}

interface MyState {
    loading: boolean,
    error: string,

    visibleQuestionsArray:any,
    invisibleQuestionsArray:any,
    company:any,
    monthDisplayModel:any,

    indexForEditingQuestion: number,
    formFields:any,

    modalConfirmOverwriteShow:boolean,

    questionsToCopy: string,
}

const grid = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",
    padding: grid * 2,
    margin: `0 0 ${grid}px 0`,

    // change background colour if dragging
    background: isDragging ? "lightblue" : "#EFEFEF",

    // styles we need to apply on draggables
    ...draggableStyle
});

const getListStyle = isDraggingOver => ({
    background: isDraggingOver ? "white" : "white",
    padding: grid,
});

class ModalQuestionsPack extends React.Component<MyProps,MyState> {

    private userObj;
    private textArea:any;

    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            error: '',

            visibleQuestionsArray: [],
            invisibleQuestionsArray : [],
            company:{},
            monthDisplayModel:[],

            indexForEditingQuestion: -1,
            formFields:{},

            modalConfirmOverwriteShow:false,

            questionsToCopy:"",
        };


        this.fetchDataFromServer = this.fetchDataFromServer.bind(this);
        this.doFetchCompany = this.doFetchCompany.bind(this);
        this.fetchQuestionsPack = this.fetchQuestionsPack.bind(this);
        this.onScheduleClick = this.onScheduleClick.bind(this);
        this.onDragEnd = this.onDragEnd.bind(this);

        this.handleQuestionChange = this.handleQuestionChange.bind(this);

        this.doCancelEdit = this.doCancelEdit.bind(this);
        this.handleSubmitAddQuestion = this.handleSubmitAddQuestion.bind(this);

        this.doSchedule = this.doSchedule.bind(this);
        this.yesOverwrite = this.yesOverwrite.bind(this);
        this.noOverwrite = this.noOverwrite.bind(this);
        this.closeModalConfirmOverwrite = this.closeModalConfirmOverwrite.bind(this);

        this.populateTextAreaToCopy = this.populateTextAreaToCopy.bind(this);


    }

    componentDidMount() {
        let userStr = localStorage.getItem('user');
        if(userStr) {
            this.userObj = JSON.parse(userStr);
        }

    }

    render() {

        const { visibleQuestionsArray,company, monthDisplayModel} = this.state;

        let titleStr = i18n.t("trans_33_modal_question_pack_title1");
        let confirmButtonStr = i18n.t("trans_33_modal_question_pack_confirm_btn1");
        let cancelButtonStr =  i18n.t("trans_33_Cancel");


        if(this.props.fromSection === "add_scan"){
            titleStr = i18n.t("trans_33_modal_question_pack_title2");
            confirmButtonStr = i18n.t("trans_33_modal_question_pack_confirm_btn2");
        } else if(this.props.fromSection === "edit_scan"){
            titleStr = i18n.t("trans_33_modal_question_pack_title2");
            confirmButtonStr = i18n.t("trans_33_modal_question_pack_confirm_btn2");
        }


        let listItems = "";
        if(monthDisplayModel && monthDisplayModel.length > 0){
            listItems = monthDisplayModel.map((oneDay,index) => {
                let dayShortNameFormat;
                let dayNumberFormat;
                dayShortNameFormat = moment(oneDay.date).format("dd");
                dayNumberFormat = moment(oneDay.date).format("DD/MM");

                let questionDisplay = ""; 
                let tagsDisplay = "";
                let otherSkillsDisplay = "";

                if(typeof visibleQuestionsArray[index] !== 'undefined') { 
                    questionDisplay = visibleQuestionsArray[index].QuestionText;

                    if(visibleQuestionsArray[index].Tags){
                        tagsDisplay = visibleQuestionsArray[index].Tags;
                    }

                    otherSkillsDisplay = "Funkiness: " + (visibleQuestionsArray[index].Funkiness==null?'-':visibleQuestionsArray[index].Funkiness)
                        + ", Measure: " + (visibleQuestionsArray[index].Measure==null?'-':visibleQuestionsArray[index].Measure)
                        + ", Challenge: " + (visibleQuestionsArray[index].Challenge==null?'-':visibleQuestionsArray[index].Challenge);
                        // + ", FunkinessDiffAbs: " + visibleQuestionsArray[index].FunkinessDiffAbs;
                } 

                if(!questionDisplay && (this.props.fromSection === "add_scan" || this.props.fromSection === "edit_scan")){
                    return false;
                } 

                if(this.props.fromSection === "add_scan") {
                    dayShortNameFormat = ""
                    dayNumberFormat = ""
                } else if(this.props.fromSection === "edit_scan") {
                    dayShortNameFormat = ""
                    dayNumberFormat = ""
                }



                return <div className="row">
                        {/* <div className="col-md-1 px-2 text-center">{dayShortNameFormat}<br/><b>{dayNumberFormat}</b></div> */}
                            <Draggable key={oneDay.date} draggableId={oneDay.date} index={index}>
                                {(provided, snapshot) => (
                                    <div className="row col-md-12"
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        style={getItemStyle(
                                            snapshot.isDragging,
                                            provided.draggableProps.style
                                        )}
                                    >

                                        <div className="col-md-9 my-auto">
                                            {!(index === this.state.indexForEditingQuestion) &&
                                                <div>
                                                    <span className="question_display2" style={{fontSize:'16px'}}>
                                                        {questionDisplay}
                                                    </span>
                                                    {tagsDisplay && this.userObj && this.userObj['Type'] == 1 &&
                                                        <span>
                                                            <br/>
                                                            <span className="tags_display">
                                                                {tagsDisplay}
                                                            </span>

                                                        </span>
                                                    }

                                                    {this.userObj && this.userObj['Type'] == 1 &&
                                                        <span>
                                                            <br/>
                                                            <span className="otherSkillsDisplay">
                                                                {otherSkillsDisplay}
                                                            </span>
                                                        </span>
                                                    }


                                                </div>

                                            }

                                            {(index === this.state.indexForEditingQuestion) &&
                                                <Form onSubmit={this.handleSubmitAddQuestion}>
                                                    <Form.Group controlId="formBasicName">
                                                        <QuestionsAutocomplete selectedQuestion={this.state.formFields.Question}
                                                                               handleQuestionChange={this.handleQuestionChange}
                                                        />
                                                    </Form.Group>
                                                    <Button variant="secondary" onClick={this.doCancelEdit}>
                                                        <Trans>
                                                            trans_33_Cancel
                                                        </Trans>
                                                    </Button>
                                                    &nbsp;
                                                    <Button variant="primary" type="submit">
                                                        <Trans>
                                                            trans_33_save
                                                        </Trans>
                                                    </Button>
                                                </Form>
                                            }
                                        </div>

                                        {(this.props.fromSection !== "add_scan" && this.props.fromSection !== "edit_scan") &&
                                        
                                            <div className="col-md-3 my-auto ml-auto">

                                                <img
                                                    id="editBtn"
                                                    alt="Edit questions"
                                                    src="/assets/images/icon_edit32.png"
                                                    onClick={e => {
                                                        e.stopPropagation();
                                                        this.doEditQuestion(index);
                                                    }}
                                                />

                                                &nbsp;

                                                <img
                                                    id="shuffleBtn"
                                                    alt="Shuffle question"
                                                    src="/assets/images/icon_shuffle32.png"
                                                    onClick={e => {
                                                        e.stopPropagation();
                                                        this.doShuffleQuestion(index);
                                                    }}
                                                />

                                                &nbsp;

                                                <img
                                                    id="removeBtn"
                                                    alt="Remove question"
                                                    src="/assets/images/icon_delete32.png"
                                                    onClick={e => {
                                                        e.stopPropagation();
                                                        this.doRemoveQuestion(index);
                                                    }}
                                                />

                                            </div>

                                        }
                                        

                                    </div>
                                )}
                            </Draggable>

                        </div>
            });
        }

        return (
            <Modal
                show={this.props.show}
                onHide={this.props.onHide}
                size="lg"
                dialogClassName="modal-90w"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                backdrop="static"
                onEntered={this.fetchDataFromServer}
            >
                <Modal.Header className='modal-header-scan p-4' closeButton translate="yes">
                    <div className='w-100 d-flex justify-content-between mt-5'>
                        <Modal.Title as="h4" id="example-custom-modal-styling-title">
                          {titleStr}
                        </Modal.Title>

                        <Button className='px-4 py-2' size='lg' variant="primary" type="submit" disabled={this.state.loading}
                            onClick={this.onScheduleClick}
                        >
                          {confirmButtonStr}
                        </Button>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    {this.state.loading &&
                    <img alt="loading" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                    }
                    <div style={{textAlign:'right'}}>
                        {this.userObj && this.userObj['Type'] == 1 &&
                            <>
                            <Button className='px-4 py-2' size='lg'  variant="primary" disabled={this.state.loading} onClick={this.copyToClipboard} >
                                <Trans>trans_33_copy_questions_to_clipboard</Trans>
                            </Button>
                            &nbsp;&nbsp;
                            </>
                        }
                    </div>

                    <DragDropContext onDragEnd={this.onDragEnd}>
                        <Droppable droppableId="droppable" >
                            {(provided, snapshot) => (
                            <div
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                                style={getListStyle(snapshot.isDraggingOver)}
                            >
                                {listItems}

                                {provided.placeholder}
                            </div>
                            )}
                        </Droppable>
                    </DragDropContext>

                    {/* <br/><br/> */}

                    <textarea ref={(textarea) => this.textArea = textarea}
                              id="copyLinkTextarea" value={this.state.questionsToCopy} onChange={this.onChange}
                    style={{height:"0.1px",border: "none"}}/>


                    <div className='p-2' style={{textAlign:'right'}}>
                        <Button className='px-4 py-2 mr-3' size='lg'  variant="secondary" onClick={this.props.onHide}>
                            {cancelButtonStr}
                        </Button>
                        &nbsp;
                        <Button className='px-4 py-2' size='lg'  variant="primary" type="submit" disabled={this.state.loading}
                            onClick={this.onScheduleClick}
                        >
                            {confirmButtonStr}
                        </Button>

                        {this.state.loading &&
                        <img alt="loading" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                        }   

                    </div>
                </Modal.Body>

                <ModalConfirmAction
                    show={this.state.modalConfirmOverwriteShow}
                    actionTitle={i18n.t("trans_33_modal_question_pack_confirm_overwrite")}
                    actionBody={i18n.t("trans_33_modal_question_pack_confirm_overwrite_description")}
                    noBtnText={i18n.t("trans_33_modal_question_pack_confirm_overwrite_no")}
                    yesBtnText={i18n.t("trans_33_modal_question_pack_confirm_overwrite_yes")}
                    onConfirmNo={this.noOverwrite}
                    onHide={this.closeModalConfirmOverwrite}
                    onConfirmYes={this.yesOverwrite}
                />

            </Modal>
        );
    }


    fetchDataFromServer(){
        this.setState({visibleQuestionsArray: [], invisibleQuestionsArray: []})
        this.doFetchCompany().then(result1 => {
            this.fetchQuestionsPack().then(questionsList => {
                this.showQuestionsTable(questionsList);
                this.populateTextAreaToCopy();
            });
        });

    }


    populateTextAreaToCopy(){
        let questionsToCopy = "";

        let monthDisplayModel = this.state.monthDisplayModel;
        let visibleQuestionsArray = this.state.visibleQuestionsArray;

        if(monthDisplayModel && monthDisplayModel.length > 0){
            monthDisplayModel.map((oneDay,index) => {
                if (typeof visibleQuestionsArray[index] !== 'undefined') {
                    questionsToCopy += moment(oneDay.date).format("YYYY-MM-DD") + ";" + visibleQuestionsArray[index]['QuestionText'] + "\n";
                }
            });
        }

        console.log("populating TextArea to copy");
        console.log(questionsToCopy);


        this.setState({questionsToCopy:questionsToCopy})
    }

    fetchQuestionsPack() {

        // console.log(this.props.questionsFromAi)

        console.log(this.props.StartDate)

        this.setState({loading:true,});

        let skillsParam = "";
        if(this.props.selectedSkills){
            for(let i=0;i<this.props.selectedSkills.length;i++){
                skillsParam += this.props.selectedSkills[i].IdSkill;
                if(i<this.props.selectedSkills.length-1){
                    skillsParam+= ",";
                }
            }
        }

        if(this.props.fromSection === "add_scan"){
            return serverService.getQPackQuestions(this.props.selectedQPack.IdQPack)
                .then(
                    result => {
                        return result.data;
                    },
                    error => {
                        toast.warn("Did not get questions");
                    }
                );
        } else 

        if(this.props.fromSection === "edit_scan"){
            return serverService.getScanQuestions(this.props.selectedScan.IdScan)
                .then(
                    result => {
                        return result.data;
                    },
                    error => {
                        toast.warn("Did not get questions");
                    }
                );
        } else  if(this.props.fromSection === "campaign_ai_questions"){
            return Promise.resolve(this.props.questionsFromAi);
        } else  {
            return serverService.generateQuestionsPack(this.props.selectedChallangeMeasure,this.props.selectedFunkiness,skillsParam)
            .then(
                result => {
                    return result.data;
                },
                error => {
                    toast.warn("Did not generate question pack");
                }
            );
        }


       

    }


    doFetchCompany() {
        let IdCompany = this.userObj.IdCompany;

        return serverService.getCompany(IdCompany)
            .then(
                result => {
                    let company = result.data[0];
                    if(result.status === "1"){
                        this.setState({
                            company:company,
                        });
                    }
                },
                error => {
                    toast.warn("Did not get company");
                }
            );
    }


    showQuestionsTable(questionsList){
        let selectedDayMoment = moment();
        if(this.props.StartDate){
            selectedDayMoment = moment(this.props.StartDate,"DD/MM/YYYY");
        }


        const from_date = selectedDayMoment.clone();
        const to_date = selectedDayMoment.add(31, 'days').clone();

        const selectedDays = this.state.company.SelectedDays;


        let monthDisplayModel = [];

        let visibleQuestionsArray = [];
        let indexQuestionsArray = 0;

        let wasStartDateFound = false;


        for (let m = moment(from_date); m.isBefore(to_date); m.add(1, 'days')) {
            const dayOfWeek = m.format('dddd');
            const dateFormat = m.format('YYYY-MM-DD');

            if(selectedDays && selectedDays.toLowerCase().includes(dayOfWeek.toLowerCase())){
                monthDisplayModel.push({'date':dateFormat});

                if(!wasStartDateFound){
                    let formFields = this.state.formFields;
                    formFields.StartDate = dateFormat;
                    wasStartDateFound = true;
                }

                if(questionsList[indexQuestionsArray]){
                    visibleQuestionsArray.push(questionsList[indexQuestionsArray]);
                } else {
                    visibleQuestionsArray.push("");
                }
                indexQuestionsArray++;
            }
        }


        let invisibleQuestionsArray = [];
        for(let j=indexQuestionsArray;j<questionsList.length;j++){
            invisibleQuestionsArray.push(questionsList[j]);
        }

        this.setState({ loading:false, visibleQuestionsArray: visibleQuestionsArray
            , invisibleQuestionsArray: invisibleQuestionsArray, monthDisplayModel: monthDisplayModel});
    }

    onScheduleClick() {
        let visibleQuestionsArray = this.state.visibleQuestionsArray;
        let StartDate = this.state.formFields.StartDate;


        if(this.props.fromSection === "add_scan" || this.props.fromSection === "edit_scan"){
            this.props.onQuestionPackScheduled(visibleQuestionsArray);
        } else {
            serverService.checkIfQuestionsAreScheduled(StartDate,visibleQuestionsArray).then(
                result => {
                    if(result.status === "1"){
                        if(result.data === true){
                            this.setState({modalConfirmOverwriteShow:true,})
                        } else {
                            this.doSchedule(false);
                        }
                    } else {
                        toast.warn("An issue occurred while checking schedule");
                    }
                },
                error => {
                    toast.warn("Did not check if question is scheduled");
                }
            );
        }


    }

    yesOverwrite(){
        this.setState({modalConfirmOverwriteShow:false,},() => this.doSchedule(true));
    }

    noOverwrite(){
        this.setState({modalConfirmOverwriteShow:false,},() => this.doSchedule(false));
    }

    closeModalConfirmOverwrite() {
        this.setState({modalConfirmOverwriteShow:false,})
    }

    doSchedule(overwrite) {
        let visibleQuestionsArray = this.state.visibleQuestionsArray;
        let StartDate = this.state.formFields.StartDate;
        let selectedIdVox = this.props.selectedIdVox;
        if(!selectedIdVox){
            selectedIdVox = 0;
        }

        serverService.scheduleQuestionPack(StartDate, visibleQuestionsArray, overwrite, selectedIdVox).then(
            result => {
                if(result.status === "1"){
                    toast.success(i18n.t("trans_33_modal_question_pack_msg_success"));
                    this.props.onQuestionPackScheduled(visibleQuestionsArray);
                } else {
                    toast.warn("An issue occurred while scheduling questions")
                }
            },
            error => {
                toast.warn("Did not schedule question pack");
            }
        );
    }

    doEditQuestion(index) {
        let formFields = this.state.formFields;
        formFields.Question = "";
        this.setState({indexForEditingQuestion:index,formFields:formFields});
    }


    doCancelEdit() {
        let formFields = this.state.formFields;
        formFields.Question = "";
        this.setState({indexForEditingQuestion:-1,formFields:formFields});
    }



    doShuffleQuestion(index) {
        if(this.state.invisibleQuestionsArray.length > 0){
            let oneQuestion = this.state.invisibleQuestionsArray.shift();
            let visibleQuestionsArray = this.state.visibleQuestionsArray;

            visibleQuestionsArray[index] = oneQuestion;
            this.setState({visibleQuestionsArray:visibleQuestionsArray});
        } else {
            toast.warn(i18n.t("trans_33_modal_question_pack_msg_limit_reached"));
        }
    }

    doRemoveQuestion(index) {
        let visibleQuestionsArray = this.state.visibleQuestionsArray;
        visibleQuestionsArray[index] = "";
        this.setState({ visibleQuestionsArray: visibleQuestionsArray }, () => {
            this.populateTextAreaToCopy();
        });
        
    }

    onDragEnd(result) {
        // dropped outside the list
        if (!result.destination) {
            return;
        }

        const visibleQuestionsArray = reorder(
            this.state.visibleQuestionsArray,
            result.source.index,
            result.destination.index
        );

        this.setState({
            visibleQuestionsArray: visibleQuestionsArray
        },  () => {
            this.populateTextAreaToCopy();
        });

    }

    handleQuestionChange(questionString) {
        let formFields = this.state.formFields;
        formFields.Question = questionString;
        this.setState({ formFields: formFields });
    }

    handleSubmitAddQuestion(e) {
        e.preventDefault();

        const { formFields } = this.state;
        const Question = formFields.Question;

        // stop here if form is invalid
        if (!(Question)) {
            toast.error(i18n.t("trans_33_missing_fields"));
            return;
        }

        let IdUser = this.userObj.IdUser;

        let question = {'IdQuestion': 0,'IdUser':IdUser.toString(), 'QuestionText': Question };
        serverService.insertQuestion(question).then(
            insertQuestionResult => {
                toast.success(i18n.t("trans_33_question_added"));

                question.IdQuestion = insertQuestionResult.data.IdQuestion;
                let visibleQuestionsArray = this.state.visibleQuestionsArray;
                visibleQuestionsArray[this.state.indexForEditingQuestion] = question;
                this.setState({visibleQuestionsArray:visibleQuestionsArray});

                this.doCancelEdit();
            },
            error => {
                toast.warn("Did not insert question");
            }
        );
    }

    onChange(){}

    copyToClipboard = (e) => {
        this.textArea.select();
        document.execCommand('copy');
        e.target.focus();

        toast.success(i18n.t("trans_33_modal_copy_link_msg_success"))
    };
}

const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};


export default ModalQuestionsPack;