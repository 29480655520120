/**
 * Created by Alex on 22-Mar-19.
 */
import React from 'react';
import {Button,Modal,Form,Row,Col} from 'react-bootstrap'
import { toast } from "react-toastify";
import ModalImage from "react-modal-image";
import {serverService} from "../../_services/server.service";
import {Trans} from "react-i18next";
import i18n from 'i18next';


interface MyProps {
    show:boolean,
    onHide?(): any,
    CodeMsBot:string,
    IdVox:number,
    selectedVox: boolean,
}

interface MyState {
    loading:boolean,
    formFields:any,
}


class ModalMsTeamsBotCode extends React.Component<MyProps,MyState> {

    private intervalTimer;
    private userObj;


    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            formFields: {}
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.doFetchVoxTeamsListInterval = this.doFetchVoxTeamsListInterval.bind(this);
        this.onExit = this.onExit.bind(this);
        this.onEntered = this.onEntered.bind(this);


    }

    componentDidMount() {
        const userStr = localStorage.getItem('user');
        if(userStr) {
            this.userObj = JSON.parse(userStr);
        }
    }


    render() {
        let {loading} = this.state;
        return (
            <Modal
                show={this.props.show}
                onHide={this.props.onHide}
                onExit  = {this.onExit}
                onEntered  = {this.onEntered}
                aria-labelledby="contained-modal-title-vcenter"
                centered
                backdrop="static"
            >
                <Modal.Header  className="add-vox-modal-header"  closeButton translate="yes">
                    <Modal.Title>
                        <Trans>trans_33_modal_teams_code_title</Trans>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="add-vox-modal-content" >
                    <br/>
                    <Trans>trans_33_modal_teams_code_fill_it_manager_email</Trans>
                    <Form onSubmit={this.handleSubmit}>
                        <Form.Control size="sm"
                                      type="text"
                                      id="Email"
                                      placeholder="Email"
                                      name="Email"
                                      onChange={this.handleChange}
                                      style={{width:'250px',float: 'left'}}
                        />
                        <Button variant="primary" type="submit" disabled={loading} style={{float: 'left',marginLeft: '10px'}}>
                            <Trans>trans_33_Send</Trans>
                        </Button>
                        {loading &&
                        <img alt="loading"
                             src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA=="/>
                        }
                    </Form>


                    <br/><br/><br/>
                    <Trans>trans_33_modal_teams_code_follow_this_steps</Trans>
                    <br/> <br/>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-1 align-middle" style={{marginTop: 'auto', marginBottom: 'auto'}}><b>1.</b>
                            </div>
                            <div className="col-md-11">
                                <a target="_blank" href="/assets/VoxCollector-3.zip">
                                    <Button variant="primary">
                                        <Trans>trans_33_modal_teams_code_download_file</Trans>
                                    </Button>
                                </a>
                            </div>
                        </div>
                        <br/>
                        <div className="row">
                            <div className="col-md-1 align-middle" style={{marginTop: 'auto', marginBottom: 'auto'}}><b>2.</b>
                            </div>
                            <div className="col-md-11">
                                 <ModalImage
                                    small={"/assets/ms_bot_tutorial.gif"}
                                    large={"/assets/ms_bot_tutorial.gif"}
                                    alt="Watch the video clip here"
                                 />
                            </div>
                        </div>
                        <br/>
                        <div className="row">
                            <div className="col-md-1 align-middle" style={{marginTop: 'auto', marginBottom: 'auto'}}><b>3.</b>
                            </div>
                            <div className="col-md-11">
                                <Trans>trans_33_modal_teams_code_copy_unique_code</Trans>&nbsp;
                                <span style={{fontWeight: 'bold', fontSize: '18px'}}>{this.props.CodeMsBot}</span>
                            </div>
                        </div>
                    </div>

                    <br/>

                </Modal.Body>

            </Modal>
        );
    }


    onExit(){
        if(this.intervalTimer != null){
            clearInterval(this.intervalTimer);
        }
    }

    onEntered(){
        if(!this.props.selectedVox['ChannelMsBot'] || this.props.selectedVox['ChannelMsBot'].length == 0){
            this.doFetchVoxTeamsListInterval();
        }
    }


    doFetchVoxTeamsListInterval(){
        let thisPointer = this;
        if(this.intervalTimer != undefined){
            clearInterval(thisPointer.intervalTimer);
        }

        this.intervalTimer = setInterval(function(){
            thisPointer.doFetchVoxMsTeamsList().then(val => {
                    if(val.data.length > 0){
                        clearInterval(thisPointer.intervalTimer);
                    }
                },
                error => {
                    clearInterval(thisPointer.intervalTimer);
                    toast.warn("Did not get the vox");
                });
        }, 3000);
    }

    doFetchVoxMsTeamsList(){
        return serverService.getVoxTeamsBotByIdVox(this.props.IdVox).then(
            res => {
                // console.log(res.data);
                if(res.data.length > 0){
                    toast.success(i18n.t("trans_33_msg_vox_successfully_assigned"));
                    this.props.onHide();
                }

                return res;
            }
        );
    }


    handleChange(e) {
        const {name, value} = e.target;
        let formFields = this.state.formFields;
        formFields[name] = value;
        this.setState({formFields: formFields});
    }

    handleSubmit(e) {
        e.preventDefault();

        this.setState({loading: true});

        let CodeMsTeams = this.props.CodeMsBot;
        let Email = this.state.formFields.Email;

        serverService.sendEmailWithMsTeamsInstructions(Email,CodeMsTeams)
            .then(result => {
                toast.success(result.message);
                this.setState({loading: false});
            }, error => {
                toast.warn("Issues while sending the email");
                this.setState({loading: false});
            });

    }
}

export default ModalMsTeamsBotCode;