/**
 * Created by Alex on 22-Mar-19.
 */
import React from 'react';
import { Button, Modal, Form } from 'react-bootstrap';
import { toast } from 'react-toastify';

import { serverService } from '../../_services/server.service';
import i18n from 'i18next';
import { Trans } from 'react-i18next';
import PulseQuestionsTable from './PulseQuestionsTable';

interface MyProps {
  onHide(): any;
  show: boolean;
  size?: any;
  selectedIdPulse: number;
  selectedPulseNumOfAnswers:number;
  isDuringAddingPulse:boolean;
  onNavigateToStep3:any;
}

interface MyState {
  submitted: boolean;
  loading: boolean;
  error: string;
  success: string;
  formFields: any;
  pulseQuestions: any;
}

class ModalPulseQuestions extends React.Component<MyProps, MyState> {
  constructor(props) {
    super(props);

    this.state = {
      submitted: false,
      loading: false,
      error: '',
      success: '',

      formFields: {},
      pulseQuestions: [],
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onEntered = this.onEntered.bind(this);

    this.onPulseQuestionDeleted = this.onPulseQuestionDeleted.bind(this);
    this.handleAcceptsCommentsCheckedChanged = this.handleAcceptsCommentsCheckedChanged.bind(this);
    this.onNavigateToStep3 = this.onNavigateToStep3.bind(this);
  }

  onEntered() {
    let formFields = this.state.formFields;
    formFields.QuestionText = "";
    formFields.AcceptsComments = 0;
    this.setState({ formFields: formFields });

    this.getPulseQuestions();
  }

  getPulseQuestions() {
    serverService.getPulseQuestions(this.props.selectedIdPulse).then(
      questionsRes => {
        this.setState({ pulseQuestions: questionsRes.data });
      },
      error => {
        toast.warn('Did not get questions');
      }
    );
  }

  handleChange(e) {
    const { name, value } = e.target;
    let formFields = this.state.formFields;
    formFields[name] = value;
    this.setState({ formFields: formFields });
  }

  handleSubmit(e) {
    e.preventDefault();

    this.setState({ submitted: true, loading: true });

    let formFields = this.state.formFields;

    if (formFields.QuestionText.length == 0) {
      toast.warn(i18n.t('trans_33_missing_fields'));
      this.setState({ submitted: false, loading: false });
      return;
    }


    let IdPulse = this.props.selectedIdPulse;
    let QuestionText = formFields.QuestionText;
    let AcceptsComments =  formFields.AcceptsComments;

    serverService.insertPulseQuestion(IdPulse,QuestionText,AcceptsComments).then(
      result => {
        if (result.status === '1') {
          this.getPulseQuestions();
          toast.success("Question added");
        } else {
          toast.error('Did not send');
        }

        formFields.QuestionText = "";
        formFields.AcceptsComments = 0;

        this.setState({ submitted: false, loading: false,formFields:formFields });
      },
      error => {
        console.log(error);
        toast.error('Did not send');
        this.setState({ submitted: false, loading: false });
      }
    );
  }

  render() {
    let { formFields, loading } = this.state;

    let title = i18n.t("trans_33_pulse_questions");
    if(this.props.isDuringAddingPulse){
      title = "Step 2/3 Add Pulse Question"
    }

    return (
      <Modal
        show={this.props.show}
        onHide={this.props.onHide}
        size={this.props.size}
        onEntered={this.onEntered}
        dialogClassName='modal-90w'
        aria-labelledby='contained-modal-title-vcenter'
        centered
      >
        <Modal.Header
          className='add-vox-modal-header modal-header-update p-4'
          closeButton
          translate='yes'
        >
          <Modal.Title as="h4" id='example-custom-modal-styling-title'>
            {title}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>


          {this.props.selectedPulseNumOfAnswers <= 0 &&
            <Form onSubmit={this.handleSubmit}>
              <Form.Group>
                <Form.Label> 
                  <Trans>trans_33_pulse_type_in_your_question</Trans>
                </Form.Label>
                <Form.Control
                    as='textarea'
                    name='QuestionText'
                    placeholder={i18n.t("trans_33_popup_input_questions_ask_your_question_here")}
                    rows={4}
                    value={formFields.QuestionText || ""}
                    onChange={this.handleChange}
                />
              </Form.Group>


              <div className='row justify-content-start'>

                <div className='col-md-10 d-flex'>
                <Form.Check
                      className="form-check-update"
                      type="checkbox"
                      checked={formFields.AcceptsComments || false}
                      onChange={this.handleAcceptsCommentsCheckedChanged}
                      id="acceptsComments"
                      label={i18n.t("trans_33_accepts_comments") as string}
                  />
                </div>
                <div className='col-md-2 d-flex justify-content-end'>
                  <Button className='px-4 py-2 btn-outline-primary-updated' size='lg' variant='outline-primary' type='submit' disabled={loading}>
                    <Trans>trans_33_add</Trans>
                  </Button>
                  {loading && (
                      <img
                          alt='load ing'
                          src='data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA=='
                      />
                  )}
                </div>
              </div>
              
              <hr />
            </Form>
          }

          <PulseQuestionsTable
              pulseQuestions={this.state.pulseQuestions}
              onPulseQuestionDeleted={this.onPulseQuestionDeleted}
              selectedPulseNumOfAnswers={this.props.selectedPulseNumOfAnswers}
          />

          {this.props.isDuringAddingPulse &&
              <>
                <br/>
                <div style={{ textAlign: 'right',padding: "0 10px 0 10px" }}>
                  <Button className='px-4 py-2' size='lg' variant='primary' disabled={loading} onClick={this.onNavigateToStep3}>
                    Next
                  </Button>
                </div>

              </>
          }


        </Modal.Body>
        <br />
      </Modal>
    );
  }


  handleAcceptsCommentsCheckedChanged(event) {
    let formFields = this.state.formFields;
    formFields.AcceptsComments = event.target.checked?1:0;
    this.setState({ formFields: formFields });
  }

  onPulseQuestionDeleted(){
    this.getPulseQuestions();
  }

  onNavigateToStep3(){
    this.props.onNavigateToStep3();
  }

}

export default ModalPulseQuestions;
